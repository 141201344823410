import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  SET_NAVBAR_TITLE,
  SHOW_NOTIFICATION
} from "action_creators/actioncreator";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// Local
import { Api } from "../../../../config/api";
import Menu from "../../../Components/Menu";
import TemplateCards from "./TemplateCards";
import { SDCloudBackendAPIWithToken } from "../../../../apis/backendAPI";
import { CMSAPIWithToken } from "../../../../apis/CMS";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "50px"
  }
}));

function SandboxTemplates() {
  const [cardData, setCardData] = useState([]);
  const [currentScreen, setCurrentScreen] = useState("All");
  const [reservedIP, setReservedIP] = useState({});
  const [reservedIPLoaded, setReservedIPLoaded] = useState(false);
  const [runningClusters, setRunningClusters] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  let { profileObj } = useSelector(state => state);

  const menuItems = ["All", "Recents"];

  // get reserved IP settings for user, account and offering specific
  const getReservedIp = () => {
    return SDCloudBackendAPIWithToken()
      .get(Api.getUserProfile)
      .then(res => {
        let reservedIpObj = {};
        if (res.data.static_ips?.length) {
          reservedIpObj["reservedIps"] = [...res.data.static_ips];
          reservedIpObj["ipService"] = true;
        }
        return reservedIpObj;
      })
      .then(reservedIpObj => {
        let accountName = JSON.parse(localStorage.getItem("profileData"))?.account;
        return SDCloudBackendAPIWithToken()
          .get(Api.getAccountDetails,{
            params: {
                account: accountName,
            }})
          .then(res => {
            if (res.data.reserved_ip) {
              reservedIpObj["staticIp"] = res.data.reserved_ip;
            }
            if (res.data.ip_available_services?.includes("poc")) {
              reservedIpObj["ipService"] = true;
            } else {
              reservedIpObj["ipService"] = false;
            }
            return reservedIpObj;
          });
      })
      .then(reservedIpObj => {
        if (
          reservedIpObj.ipService &&
          (reservedIpObj.reservedIps || reservedIpObj.staticIp)
        ) {
          reservedIpObj["dynamicIp"] = "default";

          setReservedIP({ ...reservedIpObj });
          setReservedIPLoaded(true);
        } else {
          setReservedIPLoaded(true);
        }
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err
        // };
        // dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  const getRunningClusters = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.getClusterlist)
      .then(resp => resp.data)
      .then(data => {
        setRunningClusters(data);
      })
      .catch(err => {
        //console.log("");
      });
  };

  useEffect(() => {
    getReservedIp();
    getRunningClusters();
  }, []);

  useEffect(() => {
    dispatch(SHOW_LOADER());

    dispatch(SET_NAVBAR_TITLE("Sandboxes"));

    const getTemplateData = async () => {
      let response = await CMSAPIWithToken().get(Api.adminSandboxes, {
        params: {
          limit: "1000"
        }
      });
      let sandboxesData = response.data.results;

      // response = await SDCloudBackendAPIWithToken().get(Api.getResource);
      // let sandboxes = response.data.sandboxes;

      // let profileResponse = await SDCloudBackendAPIWithToken().get(
      //   Api.getProfile
      // );

      // //console.log(`First`);

      // sandboxesData = sandboxesData.filter((item) => {
      //   let temp = sandboxes.some((data) => {
      //     //console.log(1);
      //     // response = await SDCloudBackendAPIWithToken().get(Api.getProfile);

      //     //console.log(2);

      //     let profileSandboxes = profileResponse.data.profiles[0].permissions.some(
      //       (profileItem) => {
      //         return profileItem.resource_name === data.name;
      //       }
      //     );

      //     //console.log(profileSandboxes);

      //     if (profileSandboxes) {
      //       if (data.name === item.identity) {
      //         item["profileData"] = { ...data };
      //       }

      //       return data.name === item.identity;
      //     } else {
      //       return false;
      //     }

      //     // SDCloudBackendAPIWithToken()
      //     //   .get(Api.getProfile)
      //     //   .then((res) => res.data)
      //     //   .then((data) => {
      //     //     let profileSandboxes = data.profiles[0].permissions.some(
      //     //       (profileItem) => {
      //     //         return profileItem.resource_name === data.name;
      //     //       }
      //     //     );

      //     //     //console.log(profileSandboxes);

      //     //     if (profileSandboxes) {
      //     //       if (data.name === item.identity) {
      //     //         item["profileData"] = { ...data };
      //     //       }

      //     //       return data.name === item.identity;
      //     //     } else {
      //     //       return false;
      //     //     }
      //     //   });
      //   });

      //   //console.log(`Temp: ${temp}`);
      //   return temp;
      // });

      // Old Version
      if (profileObj.profileObj.privilege === "admin") {
        response = await SDCloudBackendAPIWithToken().get(Api.getResource);
        let sandboxes = response.data.sandboxes;

        sandboxesData = sandboxesData.filter(item => {
          let temp = sandboxes.some(data => {
            if (data.name === item.identity) {
              item["profileData"] = { ...data };
            }

            return data.name === item.identity;
          });

          return temp;
        });
      } else {
        response = await SDCloudBackendAPIWithToken().get(Api.getProfile);

        let sandboxes = response.data.profiles[0].permissions.filter(item => {
          return item.resource_type === "sandbox";
        });

        sandboxesData = sandboxesData.filter(item => {
          let temp = sandboxes.some(data => {
            if (data.resource_name === item.identity) {
              item["profileData"] = { ...data };
            }
            return data.resource_name === item.identity;
          });

          return temp;
        });
      }

      setCardData(sandboxesData);
      dispatch(HIDE_LOADER());
    };

    getTemplateData();
  }, []);

  const renderTemplateCards = () => {
    // This will sort the cards on the basis of sandbox permission
    cardData.sort((value1, value2) => {
      if (value1.profileData.permission === "view") {
        return 1;
      } else {
        return 0;
      }
    });

    const cards = cardData.map(item => {
      return (
        <TemplateCards
          cardData={item}
          key={uuidv4()}
          reservedIP={reservedIP}
          runningClusters={runningClusters}
        />
      );
    });

    return cards;
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} md={2} lg={3}>
          <Menu
            items={menuItems}
            onItemClick={setCurrentScreen}
            currentScreen={currentScreen}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={9}>
          {cardData.length === 0 && reservedIPLoaded && runningClusters
            ? null
            : renderTemplateCards()}
        </Grid>
      </Grid>
    </div>
  );
}

export default SandboxTemplates;
