import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Formik
import { useFormik } from "formik";

// Material UI Components
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import User from "../Usermanagement";
import LoaderHoc from "components/HOC/LoaderHoc";
import InfoIcon from '@mui/icons-material/Info'


const inputProps = {
  disableAnimation: true,
  shrink: false,
};

const formControlProps = {
  root: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: "0 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  warningNote: {
    padding: "auto",
    "& .MuiAlert-icon": {
      // display: "none",
      color:"#0288d1"
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

const AddUser = (props) => {
  const classes = useStyles();
  // States
  const [menuItems, setMenuItems] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [permissionProfile, setPermissionProfile] = useState("");

  const [showLoader, setShowLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      privilege: "developer",
      permission_profile: "",
      firstName: "",
      lastName: "",
      endaction:"suspend",
    },
    onSubmit: (values) => {
      let payload = {
        username: values.username,
        email: values.email,
        privilege: values.privilege,
        permission_profile_name: permissionProfile,
        base_url: `https://${window.location.host}/auth/`,
        first_name: values.firstName,
        last_name: values.lastName,
        endaction:values.endaction
      };
      setShowLoader(true)
      SDCloudBackendAPIWithToken()
        .post("/create_user", payload)
        .then((resp) => resp.data)
        .then((data) => {
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("User created successfully");
          setShowLoader(false)

          props.resetapi();
        })
        

        .catch((err) => {
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
          setShowLoader(false)
        });
    },
    add: (values) => {
      return <User />;
    },
    validate: (values) => {
      let errors = {};

      let chkName = /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*$/;
      if (!chkName.test(String(values.username))) {
        errors.username =
          "Username should contain small alpha numeric characters with only common special characters";
      }
      if (!values.username) {
        errors.username = "Required";
      }
      if (!values.firstName) {
        errors.firstName = "Required";
      }
      if (!values.lastName) {
        errors.lastName = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      }

      if (!values.privilege) {
        errors.privilege = "Required";
      }

      if (
        (!permissionProfile || permissionProfile === "None") &&
        values.privilege !== "admin"
      ) {
        errors.permission_profile = "Required";
      }

      return errors;
    },
  });

  const renderProfileDropdown = async () => {
    const profileItems = SDCloudBackendAPIWithToken()
      .get("/get_profile")
      .then((resp) => resp.data)
      .then((data) => {
        let profiles = data.profiles;

        let items = profiles.map((item) => {
          return item.profile_name;
        });

        setMenuItems(items);
      });
  };

  useEffect(() => {
    renderProfileDropdown();
  }, []);

  return (
  
      <form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.gridContainer}>
          <Grid item md={12}>
            <Typography variant="h4">Create new user</Typography>
          </Grid>
              <Grid item md={12}>
                <Grid container justify="space-between">
                  <Grid item xs={12} md={5}>
                    {formik.errors.username && formik.touched.username ? (
                      <TextField
                        type="text"
                        variant="filled"
                        label="Username"
                        name="username"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        helperText={formik.errors.username}
                        onBlur={formik.handleBlur}
                        error
                      />
                    ) : (
                      <TextField
                        type="text"
                        variant="filled"
                        label="Username"
                        name="username"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    {formik.errors.email && formik.touched.email ? (
                      <TextField
                        type="email"
                        variant="filled"
                        label="Email"
                        name="email"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        helperText={formik.errors.email}
                        onBlur={formik.handleBlur}
                        error
                      />
                    ) : (
                      <TextField
                        type="email"
                        variant="filled"
                        label="Email"
                        name="email"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid container justify="space-between">
                  <Grid item xs={12} md={5}>
                    {formik.errors.firstName && formik.touched.firstName ? (
                      <TextField
                        type="text"
                        variant="filled"
                        label="First Name"
                        name="firstName"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        helperText={formik.errors.firstName}
                        onBlur={formik.handleBlur}
                        error
                      />
                    ) : (
                      <TextField
                        type="text"
                        variant="filled"
                        label="First Name"
                        name="firstName"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    {formik.errors.lastName && formik.touched.lastName ? (
                      <TextField
                        type="text"
                        variant="filled"
                        label="Last Name"
                        name="lastName"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        helperText={formik.errors.lastName}
                        onBlur={formik.handleBlur}
                        error
                      />
                    ) : (
                      <TextField
                        type="text"
                        variant="filled"
                        label="Last Name"
                        name="lastName"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between" alignItems="center">
                  <FormLabel component="legend">User Privilege</FormLabel>
                  <Alert
                    icon={<InfoIcon fontSize="inherit" />}
                    severity="warning"
                    className={classes.warningNote}
                  >
                    Note: User privilege can be user or admin. A valid permission
                    profile must be assigned to user.
                  </Alert>
                  <Grid item xs={12} md={8}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="privilege"
                        name="privilege"
                        value={formik.values.privilege}
                        className={classes.radioButton}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="developer"
                          control={<Radio color="primary" />}
                          label="User"
                        />
                        <FormControlLabel
                          value="admin"
                          control={<Radio color="primary" />}
                          label="Admin"
                        />
                        {/* <FormControlLabel
                      value="profile_admin"
                      control={<Radio color="primary" />}
                      label="Profile Admin"
                    /> */}
                      </RadioGroup>
                    </FormControl>
                    
                  </Grid>
                  
                  {formik.values.privilege ? (
                    <Grid item xs={12} md={4}>
                      <Grid
                        container
                        alignItems="flex-end"
                        style={{ height: "100%" }}
                      >
                        <Grid item xs={12} md={12}>
                          <FormControl
                            variant="filled"
                            className={classes.formControl}
                          >
                            <FormLabel>Permission Profile</FormLabel>
                            <Autocomplete
                              disabled={
                                formik.values.privilege !== "admin" ? false : true
                              }
                              openOnFocus
                              id="permission_profile"
                              options={menuItems}
                              value={permissionProfile}
                              onChange={(event, newValue) => {
                                setPermissionProfile(newValue);
                                //console.log("done");
                              }}
                              renderInput={(params) =>
                                formik.errors.permission_profile &&
                                  formik.touched.permission_profile ? (
                                  <TextField
                                    {...params}
                                    type="text"
                                    variant="filled"
                                    fullWidth
                                    helperText={formik.errors.permission_profile}
                                    error
                                  />
                                ) : (
                                  <TextField
                                    {...params}
                                    type="text"
                                    variant="filled"
                                    fullWidth
                                  />
                                )
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between" alignItems="center">
                  <FormLabel component="legend">End Action</FormLabel>
                  <Alert
                    icon={<InfoIcon fontSize="inherit" />}
                    severity="warning"
                    className={classes.warningNote}
                  >
                    Note: If the "Terminate" option is selected, the "Suspend" feature will be disabled and unavailable for future actions.
                  </Alert>
                  <Grid item xs={12} md={8}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="endaction"
                        name="endaction"
                        value={formik.values.endaction}
                        className={classes.radioButton}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="suspend"
                          control={<Radio color="primary" />}
                          label="Suspend"
                        />
                        <FormControlLabel
                          value="terminate"
                          control={<Radio color="primary" />}
                          label="Terminate"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {showLoader ? (
                <div 
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "80px"
                }}>
                <LoaderHoc isLoading={showLoader}></LoaderHoc>
              </div>
              ):(
                <>
                   <Grid item xs={12} md={3}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  disableFocusRipple
                  type="submit"
                  className={classes.button + " " + classes.buttOutlined}
                >
                  Submit
                </Button>
              </Grid>
                </>
        
              )}
              
             
              <Grid item xs={12} md={12}>
                {showNotification ? (
                  <Alert
                    onClose={() => setShowNotification(false)}
                    severity={notificationType}
                  >
                    {notificationMessage}
                  </Alert>
                ) : null}
              </Grid>
    
        </Grid>
     
      </form>
    
    
  );
  
};

export default AddUser;
