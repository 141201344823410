import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Loader from "react-loader-spinner";

// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import RightDrawer from "views/Usermanagement/components/RightDrawer";
import { Api } from "config/api";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Badge from "../../../Components/badge";
// Local
import ProfileTable from "views/Usermanagement/components/ProfileTable";
import theme from "theme";
const useStyles = makeStyles((theme) => ({
  loader: {
    color: theme.palette.primary.main,
  },
  gridContainer: {
    padding: "0 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
}));

const ViewPermissionProfile = (props) => {
  const classes = useStyles();

  // States
  const [isDetailsLoaded, setIsDetailsLoaded] = useState(false);
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    renderTemplateData();
  }, [props.profile, props.profileData]);

  const hasBadge = profileData.some((item) => item.badge === true);
  const columns = [
    {
      Header: "Resource Name",
      accessor: "resource_name",
    },
    {
      Header: "Resource Type",
      accessor: "resource_type",
    },
    {
      Header: "Permission",
      accessor: "permission",
    },
    {
      Header: "Lab Hours",
      accessor: "lab_hours",
    },
    {
      Header: "Allocated Hours",
      accessor: "allocated_hours",
    },
    {
      Header: "Consumed Hours",
      accessor: "consumed_hours",
    },
    // {
    //   Header: "Badge",
    //   accessor: "badge",
    //   Cell: ({ value, row }) => {
    //     if (value) {
    //       return <Badge labName={row.original.resource_name} badgeUrl={row.original.badgeUrl} logo = {value}/>;
    //     }
    //     return null;
    //   },
    // }
    ...(hasBadge
      ? [
          {
            Header: "Badge",
            accessor: "badge",
            Cell: ({ value, row }) => {
              if (value) {
                return (
                  <Badge
                    labName={row.original.resource_name}
                    badgeUrl={row.original.badgeUrl}
                    logo={value}
                  />
                );
              }
              return null;
            },
          },
        ]
      : []),
  ];

  const renderTemplateData = async () => {
    await SDCloudBackendAPIWithToken()
      .post(Api.getProfile)
      .then((res) => {
        let profileInfo = res.data.profiles[0].permissions.map((item) => {
          return {
            resource_name: item.display_name
              ? item.display_name
              : item.resource_name === "designer_portal"
              ? 'Designer'
              : item.resource_name,
            resource_type: item.is_scripted_demo
              ? "showcase"
              : item.resource_type,
            permission: item.permission,
            lab_hours: item.labhours,
            allocated_hours: item.allocated_hours,
            consumed_hours: item.consumed_hours.toFixed(2),
            badge:"true" ? item.is_badge : "false",
            badgeUrl:item.badge_url
          };
        });
        setProfileData(profileInfo);
        setIsDetailsLoaded(true);
      });
  };

  return (
    <div>
      <RightDrawer
        open={props.openDrawer}
        onClose={props.setCloseDrawer}
        title={"Profile details"}
      >
        <Grid container className={classes.gridContainer}>
          <Grid item md={12}>
            <Typography variant="h4">{props.profileName}</Typography>
          </Grid>
          {!isDetailsLoaded ? (
            <Grid container justify="center" style={{ marginTop: "100px" }}>
              <Loader
                type="ThreeDots"
                color={theme.palette.secondary.main}
                height={50}
                width={50}
                visible={true}
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
              <Grid container justify="space-between">
                <ProfileTable
                  columns={columns}
                  data={profileData}
                  // tableTitle="Available Templates"
                  // buttonTitle="Create Profile"
                  dropDownValues={[]}
                  hiddenColumns={["template_name"]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* </Grid> */}
      </RightDrawer>
    </div>
  );
};

export default ViewPermissionProfile;