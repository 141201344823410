import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Loader from "react-loader-spinner";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import RightDrawer from "views/Usermanagement/components/RightDrawer";
import { Api } from "config/api";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Badge from "../Components/badge";
import LoaderHoc from "../../components/HOC/LoaderHoc";
import BadgeTable from "./badgeTable";
import theme from "theme";
const useStyles = makeStyles((theme) => ({
  root:{
    padding: "0.9375rem 70px"
  }
}));
const ViewBadges = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [badgeData, setBadgeData] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationType, setNotificationType] = useState("");
    useEffect(() => {
        renderBadgeData();
      }, []);
    
    const columns = [
        {
          Header: "Resource Name",
          accessor: "resource_name",
        },
        {
          Header: "Resource Type",
          accessor: "resource_type",
        },
        {
          Header: "Badge",
          accessor: "badge",
          Cell: ({ value, row }) => {
            if (value) {
              return <Badge labName={row.original.resource_name} badgeUrl={row.original.badgeUrl} logo = {value}/>;
            }
            return null;
          },
        }
    ];
    let data = [];
    const renderBadgeData = async () => {
        let response = SDCloudBackendAPIWithToken()
          .get("list_badges/")
          .then((resp) => resp.data)
          .then((resp) => {
            resp.badges.forEach((item) => {
              data.push({
                resource_name: item.product_name,
                resource_type: item.product_type,
                badge:"true" ? item.is_badge : "false",
                badgeUrl:item.badge_url
              });
            });
            setBadgeData(data);
            setIsLoading(false);
        })
        .catch((err) => {
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage("Failed to retrieve the data");
        });
    };
    return (
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
          <LoaderHoc isLoading={isLoading}>
          <BadgeTable
                columns={badgeData.length > 0 ? columns : []}
                data={badgeData.length > 0 ? badgeData : []}
                tableTitle="Skill Badges"
                dropDownValues={[]}
                hiddenColumns={[]}
              />
          </LoaderHoc>
        </Grid>
      </Grid>
    );
};
export default ViewBadges;
