import React, { Component } from "react";

import Close from "@material-ui/icons/Close";
// // core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import moment from "moment";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Axios from "axios";
import { Api, Offerings, Urls } from "config/api";
import { Grid } from "@material-ui/core";
import ScheduleLabModal from "views/Components/ServiceOffering/ScheduleLabModal";
import "./ScheduleCluster.css";
import ConfirmationModal from "../../../src/views/Design/Components/ConfirmationDelete";
import { SHOW_LOADER } from "action_creators/actioncreator";
import theme from "../../theme";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";
import { ReactComponent as svg11 } from "../../assets/criterion_networks/svg/11.svg";
import { ReactComponent as svg12 } from "../../assets/criterion_networks/svg/12.svg";
import SvgComponent from "utils/SvgComponent";
import SandboxScheduleModal from "../ServiceOffering/ExperienceCenter/Sandboxes/SandboxScheduleModal";
// Toast component

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const styles2 = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  actionsRight: {
    textAlign: "right",
  },
  pl0: {
    paddingLeft: "0!important",
  },
  btnWhite: {
    backgroundColor: "unset",
    color: "#F96B13",
    border: "0",
    fontSize: "18px",

    "&:hover": {
      backgroundColor: "unset",
      color: "#F96B13",
    },
  },
  normalFont: {
    fontSize: "18px",
    color: "#000",
  },
  marginTop: {
    marginTop: "45px",
  },
  description: {
    fontSize: "18px",
    marginTop: "20px",
  },
};

const placeholderImage = require("assets/criterion_networks/img/LearningLab_defaultimg.png");

// export default function ScheduleCluster() {
//   const [data, setData] = React.useState([]);
//   const [hideModal, setModalStatus] = React.useState(true);
//   const [isNeeded, setIsNeeded] = React.useState(false);

//   const modalStatus = () => {
//     //console.log("inside modal status");
//     setModalStatus(false);
//   }

//   // React.useEffect(() => {
//   //   modalStatus
//   // }, [hideModal])

// const handleDelete = uuid => {
//   //console.log(uuid);
//   Axios.post(
//     Api.delScheduleCluster,
//     { uuid: uuid },
//     {
//       headers: {
//         "Content-type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("user")}`
//       }
//     }
//   )
//     .then(() => {
//       setIsNeeded(true)
//     })
//     .catch(err => {
//       //console.log(err.response);
//     });
// };

// const handleEdit = item => {
//   //console.log(item);
//   setModalStatus(false);
// };

//   React.useEffect(() => {
//     let newData = [];

//     Axios.post(
//       Api.getScheduleCluster,
//       { name: "list" },
//       {
//         headers: {
//           "Content-type": "application/json",
//           Authorization: `Bearer ${localStorage.getItem("user")}`
//         }
//       }
//     )
//       .then(dataTable => {
//         dataTable.data.list.map((prop, key) => {
//           if (prop.deleted === false) {
//             newData.push(prop);
//           }
//         });
//         setData(newData);
//       })
//       .catch(err => {
//         //console.log(err.response);
//       });
//   }, [setIsNeeded]);
//   const classes = useStyles();

//   //console.log("hideModal after", hideModal);
//   return (
//     <GridContainer>
//       <GridItem xs={12}>
//         {
//           data.length > 0 && data.map((item, key) => {
//             const imageUrl = item.image
//             ? item.image
//             : placeholderImage;
//             // let dateTime = moment(item.schedule_start_time).format("DD MMM YYYY HH:mm A")
//             //console.log("moment(timestamp).toISOString(true)", moment(item.schedule_start_time).toISOString(true));
//             var gmtDateTime = moment.utc(item.schedule_start_time)
//             var local = gmtDateTime.local().format('DD MMM YYYY hh:mm A');
//             var timepart = gmtDateTime.local().format('hh:mm')

//             return(
//               <div class="sortable-moves" key={key}>
//                 <img class='img-fluid p-absolute image-dim' src={imageUrl} alt=''></img>
//                 <h6 class="card-title">{item.display_name}</h6>
//                 <h6 className={description}>{"Description"}</h6>
//                 <Grid container className={classes.marginTop}>
//                   <GridItem xs={12} md={6} className={classes.pl0}>
//                     <p className={classes.normalFont}> Schedule Date & Time: <b>{local}</b></p>
//                   </GridItem>
//                   <GridItem xs={12} md={6}>
//                     <div className={classes.actionsRight}>
//                       <Button
//                           onClick={() => {
//                             handleEdit(item);
//                           }}
//                           className={classes.btnWhite}
//                         >
//                         Edit
//                         </Button>
//                         {hideModal ? null : (
//                           <ScheduleLabModal
//                             mode="Edit"
//                             labScheduleData={{
//                               resourceType: Offerings.LEARNING_LABS,
//                               identifier: item.template_name,
//                               fullTitle: item.display_name
//                             }}
//                             offeringType={Offerings.LEARNING_LABS.title}
//                             openModal={hideModal}
//                             initialValues={{
//                               startDate: moment(item.schedule_start_time).format("YYYY-MM-DD"),
//                               startTime: timepart
//                             }}
//                             hideModal={() => modalStatus()}
//                           />
//                         )}
//                         <Button
//                           onClick={() => {
//                             handleDelete(item.uuid);
//                           }}
//                           className={classes.btnWhite}
//                         >
//                           Cancel
//                         </Button>
//                     </div>
//                   </GridItem>
//                 </Grid>
//               </div>
//             )
//           })
//         }
//       </GridItem>
//     </GridContainer>
//   );
// }

class ScheduleCluster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideModal: true,
      data: [],
      salesDemo: [],
      poc: [],
      searchInput: props.ScheduleSearchInput,
      confirmModal: false,
      delUUID: "",
      editModel: {},
      labhours: {},
      salesDemoIsUpdated: false,
      pocIsUpdated: false,
      dataIsUpdated: false,
    };
  }

  componentDidMount = async () => {
    //console.log("inside did mount");
    let newData = [];
    let newDataSalesDemo = [];
    let newDataPOC = [];

    await Axios.post(
      Api.getScheduleCluster,
      { name: "list",resource_type:"poc" },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        },
      }
    )
      .then((dataTable) => {
        dataTable.data.list.map((prop, key) => {
          if (prop.deleted === false) {
            if (prop.resource_type === "Learning Lab") {
              newData.push(prop);
            }
            if (prop.resource_type === "PoC") {
              newDataPOC.push(prop);
            }
            if (prop.resource_type === "Sales Demos") {
              newDataSalesDemo.push(prop);
            }
          }
        });
        this.setState({
          data: newData,
          salesDemo: newDataSalesDemo,
          poc: newDataPOC,
          pocIsUpdated: true,
          dataIsUpdated: true,
          salesDemoIsUpdated: true,
        });
      })
      .catch((err) => {
        this.setState({
          pocIsUpdated: false,
          dataIsUpdated: false,
          salesDemoIsUpdated: false,
        });
        //console.log(err.response);
      });
  };

  componentWillReceiveProps = (newProps) => {
    if (newProps !== "") {
      this.ScheduleSearch();
    }
  };

  setConfirmModalClose = () => {
    this.setState({
      confirmModal: false,
    });
  };

  setConfirmModal = () => {
    this.setState({
      confirmModal: false,
      pocIsUpdated: true,
      dataIsUpdated: true,
      salesDemoIsUpdated: true,
    });
    Axios.post(
      Api.delScheduleCluster,
      { uuid: this.state.delUUID },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        },
      }
    )
      .then(() => {
        toast.success("Canceled Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.scheduleList();
      })
      .catch((err) => {
        const payload = err?.response?.data?.message || "unknown error occured";
        toast.error(payload, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  ScheduleSearch = () => {
    let newData = [];
    let newDataSalesDemo = [];
    let newDataPOC = [];
    Axios.post(
      Api.getScheduleCluster,
      { name: "list" },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        },
      }
    )
      .then((dataTable) => {
        dataTable.data.list.map((prop, key) => {
          if (prop.deleted === false) {
            if (prop.resource_type === "Learning Lab") {
              newData.push(prop);
            }
            if (prop.resource_type === "PoC") {
              newDataPOC.push(prop);
            }
            if (prop.resource_type === "Sales Demos") {
              newDataSalesDemo.push(prop);
            }
            // newData.push(prop);
          }
        });

        if (this.props.offeringType === "learninglabs") {
          const searchResult =
            newData &&
            newData.filter((obj) =>
              ["display_name"].some((key) => {
                return (
                  obj[key] &&
                  obj[key]
                    .toLowerCase()
                    .includes(this.props.ScheduleSearchInput.toLowerCase())
                );
              })
            );
          this.setState({
            data: searchResult,
          });
        } else if (this.props.offeringType === "PoCLibrary") {
          const searchResult =
            newDataPOC &&
            newDataPOC.filter((obj) =>
              ["cluster_name"].some((key) => {
                return (
                  obj[key] &&
                  obj[key]
                    .toLowerCase()
                    .includes(this.props.ScheduleSearchInput.toLowerCase())
                );
              })
            );
          this.setState({
            poc: searchResult,
          });
        } else if (this.props.offeringType === "SalesDemo") {
          const searchResult =
            newDataSalesDemo &&
            newDataSalesDemo.filter((obj) =>
              ["display_name"].some((key) => {
                return (
                  obj[key] &&
                  obj[key]
                    .toLowerCase()
                    .includes(this.props.ScheduleSearchInput.toLowerCase())
                );
              })
            );
          this.setState({
            salesDemo: searchResult,
          });
        }
        this.setState({
          pocIsUpdated: true,
          dataIsUpdated: true,
          salesDemoIsUpdated: true,
        });
      })
      .catch((err) => {
        this.setState({
          pocIsUpdated: true,
          dataIsUpdated: true,
          salesDemoIsUpdated: true,
        });
        //console.log(err.response);
      });
  };

  hideModal = () => {
    this.setState({
      hideModal: true,
    });
  };
  showModal = () => {
    this.setState({
      hideModal: false,
    });
  };

  handleEdit = (item) => {
    const { noofhours } = this.props;
    if(noofhours) {
      const no_of_hours = noofhours.filter(
          (value) =>
              value.display_name.toLowerCase() === item.display_name.toLowerCase()
      );
      this.setState({ editModel: item, labhours: no_of_hours || null });
    }else{
      this.setState({ editModel: item, labhours: null });
    }
    this.showModal();
  };

  scheduleList = async () => {
    let newData = [];
    let newDataSalesDemo = [];
    let newDataPOC = [];

    await Axios.post(
      Api.getScheduleCluster,
      { name: "list" },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        },
      }
    )
      .then((dataTable) => {
        dataTable.data.list.map((prop, key) => {
          if (prop.deleted === false) {
            if (prop.resource_type === "Learning Lab") {
              newData.push(prop);
            }
            if (prop.resource_type === "PoC") {
              newDataPOC.push(prop);
            }
            if (prop.resource_type === "Sales Demos") {
              newDataSalesDemo.push(prop);
            }
            // newData.push(prop);
          }
        });

        this.setState({
          data: newData,
          salesDemo: newDataSalesDemo,
          poc: newDataPOC,
          pocIsUpdated: true,
          dataIsUpdated: true,
          salesDemoIsUpdated: true,
        });
      })
      .catch((err) => {
        this.setState({
          pocIsUpdated: false,
          dataIsUpdated: false,
          salesDemoIsUpdated: false,
        });
        //console.log(err.response);
      });
  };

  handleDelete = (uuid) => {
    this.setState({
      delUUID: uuid,
      confirmModal: true,
    });
  };

  render() {
    const { data, hideModal, poc, salesDemo, editModel, labhours } = this.state;
    const { offeringType, classes } = this.props;

    var gmtDateTime = moment.utc(editModel.schedule_start_time);
    var timepart = gmtDateTime.local().format("HH:mm");
    var timepart_date = gmtDateTime.local().format("YYYY-MM-DD HH:mm");
    // //console.log("time",timepart)

    if (offeringType === "learninglabs") {
      return (
        <GridContainer>
          <ToastContainer autoClose={5000} />
          <GridItem xs={12}>
            {data.length > 0 &&
              data.map((item, key) => {
                const imageUrl = item.image ? item.image : "";

                var gmtDateTime = moment.utc(item.schedule_start_time);
                var local = gmtDateTime.local().format("DD MMM YYYY hh:mm A");
                var timepart = gmtDateTime.local().format("hh:mm A");
                return (
                  <div class="sortable-moves" key={key}>
                    {imageUrl.trim() !== "" ? (
                      <img
                        class="img-fluid p-absolute image-dim"
                        src={imageUrl}
                        alt=""
                      ></img>
                    ) : (
                      <div className="imgdefault">
                        <SvgComponent
                          className={this.props.classes.absoluteImg}
                          type="default"
                          svgSource={svg12}
                        />
                      </div>
                    )}
                    <h6 class={`card-title ${this.props.classes.font}`}>
                      {item.display_name}
                    </h6>
                    <h6 className="description">{""}</h6>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={7}>
                        <p
                          className={`card-content position float-left pt-4 ${this.props.classes.font}`}
                        >
                          {" "}
                          Schedule Date & Time:{" "}
                          <span>
                            <b>{local}</b>
                          </span>
                          {JSON.parse(localStorage.getItem("profileData"))
                            .privilege === "admin" ? (
                            <>
                              {" "}
                              <span> Scheduled by: </span>
                              <span>
                                <b>{item.user}</b>
                              </span>{" "}
                            </>
                          ) : null}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        justifyContent="flex-end"
                        className="right-button"
                      >
                        <div
                          className={"card-content float-right position pt-4"}
                        >
                          {/* {hideModal ? null : (
                            <ScheduleLabModal
                              mode="Edit"
                              labScheduleData={{
                                resourceType: Offerings.LEARNING_LABS,
                                identifier: item.template_name,
                                fullTitle: item.display_name
                              }}
                              offeringType={Offerings.LEARNING_LABS.title}
                              openModal={hideModal}
                              initialValues={{
                                startDate: moment(item.schedule_start_time).format("YYYY-MM-DD"),
                                startTime: timepart
                              }}
                              hideModal={this.hideModal}
                              uuid={item.uuid}
                              refetch={this.scheduleList}
                            />
                          )} */}
                          <Button
                            onClick={() => {
                              this.handleDelete(item.uuid);
                            }}
                            className={`btnWhite ${classes.scheduleBtnStyle}`}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.handleEdit(item);
                            }}
                            className={`btnWhite ${classes.scheduleBtnStyle}`}
                          >
                            Edit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            {hideModal ? null : (
              <ScheduleLabModal
                mode="Edit"
                labScheduleData={{
                  resourceType: Offerings.LEARNING_LABS,
                  identifier: editModel.template_name,
                  fullTitle: editModel.display_name,
                  labhours: labhours[0]?.labhours,
                }}
                offeringType={Offerings.LEARNING_LABS.title}
                page={offeringType}
                openModal={hideModal}
                initialValues={{
                  startDate: gmtDateTime.local().format("YYYY-MM-DD"),
                  startTime: timepart,
                  run_time: timepart_date,
                }}
                hideModal={this.hideModal}
                uuid={editModel.uuid}
                refetch={this.scheduleList}
              />
            )}
            {data && data.length === 0 && this.state.dataIsUpdated ? (
              <h4
                style={{
                  fontWeight: 400,
                  fontFamily: theme.palette.fontList.selectedFont,
                }}
              >
                {" "}
                {this.props.value}{" "}
              </h4>
            ) : (
              ""
            )}
            {!this.state.dataIsUpdated && (
              <Loader
                type="ThreeDots"
                color={theme.palette.secondary.main}
                height={50}
                width={50}
                visible={true}
                style={{ justifyContent: "center", display: "flex" }}
              />
            )}
            <ConfirmationModal
              visible={this.state.confirmModal}
              onClose={() => this.setConfirmModalClose()}
              onConfirm={() => this.setConfirmModal()}
              message="Are you sure want to cancel the schedule?"
              font={classes.font}
              //selectedTemplate={selectedTemplates.length}
            />
          </GridItem>
        </GridContainer>
      );
    } else if (offeringType === "SalesDemo") {
      return (
        <GridContainer>
          <ToastContainer autoClose={5000} />
          <GridItem xs={12}>
            {salesDemo.length > 0 &&
              salesDemo.map((item, key) => {
                const imageUrl = item.image ? item.image : "";

                var gmtDateTime = moment.utc(item.schedule_start_time);
                var local = gmtDateTime.local().format("DD MMM YYYY hh:mm A");
                var timepart = gmtDateTime.local().format("hh:mm A");
                return (
                  <div class="sortable-moves" key={key}>
                    {imageUrl.trim() !== "" ? (
                      <img
                        class="img-fluid p-absolute image-dim"
                        src={imageUrl}
                        alt=""
                      ></img>
                    ) : (
                      <div className="imgdefault">
                        <SvgComponent
                          className={this.props.classes.absoluteImg}
                          type="default"
                          svgSource={svg11}
                        />
                      </div>
                    )}
                    <h6 class={`card-title ${this.props.classes.font}`}>
                      {item.display_name}
                    </h6>
                    <h6 className="description">{""}</h6>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={7}>
                        <p
                          className={`card-content position float-left pt-4 ${this.props.classes.font}`}
                        >
                          {" "}
                          Schedule Date & Time:{" "}
                          <span>
                            <b>{local}</b>
                          </span>
                          {JSON.parse(localStorage.getItem("profileData"))
                            .privilege === "admin" ? (
                            <>
                              {" "}
                              <span> Scheduled by: </span>
                              <span>
                                <b>{item.user}</b>
                              </span>{" "}
                            </>
                          ) : null}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        justifyContent="flex-end"
                        className="right-button"
                      >
                        <div
                          className={"card-content float-right position pt-4"}
                        >
                          {/* {hideModal ? null : (
                          <ScheduleLabModal
                            mode="Edit"
                            labScheduleData={{
                              resourceType: Offerings.LEARNING_LABS,
                              identifier: item.template_name,
                              fullTitle: item.display_name
                            }}
                            offeringType={Offerings.LEARNING_LABS.title}
                            openModal={hideModal}
                            initialValues={{
                              startDate: moment(item.schedule_start_time).format("YYYY-MM-DD"),
                              startTime: timepart
                            }}
                            hideModal={this.hideModal}
                            uuid={item.uuid}
                            refetch={this.scheduleList}
                          />
                        )} */}
                          <Button
                            onClick={() => {
                              this.handleDelete(item.uuid);
                            }}
                            className={`btnWhite ${classes.scheduleBtnStyle}`}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.handleEdit(item);
                            }}
                            className={`btnWhite ${classes.scheduleBtnStyle}`}
                          >
                            Edit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            {hideModal ? null : (
              <ScheduleLabModal
                mode="Edit"
                labScheduleData={{
                  resourceType: Offerings.SALES_DEMO,
                  identifier: editModel.template_name,
                  fullTitle: editModel.display_name,
                  labhours: labhours[0]?.labhours,
                }}
                offeringType={Offerings.SALES_DEMO.title}
                page={offeringType}
                openModal={hideModal}
                initialValues={{
                  startDate: gmtDateTime.local().format("YYYY-MM-DD"),
                  startTime: timepart,
                  run_time: timepart_date,
                }}
                hideModal={this.hideModal}
                uuid={editModel.uuid}
                refetch={this.scheduleList}
              />
            )}
            {salesDemo && salesDemo.length === 0 && this.state.dataIsUpdated ? (
              <h4
                style={{
                  fontWeight: 400,
                  fontFamily: theme.palette.fontList.selectedFont,
                }}
              >
                {" "}
                {this.props.value}{" "}
              </h4>
            ) : (
              ""
            )}
            {!this.state.dataIsUpdated && (
              <Loader
                type="ThreeDots"
                color={theme.palette.secondary.main}
                height={50}
                width={50}
                visible={true}
                style={{ justifyContent: "center", display: "flex" }}
              />
            )}
            <ConfirmationModal
              visible={this.state.confirmModal}
              onClose={() => this.setConfirmModalClose()}
              onConfirm={() => this.setConfirmModal()}
              message="Are you sure want to cancel the schedule?"
              font={classes.font}
              //selectedTemplate={selectedTemplates.length}
            />
          </GridItem>
        </GridContainer>
      );
    } else if (offeringType === "PoCLibrary") {
      return (
        <GridContainer>
          <ToastContainer autoClose={5000} />
          <GridItem xs={12}>
            {poc.length > 0 &&
              poc.map((item, key) => {
                const imageUrl = item.image ? item.image : "";

                var gmtDateTime = moment.utc(item.schedule_start_time);
                var local = gmtDateTime.local().format("DD MMM YYYY hh:mm A");
                var timepart = gmtDateTime.local().format("hh:mm");

                return (
                  <div class="sortable-moves" key={key}>
                    {imageUrl.trim() !== "" ? (
                      <img
                        class="img-fluid p-absolute image-dim"
                        src={imageUrl}
                        alt=""
                      ></img>
                    ) : (
                      <div className="imgdefault">
                        <SvgComponent
                          className={this.props.classes.absoluteImg}
                          type="default"
                          svgSource={svg11}
                        />
                      </div>
                    )}
                    <h6 class={`card-title ${this.props.classes.font}`}>
                      {item.cluster_name}
                    </h6>
                    <h6 className="description">{""}</h6>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={7}>
                        <p
                          className={`card-content position float-left pt-4 ${this.props.classes.font}`}
                        >
                          {" "}
                          Schedule Date & Time:{" "}
                          <span>
                            <b>{local}</b>
                          </span>
                          {JSON.parse(localStorage.getItem("profileData"))
                            .privilege === "admin" ? (
                            <>
                              {" "}
                              <span> Scheduled by: </span>
                              <span>
                                <b>{item.user}</b>
                              </span>{" "}
                            </>
                          ) : null}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        justifyContent="flex-end"
                        className="right-button"
                      >
                        <div
                          className={"card-content float-right position pt-4"}
                        >
                          {/* {hideModal ? null : (
                            <ScheduleLabModal
                              mode="Edit"
                              labScheduleData={{
                                resourceType: Offerings.POC_LIBRARY,
                                identifier: item.template_name,
                                fullTitle: item.display_name
                              }}
                              offeringType={Offerings.POC_LIBRARY.title}
                              openModal={hideModal}
                              initialValues={{
                                startDate: moment(item.schedule_start_time).format("YYYY-MM-DD"),
                                startTime: timepart
                              }}
                              hideModal={this.hideModal}
                              uuid={item.uuid}
                              refetch={this.scheduleList}
                            />
                          )} */}
                          <Button
                            onClick={() => {
                              this.handleDelete(item.uuid);
                            }}
                            className={`btnWhite ${classes.scheduleBtnStyle}`}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.handleEdit(item);
                            }}
                            className={`btnWhite ${classes.scheduleBtnStyle}`}
                          >
                            Edit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            {hideModal ? null : (
              <SandboxScheduleModal
                mode="Edit"
                open={!hideModal}
                onClose={this.hideModal}
                initialValues={{
                  name: editModel.cluster_name,
                  startDate: gmtDateTime.local().format("YYYY-MM-DD"),
                  startTime: timepart,
                  run_time: timepart_date,
                }}
                labScheduleData={{
                  resourceType: Offerings.POC_LIBRARY,
                  identifier: editModel.template_name,
                }}
                offeringType={Offerings.POC_LIBRARY.title}
                page={offeringType}
                uuid={editModel.uuid}
                refetch={this.scheduleList}
              />
            )}
            {poc && poc.length === 0 && this.state.pocIsUpdated ? (
              <h4
                style={{
                  fontWeight: 400,
                  fontFamily: theme.palette.fontList.selectedFont,
                }}
              >
                {" "}
                {this.props.value}{" "}
              </h4>
            ) : (
              ""
            )}
            {!this.state.pocIsUpdated && (
              <Loader
                type="ThreeDots"
                color={theme.palette.secondary.main}
                height={50}
                width={50}
                visible={true}
                style={{ justifyContent: "center", display: "flex" }}
              />
            )}
            <ConfirmationModal
              visible={this.state.confirmModal}
              onClose={() => this.setConfirmModalClose()}
              onConfirm={() => this.setConfirmModal()}
              message="Are you sure want to cancel the schedule?"
              font={classes.font}
              //selectedTemplate={selectedTemplates.length}
            />
          </GridItem>
        </GridContainer>
      );
    }
  }
}
ScheduleCluster.propTypes = {
  classes: PropTypes.object.isRequired,
};
//export default ScheduleCluster;
export default withStyles(styles)(ScheduleCluster);
