import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import axios from "axios";
// Material UI Components
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
const useStyles = makeStyles((theme) => ({
  rootAlert: {
    width: '60%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      float: 'left',
    },
  },
  root: {
    // padding: "0 20px",
    backgroundColor: "#ffffff",
  },
  appBar: {
    backgroundColor: "#ffffff !important",
    color: theme.palette.grey["600"],
    boxShadow: "none",
  },
  topologyImage: {
    width: "100%",
    "& img": {
      maxWidth: "100%",
    },
  },
  tabPanel: {
    backgroundColor: "#ffffff",
    "& div": {
      width: "100%",
      margin: "0",
      paddingTop: "5px",
    },
  },
  ipDetailAccess: {
    fontFamily: theme.palette.fontList.selectedFont,
    width: "100%",
    paddingTop: "30px",
    "& table": {
      width: "100%",
      "& thead": {
        textAlign: "left",
        "& tr": {
          borderBottom: "1px solid black",
        },
        "& th": {
          height: "40px",
          color: theme.palette.grey["500"],
        },
      },
      "& tbody": {
        textAlign: "left",
        "& td": {
          height: "40px",
          color: theme.palette.grey["900"],
        },
      },
    },
  },
  interactiveTopologyButton: {
    marginTop: "20px",
  },
  btnColor: {
    background: `${theme.palette.buttonColor.main} !important`,
    "&:hover": {
      background: `${theme.palette.buttonColor.main} !important`,
    }
  },
  customTabRoot: {
    color: `#000`,
    "& .Mui-selected .MuiTab-wrapper": {
      color: `${theme.palette.secondary.main}`
    },
    "& .MuiTab-wrapper": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont,
  },
  buttonStyle1: {
    backgroundColor: theme.palette.secondary.main,
    float:"left",
    color: "#fff",
    marginTop: "20px",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonStyle2: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    float:"right",
    marginTop: "20px",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  para:{
    fontSize:"14px",
    color:"#000",
    margin:"25px"
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
function Badge(props) {
  const [value, setValue] = useState(0);
  const [svgContent, setSvgContent] = useState("");
  const classes = useStyles();
  useEffect(() => {
    if (props.labName) {
      const encodedBadgeUrl = btoa(props.badgeUrl);
      const badgeUrl = `/badge/?labName=${encodeURIComponent(props.labName)}&badgeUrl=${encodeURIComponent(encodedBadgeUrl)}`;
      SDCloudBackendAPIWithToken()
        .get(badgeUrl)
        .then((response) => {
          const decodedSvg = atob(response.data);
          setSvgContent(decodedSvg);
        })
        .catch((error) => {
          console.error("Error fetching badge:", error);
        });
    }
  }, [props.badgeUrl, props.labName]);
  
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePrev = () => {
    if (value === 0) {
      if (props.categoriesData && props.categoriesData[0].length > 0) {
      const lastCategoryIndex = props.categoriesData[0].length - 1;
      const lastCategoryName = props.categoriesData[0][lastCategoryIndex].sandboxes_category_name;
      const exercisesForSelectedCategory = props.exercisesData[0].filter(
        (exercise) => exercise.admin_sandboxes_categories_id === props.categoriesData[0][lastCategoryIndex].id
      );
      const sortedExercisesData = exercisesForSelectedCategory.slice().sort((a, b) => a.exercise_position - b.exercise_position); 
      props.changePrevActiveTab(lastCategoryName,sortedExercisesData.length-1,lastCategoryIndex);
      } else {
        props.changeActiveTab("Overview")
      }
    } else {
      setValue((prevValue) => Math.max(prevValue - 1, 0));
    }
  };
  const handleNext = () => {
    if (value === 0) {
      // setValue(1);
      if(props.sandboxCheatsheets && props.sandboxCheatsheets.length > 0){
        const sortedCheatsheets = props.sandboxCheatsheets.slice().sort((a, b) => a.cheatsheet_position - b.cheatsheet_position);
        props.changeActiveTab("Cheat sheets");
      } else {
        props.changeActiveTab("Access devices")
      } 
    } else {
      setValue((prevValue) => Math.min(prevValue + 1, 1));
    }
  };
  
  return (
    <div className={classes.root}>
    {!props.logo && (
      props.type !== "Learning" && (
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            classes={{
              root: classes.customTabRoot
            }}
          >
            <Tab label="Skill Badge" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
      )
    )}
    <TabPanel value={value} index={0} className={classes.tabPanel}>
      {!props.logo && (
        <p className={classes.para}>We are delighted to inform you that you have successfully completed the {props.labName} course and earned the {props.labName} badge."</p>
      )}
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        style={{
          flexDirection: "row",
          flexWrap: "nowrap",
          gap: "8px", 
          textAlign:"center"
        }}
      >
      {svgContent ? (
        <div
          dangerouslySetInnerHTML={{ __html: svgContent }}
          style={
            props.type
              ? {}
              : {
                  width: "30px",
                  height: "30px",
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginBottom: "10px",
                }
          }
          {...(props.type ? {} : { title: props.labName })}
        />
      ) : (
        null
      )}
      </Grid>
      {!props.logo && (
        <p className={classes.para}>We commend your dedication to professional development and encourage you to continue exploring our learning resources.</p>
      )}
      {!props.logo && (
        props.type !== "Learning" && (
        <div>
          <Button
            onClick={handlePrev}
            className={classes.buttonStyle1}
          >
            Prev
          </Button>
          <Button
            onClick={handleNext}
            className={classes.buttonStyle2}
          >
            Next
          </Button>
        </div>
        )
      )}
    </TabPanel>
  </div>

  );
  };
  
  export default Badge;
  