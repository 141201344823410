import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { FaStar } from "react-icons/fa";
import { Box, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";


// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { FormGroup, FormControl } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import FormControlLabel from "@material-ui/core/FormControlLabel";

// Local
import { SDCloudBackendAPIWithToken } from "../../apis/backendAPI";
import NotificationAlert from "../Components/NotificationAlert";
import Exercises from "views/ServiceOffering/ExperienceCenter/Sandboxes/AccessSandbox/Exercises";
import { Api } from "config/api";

const inputProps = {
  disableAnimation: true,
  shrink: false
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },btnStyle: {
    background: `${theme.palette.buttonColor.main} !important`,
  },
  customMenu: {
    "& .MuiListItem-root.Mui-selected, & .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "#f6f6f6", // Change this to your desired color
     
    },
  },

  disabledField: {
    color: "#000 !important",  // Force black text
    opacity: 1, // Remove faded look
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#000 !important", // Ensure input text is dark
    },
    "& .MuiInputLabel-root.Mui-disabled": {
      color: "#000 !important", // Ensure label text is dark
    },
  },
}));

const formControlProps = {
  root: {
    marginTop: "10px"
  }
};

function FeedbackLabs({ category, subcategory ,onClose,submitFeedback,uuid}) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [ratings, setRatings] = useState({
    courseContent: 0,
    easeOfUse: 0,
    experience: 0
  });
  const [categories, setCategories] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subItems, setSubItems] = useState([]); // Sub-items for second dropdown
  const [selectedSubItem, setSelectedSubItem] = useState(""); // Selected sub-item

 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResponse, profileResponse] = await Promise.all([
          SDCloudBackendAPIWithToken().get(Api.getFeedback),
          SDCloudBackendAPIWithToken().get(Api.getProfiledata),
        ]);

  
        setCategories(categoryResponse.data.inventory_type || []);
        setProfileData(profileResponse.data || {}); // Ensure entire response is stored
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (category) {
      setSelectedCategory(category); // Set main category
      let categoryKey = category.toLowerCase().replace(/es$/, "").replace(/s$/, "");

      if (profileData?.data?.[categoryKey]) {
        setSubItems(profileData.data[categoryKey]); // Set available subcategories
      } else {
        setSubItems([]); // Reset subcategories if no data
      }
    }

    if (subcategory) {
      setSelectedSubItem(subcategory); // Set subcategory
    }
  }, [category, subcategory, profileData]);

  
  const handleCategoryChange = (event) => {
    const selected = event.target.value;
    setSelectedCategory(selected);
  
    // Convert to lowercase
    let categoryKey = selected.toLowerCase();
  
    // Handle pluralization rules
    if (categoryKey.endsWith("es")) {
      categoryKey = categoryKey.slice(0, -2); 
    } else if (categoryKey.endsWith("s")) {
      categoryKey = categoryKey.slice(0, -1); 
    }
  
  
  
    // Check if profileData contains the corrected category key
    if (profileData?.data?.[categoryKey]) {
      setSubItems(profileData.data[categoryKey]); 
    } else {
      setSubItems([]); // Reset if no data found
    }
  };
  
  
  
  // Handle sub-item selection
  const handleSubItemChange = (event) => {
    setSelectedSubItem(event.target.value);
  };

  // const handleRating = (value) => {
  //   setRating(value);
  //   alert(`You have given ${value} star(s).`);
  // };
    // Handle rating updates
    const handleRatingChange = (field, value) => {
      setRatings((prev) => ({ ...prev, [field]: value }));
    };
  
    const [errors, setErrors] = useState({});

    const validateForm = () => {
      let newErrors = {};

      if (!name.trim()) newErrors.name = "Name is required.";
      if (!email.trim()) {
        newErrors.email = "Email is required.";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        newErrors.email = "Invalid email format.";
      }

      if (!feedback.trim()) newErrors.feedback = "Feedback content is required.";
      if (!selectedCategory) newErrors.selectedCategory = "Please select a category.";

      // Ensure at least one rating is selected
      if (Object.values(ratings).every(rating => rating === 0)) {
        newErrors.ratings = "Please provide at least one rating.";
      }

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0; // Return true if no errors
    };

  

  const handleSubmit = e => {
    e.preventDefault();
    if (!validateForm()) {
      return; 
    }
    const payload = {
      name: name,
      email: email,
      content: feedback,
      category: selectedCategory,  
      ratings: ratings,
      uuid:uuid
              
    };
    // Add subCategory only for "Learning" and "Sandbox"
    if (selectedCategory === "Learnings" || selectedCategory === "Sandboxes") {
      payload.subCategory = selectedSubItem || ""; 
    }

    SDCloudBackendAPIWithToken()
      .post("/feedback", payload)
      .then(resp => {
        return resp.data;
      })
      .then(data => {
        setShowNotification(true);
        setNotificationMessage(data.message);
        setNotificationType("success");
        setTimeout(() => {
          submitFeedback(email);
        }, 1000);
      })
      .catch(err => {
        let message = err.response.data.message;
        setShowNotification(true);
        setNotificationMessage(message);
        setNotificationType("error");
      });
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
        <IconButton 
            onClick={onClose} 
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
           
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Name & Email Row */}
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                variant="filled"
                label="Your Name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="email"
                variant="filled"
                label="Enter Your Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Category"
                    variant="filled"
                    fullWidth
                    value={category}
                    InputProps={{ readOnly: true }}
                />
                </Grid>

                <Grid item xs={12} md={6}>
                <TextField
                    label="Subcategory"
                    variant="filled"
                    fullWidth
                    value={subcategory}
                    InputProps={{ readOnly: true }}
                />
                </Grid>

            {/* Feedback Field - Full Row */}
            <TextField
                    type="text"
                    variant="filled"
                    label="Your Feedback"
                    fullWidth
                    multiline
                    rows={4}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    error={!!errors.feedback}  // Add error prop
                    helperText={errors.feedback} // Show validation message
                />

            <Grid item xs={12}>
          {["courseContent", "easeOfUse", "experience"].map((field) => (
            <Grid item xs={12} key={field} style={{ marginBottom: "10px" }}>
              <Typography variant="body1" style={{ fontWeight: 600, marginBottom: "5px" }}>
                {field === "courseContent"
                  ? "Course Content"
                  : field === "easeOfUse"
                  ? "Ease of Use"
                  : "Experience"}
              </Typography>
              <div style={{ display: "flex", gap: "5px" }}>
                {[...Array(5)].map((_, index) => (
                  <label key={index} style={{ cursor: "pointer" }}>
                    <input
                      type="radio"
                      value={index + 1}
                      style={{ display: "none" }}
                      onClick={() => handleRatingChange(field, index + 1)}
                    />
                    <FaStar
                      size={30}
                      color={index + 1 <= ratings[field] ? "#ffc107" : "#e4e5e9"}
                    />
                  </label>
                ))}
              </div>
            </Grid>
          ))}
            {errors.ratings && <Typography color="error">{errors.ratings}</Typography>}
        </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                fullWidth
                className={classes.btnStyle}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>

          <NotificationAlert
            notificationType={notificationType}
            showNotification={showNotification}
            message={notificationMessage}
          />
        </form>

        </Grid>
      </Grid>
    </div>
  );
}

export default FeedbackLabs;