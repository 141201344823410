import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api, Offerings, Urls } from "config/api";
import { asyncSetState } from "utils/utilfunction";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment";
import { msToStr } from "views/ServiceOffering/LearningLab/acess labs/sdntokenizerhelper";
import SvgComponent from "utils/SvgComponent";
import { ReactComponent as svg12 } from "../../../assets/criterion_networks/svg/12.svg";
import Button from "@material-ui/core/Button";
import lock from "assets/img/lock.png";
import unlock from "assets/img/unlock.png";
import {
  HIDE_LOADER,
  SET_NAVBAR_TITLE,
  SHOW_LOADER,
  SHOW_NOTIFICATION,
} from "action_creators/actioncreator";
import { CMSAPIWithToken } from "apis/CMS";
import { v4 as uuidv4 } from "uuid";
import LaunchNewOfferingCard from "views/Components/ServiceOffering/LaunchNewOfferingCard";
import globalTheme from "../../../theme";
import { getSandboxesCluster } from "apis/APIServices";
import LearningCards from "./LearningCards";
import LearningCenterUpgrade from "../../../views/Components/LearningCenterUpgrade";
import { getResourcesStatus } from "apis/APIServices";
import { getServiceStatus } from "apis/APIServices";
import ScheduleCluster from "../../../views/ScheduleCluster/ScheduleCluster";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import Chatbot from "views/Components/chatbot/Chatbot";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  imgFilter: {
    filter: theme.palette.secondary.filterImage,
    background: "transparent !important",
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont
  },
  absoluteImg: {
    height: "114px",
    width: "114px",
    left: "56px",
    borderRadius: "0px",
    top: "28px",
    background: "black",
  },
}));

const selectedlabTypeStyle = {
  color: globalTheme.palette.secondary.main,
};

const EXPERIENCEFILTER = "Learning Center";
const templateFilters = ["All", "Recent"];
const notes = {
  reservedIpNote:
    "Option selected will reflect the IP address for the learning lab.",
};

class LearningLab extends React.Component {
  constructor(props) {
    super(props);
    this.offeringType = props.offeringType || Offerings.LEARNING_LABS.resource;
    this.state = {
      app_bar: false,
      app_loader: true,
      adminLabArray: undefined,
      activeLabsTime: {},
      inactiveLabsTime:{},
      labIDToNameMap: {},
      reservedIp: null,
      currentLabType: "",
      labTypetoLabMap: {},
      allLabTemplates: [],
      profileDetails: null,
      resourceDetails: null,
      provisionedLab: {},
      reservedIpLoaded: false,
      canLaunch: true,
      value: 0,
      subscribedList: [],
      subscribedListTemp: [],
      searchfilter: [],
      searchText: "",
      currentTabIndex: 0,
      activeLabs: [],
      inactiveLabs: [],
      subscribedLabs: [],
      unsubscribedLabs: [],
      allLabs: [],
      allLabsTemp: [],
      activeLabsTemp: [],
      inactiveLabsTemp: [],
      cmsdata: [],
      isProfileLoaded: false,
      currentLabSubItem: "All",
      activeLabSubItem: "Active",
      selectedRows: [],
      currentSelectedItem: "",
      resources: [],
      services: [],
      sections: [
        {
          name: "Designer Templates",
          id: "designer",
          ScreenName: "Design",
          Upgrade: "Design Center",
        },
        {
          name: "Learning labs",
          id: "learning",
          ScreenName: "Learning",
          Upgrade: "Learning Center",
        },
        {
          name: "Experience Center - Showcases",
          id: "sales_demo",
          tempId: "showcases",
          ScreenName: "Showcases",
          Upgrade: "Experience Center",
        },
        {
          name: "Experience Center - Sandboxes",
          id: "poc",
          tempId: "sandboxes",
          ScreenName: "Sandboxes",
          Upgrade: "Experience Center",
        },
      ],
    };
  }
  
  componentDidMount = async () => {  
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const isInternal = hasDomain
    
    if (this.state.app_loader === true) {
      this.props.dispatch(SHOW_LOADER());
    }
    this.props.dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - "+EXPERIENCEFILTER:EXPERIENCEFILTER));
    this.props.dispatch(SHOW_LOADER());
    this.getResourcesStatusAPI();
    if (
      JSON.parse(localStorage.getItem("profileData")).privilege === "developer"
    ) {
      await this.getNewLearnings();
      await this.getAdminLabTypes();
      await this.getResources();
      await this.getProfile();
    } else if (
      JSON.parse(localStorage.getItem("profileData")).privilege === "admin"
    ) {
      await this.getNewLearnings();
      await this.getAdminLabTypes();
      await this.getResources();
    } else if (
      JSON.parse(localStorage.getItem("profileData")).privilege ===
      "profile_admin"
    ) {
      await this.getNewLearnings();
      await this.getAdminLabTypes();
      await this.getResources();
      await this.getProfile();
    }
    await this.getInventory();

    this.getCmsData(
      this.state.profileDetails,
      this.state.resourceDetails
    ).finally(() => {
      let currentTabScreen;
      let currentTabIndex;
      if (JSON.parse(localStorage.getItem("profileData")).privilege === "admin") {
        currentTabIndex = 0;
        currentTabScreen = "All";
      } else {
        currentTabIndex = 1;
        currentTabScreen = "Subscribed";
      }
      if (
        JSON.parse(localStorage.getItem("profileData")).privilege === "admin" ||
        JSON.parse(localStorage.getItem("profileData")).privilege ===
          "profile_admin"
      ) {
        if (this.props.location.state && this.props.location.state.currentTab) {
          currentTabScreen =
            this.props.location.state.currentTab === "Active"
              ? "ActiveLab"
              :this.props.location.state.currentTab === "Inactive"
              ? "InactiveLab"
              : "All";
        }
        if (this.props.location.state && this.props.location.state.currentTab) {
          currentTabIndex =
            this.props.location.state.currentTab === "Active" 
            ? 1 
            : this.props.location.state.currentTab === "Inactive"
            ? 2
            : 0;
        }
      } else {
        if (this.props.location.state && this.props.location.state.currentTab) {
          currentTabScreen =
            this.props.location.state.currentTab === "Active"
              ? "ActiveLab"
              : this.props.location.state.currentTab === "Inactive"
              ? "InactiveLab"
              : this.props.location.state.currentTab === "Subscribed"
              ? "Subscribed"
              : "All";
        }
        if (this.props.location.state && this.props.location.state.currentTab) {
          currentTabIndex =
            this.props.location.state.currentTab === "Active"
              ? 2
              :this.props.location.state.currentTab === "Inactive"
              ? 3
              : this.props.location.state.currentTab === "Subscribed"
              ? 1
              : 0;
        }
      }
      this.setState(
        {
          value: currentTabIndex,
        },
        () => {
          this.handleHeaderClick(currentTabScreen);
        }
      );
      this.setState({
        app_bar: true,
      });
      this.props.dispatch(HIDE_LOADER());
    });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { adminLabArray, currentLabType, currentSelectedItem } = this.state;
    const labTypetoLabMap = {};
    let labIDToNameMap = this.state.labIDToNameMap;
    

    if (
      prevState.currentLabType != this.state.currentLabType &&
      currentLabType != "ActiveLab" && currentLabType != "InactiveLab"
    ) {
      if (adminLabArray && adminLabArray.length > 0) {
        adminLabArray.forEach((item) => {
          if (
            labTypetoLabMap[(labIDToNameMap[item.admin_lab_types_id]?.name)]
          ) {
            labTypetoLabMap[
              (labIDToNameMap[item.admin_lab_types_id]?.name)
            ].push(item);
          } else {
            labTypetoLabMap[(labIDToNameMap[item.admin_lab_types_id]?.name)] = [
              item,
            ];
          }
        });
      }
      this.setState({
        labTypetoLabMap,
      });
      this.handleHeaderClick(currentLabType);
      
    }

    if (prevState.currentLabType !== this.state.currentLabType) {
      // this.setState({
      //   isProfileLoaded: true
      // });
      if (this.offeringType.title === Offerings.POC_LIBRARY.title) {
        const res = await getSandboxesCluster();
        if (!res.success) {
          const payload = {
            message: res.msg || "unknown error occured",
            color: "danger",
          };
          this.props.dispatch(SHOW_NOTIFICATION({ payload }));
        } else {
          const clouds = res.data.clouds;
          const activeLabs = clouds.filter(
            (item) => !item.deleted && item.uuid && item.status !== "suspended"
          );
          const terminatedLabs = clouds.filter((item) => item.deleted);
          const inactiveLabs = clouds.filter(
            (item) =>
              (!item.deleted && item.status === "suspended") ||
              item.status === "terminated"
          );
          let updatedState = {
            activeLabs,
            inactiveLabs,
          };
          if (activeLabs.length === 0) {
            updatedState = { ...updatedState, show: true };
          } else {
            updatedState = { ...updatedState, show: false, learn: clouds };
          }

          this.setState(updatedState);
          this.setState({
            isResourceLoaded: true,
          });
        }
      } else {
        SDCloudBackendAPIWithToken()
          .get(Api.getNewLearning)
          .then((res) => {
            let activeLabs = [];
            let inactiveLabs = [];
            let allLabs = [];
            const is_scripted_demo = this.offeringType === Offerings.SALES_DEMO;
            res.data.learnings.map((x) => {
              this.state.cmsdata &&
                this.state.cmsdata.map((cms) => {
                  if (x.name === cms.identifier) {
                    if (x.is_scripted_demo === is_scripted_demo) {
                      if (x.is_active) {
                        if(x.lab_status !== "suspended" && x.lab_status !== "archived"){
                          activeLabs.push(x);
                        } else if(x.lab_status === "suspended" || x.lab_status === "archived"){
                          inactiveLabs.push(x);
                        }
                      } 
                      // else {
                      //   inactiveLabs.push(x);
                      // }
                    }
                  }
                });
            });
            allLabs = inactiveLabs;
            this.setState({
              activeLabs,
              inactiveLabs,
              allLabs,
              allLabsTemp: allLabs,
              activeLabsTemp: activeLabs,
              inactiveLabsTemp: inactiveLabs,
            });
            if (activeLabs.length === 0) {
              this.setState({ show: true });
            } else {
              this.setState({ show: false, learn: res.data.learnings });
            }
            if (inactiveLabs.length === 0) {
              this.setState({ show: true });
            } else {
              this.setState({ show: false, learn: res.data.learnings });
            }
          })
          .then(() => {
            this.setState({
              isResourceLoaded: true,
            });
          })
          .catch((err) => {
            // const payload = {
            //   message: err?.response?.data?.message || "unknown error occured",
            //   color: "danger",
            //   error: err
            // };
            // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
          })
          .finally(() => {
            //
          });
      }
    }
    if (prevProps.labFilter !== this.props.labFilter) {
      this.setState({
        labFilter: this.props.labFilter,
      });
    }
  };
  
  launchLabBtnHandler = async (
    labId,
    labIdentifier,
    labEnabled,
    labName,
    isReservedIp = null,
    ip = null,
    customerName = null,
    demoName = null,
    purpose = null,
    // zone = null,
    instance_type = null,
    testValue = null,
    dealId = null
  ) => {
    let uuid = null;
    let redirectRoute =
      this.offeringType === Offerings.SALES_DEMO
        ? Urls.accessSalesDemo
        : Urls.accessLab;
    this.props.dispatch(SHOW_LOADER());
    try {
      await this.subscribeLab(labIdentifier, customerName, demoName).catch(
        (err) => {
          let stateParams = {
            labId: labId,
            labEnabled: labEnabled,
            labIdentifier: labIdentifier,
            uuid: uuid,
            labName: labName,
          };
          let additionParams = stateParams;
          additionParams["offeringType"] = Offerings.LEARNING_LABS;
          this.props.history.push({
            pathname: redirectRoute,
            state: { stateParams: additionParams },
            search: `?labId=${labId}&uuid=${uuid}&labIdentifier=${labIdentifier}&labEnabled=${labEnabled}&labName=${labName}&offeringType=${Offerings.LEARNING_LABS.title}`,
          });

          throw err?.response?.data?.message || "error in subscribing";
        }
      );
      uuid = await this.provisionLab(
        labIdentifier,
        isReservedIp,
        ip,
        purpose,
        // zone,
        instance_type,
        testValue,
        dealId
      ).catch((err) => {
        throw err?.response?.data?.message || "error in provisioning";
      });
      const stateParams = {
        labId: labId,
        labEnabled: labEnabled,
        labIdentifier: labIdentifier,
        uuid: uuid,
        labName: labName,
      };
      if (isReservedIp) {
        stateParams["reserved_ip"] = true;
      }
      if (ip) {
        stateParams["ip_address"] = ip;
      }
      if (dealId) {
        stateParams["deal_id"] = dealId;
      }
      if (uuid) {
        let additionParams = stateParams;
        // redirectRoute = `${redirectRoute}/${uuid}/${labIdentifier}`;
        additionParams[
          "addHoursDetails"
        ] = this.props.profile.profileObj.add_hours;
        additionParams["offeringType"] = Offerings.LEARNING_LABS;
        await this.props.history.push({
          pathname: redirectRoute,
          state: { stateParams: additionParams },
          search: `?labId=${stateParams.labId}&uuid=${stateParams.uuid}&labIdentifier=${stateParams.labIdentifier}&labEnabled=${stateParams.labEnabled}&labName=${stateParams.labName}&offeringType=${Offerings.LEARNING_LABS.title}`,
        });
      }
    } catch (err) {
      const payload = {
        message: err,
        color: "danger",
      };
      this.props.dispatch(SHOW_NOTIFICATION({ payload }));
    }
  };

  // subscribe to a learning lab
  subscribeLab = (labIdentifier, customerName, demoName) => {
    const data = {
      learning: labIdentifier,
      auto_renew: false,
    };
    if (customerName) {
      data["cust_name"] = customerName;
    }
    if (demoName) {
      data["name"] = demoName;
    }
    return SDCloudBackendAPIWithToken().post(Api.subscribeLearningLab, data);
  };

  getNewLearnings = async () => {
    this.props.dispatch(SHOW_LOADER());
    return SDCloudBackendAPIWithToken()
      .get(Api.getNewLearning)
      .then((res) => {
        let activeLabs = { names: [], userNames: {} };
        let inactiveLabs = { names: [], userNames: {}};
        let provisionedLab = { labData: [], userNames: {} };
        res.data.learnings.forEach((item) => {
          if (item.lab_uuid) {
            provisionedLab["labData"].push({
              labName: item.name,
              uuid: item.lab_uuid,
            });
            if (
              provisionedLab.userNames[item.name] &&
              provisionedLab.userNames[item.name] ==
                localStorage.getItem("userName")
            ) {
              // //console.log("Do nothing ..")
            } else {
              Object.assign(provisionedLab["userNames"], {
                [item.name]: item.user_name,
              });
            }
          }
          if (item.is_active === true) {
            if(item.lab_status !== "suspended" && item.lab_status!=="archived"){
              this.setState((prevState) => ({
                activeLabsTime: {
                  ...prevState.activeLabsTime,
                  [item.name]: {
                    createdDate: item.created,
                    expiryDate: item.expiry_date,
                  },
                },
              }));
              activeLabs["names"].push(item.name);
              if (
                activeLabs.userNames[item.name] &&
                activeLabs.userNames[item.name] ==
                  localStorage.getItem("userName")
              ) {
                // //console.log("Do nothing ..")
              } else {
                Object.assign(activeLabs["userNames"], {
                  [item.name]: item.user_name,
                });
              }
            } else if(item.lab_status === "suspended" || item.lab_status ==="archived"){
              this.setState((prevState) => ({
                inactiveLabsTime: {
                  ...prevState.inactiveLabsTime,
                  [item.name]: {
                    createdDate: item.created,
                    expiryDate: item.expiry_date,
                  },
                },
              }));
              inactiveLabs["names"].push(item.name);
              if (
                inactiveLabs.userNames[item.name] &&
                inactiveLabs.userNames[item.name] ==
                  localStorage.getItem("userName")
              ) {
                // //console.log("Do nothing ..")
              } else {
                Object.assign(inactiveLabs["userNames"], {
                  [item.name]: item.user_name,
                });
              }
            }
          }
        });
        return { activeLabs, provisionedLab, inactiveLabs };
      })
      .then(({ activeLabs, provisionedLab, inactiveLabs }) => {
        return (
          asyncSetState(
            {
              activeLabs,
              inactiveLabs,
              provisionedLab,
            },
            this
          )
            // .then(() => this.getAdminLabTypes())
            .catch((err) => {
              throw err;
            })
        );
      })
      .catch((err) => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // api call to find lab mappings
  // independent of sales demo or learning lab. calls a `general` api.
  getAdminLabTypes = async () => {
    this.props.dispatch(SHOW_LOADER());
    return CMSAPIWithToken()
      .get(Api.adminLabTypes)
      .then((res) => {
        const labIDToNameMap = {};
        res.data.results.forEach((item) => {
          labIDToNameMap[item.id] = {
            sequence: item.sequence,
            name: item.name,
          };
        });
        this.setState({
          labIDToNameMap,
        });
      })
      .then(() => {
        // if (this.props.profile.profileObj.privilege === "developer") {
        //   return this.getProfile();
        // } else if (this.props.profile.profileObj.privilege === "admin") {
        //   return this.getResources();
        // }
      })
      .catch((err) => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };
  //get resources for user type admin
  getResources = async (developer = null) => {
    this.props.dispatch(SHOW_LOADER());
    const isSalesDemo = this.offeringType === Offerings.SALES_DEMO.resource;
    return SDCloudBackendAPIWithToken()
      .get(Api.getResource_learnigs)
      .then((res) => {
        let getResourcesDetails = {}; // { labname : { ...details }}
        if (res.data.hasOwnProperty("learnings")) {
          // creating a mapping of lab Name to lab details.
          res.data.learnings.forEach((item) => {
            // is_scripted_demo = false implies not a sales demo.
            if (item.is_scripted_demo === isSalesDemo) {
              getResourcesDetails[item.name] = { ...item };
              delete getResourcesDetails.name; // delete name field to align with getProfile data.
            }
          });
        }
        return getResourcesDetails;
      })
      .then((getResourcesDetails) => {
        this.setState({
          resourceDetails: getResourcesDetails,
        });
      })
      .catch((err) => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  getResourcesStatusAPI = async () => {
    const res = await getResourcesStatus();

    if (!res.success) {
      return;
    }
    const resources = res.data;
    this.state.sections = this.state.sections.map((item) => ({
      available:
        (resources[item.id] &&
          resources[item.id].allocated_hours &&
          resources[item.id].allocated_hours -
            resources[item.id].consumed_hours) ||
        0,
      isLaunch: !!resources[item.id],
      ...item,
    }));
    this.setState({
      resources: this.state.sections,
    });
    this.getServicesStatusAPI();
  };

  getServicesStatusAPI = async () => {
    const res = await getServiceStatus();
    if (!res.success) {
      return;
    }
    this.setState({
      services: res.data,
    });
  };

  // gets lab name and tries related data from backend db for user type non admin.
  getProfile = () => {
    this.props.dispatch(SHOW_LOADER());
    const isSalesDemo = this.offeringType === Offerings.SALES_DEMO.resource;
    return SDCloudBackendAPIWithToken()
      .get(Api.getProfile)
      .then((res) => {
        let getProfileDetails = {}; // { labname : { ...labDetails }}
        res.data.profiles[0].permissions.forEach((item) => {
          // is_scripted_demo = false implies not a sales demo.
          if (item.is_scripted_demo === isSalesDemo) {
            getProfileDetails[item.resource_name] = { ...item };
          }
        });
        return getProfileDetails;
      })
      .then((getProfileDetails) => {
        this.setState({
          profileDetails: getProfileDetails,
        });
        // return this.getCmsData(getProfileDetails);
      })
      .catch((err) => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  getInventory = () => {
    let learningsInventoryData = {};
    SDCloudBackendAPIWithToken()
      .get(`/inventory/view_inventories/`)
      .then((resp) => resp.data.inventories)
      .then((resp) => {
        resp.forEach((item) => {
          if (item.inventory_type == "Learning") {
            learningsInventoryData[item.sku_name] = item.tags;
          }
          this.setState({
            learningsInventoryData: learningsInventoryData,
          });
        });
      });
  };

  // gets lab related data from cms db.
  // merging of cms data and backend get_profile data. data from get_profile will overwrite cms data.
  getCmsData = async (getProfileDetails = null, getResourcesDetails = null) => {
    let getResourceLabs = {};
    if (getProfileDetails && getResourcesDetails) {
      let getProfileLabs = Object.keys(getProfileDetails);
      let resourceKeys = Object.keys(getResourcesDetails);
      resourceKeys.forEach((item, index) => {
        if (
          !getProfileLabs.includes(getResourcesDetails[item].name) &&
          !getProfileLabs.includes(getResourcesDetails[item].resource_name)
        ) {
          getResourceLabs[item] = getResourcesDetails[item];
          getResourceLabs[item]["access"] = "no";
        }
        // getResourceLabs[item] = getResourcesDetails[item];
      });
    } else {
      getResourceLabs = getResourcesDetails;
    }
    getProfileDetails = { ...getProfileDetails, ...getResourceLabs };
    const getCmsDataUrl =
      this.offeringType === Offerings.LEARNING_LABS.resource
        ? Api.viewLearningLab(1000)
        : Api.adminSalesDemo;
    return CMSAPIWithToken()
      .get(getCmsDataUrl)
      .then((res) => {
        this.setState({ cmsdata: res.data.results });
        let cmsData = res.data.results;
        // creating a mapping of labName to lab details for cms data.
        let cmsLabtoDetailsMap = [];
        cmsData.forEach((item) => {
          cmsLabtoDetailsMap[item.identifier] = { ...item };
        });
        // Array containing combined data from getProfile/resources and cms data.
        let cmsAndProfileCombinedData = [];
        // Array of lab names derived from getProfile / resoures.
        let labNameKeysArrGetProfile = Object.keys(getProfileDetails);

        // combining data from cms and profile/resources
        for (let i = 0; i < labNameKeysArrGetProfile.length; i++) {
          if (cmsLabtoDetailsMap[labNameKeysArrGetProfile[i]]) {
            cmsAndProfileCombinedData.push({
              // data from cms { labName : { ...labDetails }}
              ...cmsLabtoDetailsMap[labNameKeysArrGetProfile[i]],
              // data from getProfile/resources { labName : { ...labDetails }}
              ...getProfileDetails[labNameKeysArrGetProfile[i]],
              // if a lab is active from getNewLearnings then add it to combined data.
              isLabActive: this.state.activeLabs.names.includes(
                labNameKeysArrGetProfile[i]) || this.state.inactiveLabs.names.includes(
                  labNameKeysArrGetProfile[i]
              ),
              userName: this.state.provisionedLab.userNames[
                labNameKeysArrGetProfile[i]
              ]
                ? this.state.provisionedLab.userNames[
                    labNameKeysArrGetProfile[i]
                  ]
                : this.state.activeLabs.userNames[labNameKeysArrGetProfile[i]],
              sequence: this.state.labIDToNameMap[
                cmsLabtoDetailsMap[labNameKeysArrGetProfile[i]]
                  .admin_lab_types_id
              ].sequence,
              activeLabTime: this.state.activeLabsTime[
                labNameKeysArrGetProfile[i]
              ],
              inactiveLabTime: this.state.inactiveLabsTime[
                labNameKeysArrGetProfile[i]
              ],
              isLabProvisioned: this.state.provisionedLab.labData
                .flatMap((x) => x.labName)
                .includes(labNameKeysArrGetProfile[i]),
              uuid: this.state.provisionedLab.labData.find(
                (x) => x.labName === labNameKeysArrGetProfile[i]
              )
                ? this.state.provisionedLab.labData.find(
                    (x) => x.labName === labNameKeysArrGetProfile[i]
                  ).uuid
                : null,
            });
          }
        }
        // segregating data based on lab type
        const labTypetoLabMap = {}; // { primer : {...} , advance : { ... }}
        let labIDToNameMap = this.state.labIDToNameMap; // { 2 : "advance" , 3 : "primer" }
        const launchAccessList = []; // [ { {id: 2, short_title: "AAR" , type : "Learning" }]
        const noAccessList = [];
        const labProvisionedList = [];
        const subscribedList = [];
        cmsAndProfileCombinedData.forEach((item) => {
          let tries = Math.floor(
            (item.allocated_hours - item.consumed_hours) / item.labhours
          );
          let availableTries = tries < 1 ? 0 : tries;

          if (item.isLabActive && item.isLabProvisioned) {
            labProvisionedList.push(item);
          } else if (availableTries < 1) {
            noAccessList.push(item);
          } else {
            launchAccessList.push(item);
          }
          if (labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name]) {
            labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name].push(
              item
            );
          } else {
            labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name] = [
              item,
            ]; 
          }
          let isLabDisabled = false;
          if (item.permission) {
            isLabDisabled = item.permission === "delete";
          }
          if (item.access != "no" && availableTries > 0 && !isLabDisabled) {
            subscribedList.push(item);
          }
        });

        const allLabTypetoLabMap = labProvisionedList
          .concat(launchAccessList)
          .concat(noAccessList);
        if (cmsAndProfileCombinedData.length > 0) {
          this.setState(
            {
              // adminLabArray: labTypetoLabMap[defaultLabType],
              // currentLabType: defaultLabType,
              allLabTemplates: allLabTypetoLabMap,
              adminLabArray: allLabTypetoLabMap,
              currentLabType: templateFilters[0],
              labTypetoLabMap: labTypetoLabMap,
              subscribedList,
              subscribedListTemp: subscribedList,
              searchfilter: allLabTypetoLabMap,
            },
            () => {
              return;
            }
          );
        } else {
          this.setState({
            adminLabArray: [],
            currentLabType: templateFilters[0],
            allLabTemplates: [],
            labTypetoLabMap: labTypetoLabMap,
          });
        }
      })
      .then(() => {
        return this.getReservedIp();
      })
      .catch((err) => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // ping cms for getting lab id using lab identifier for access learning lab.
  getLabIdfromLabIdentifier = (labIdentifier) => {
    let labCmsUrl =
      this.offeringType.resource === Offerings.SALES_DEMO.resource
        ? Api.adminSalesDemo(1000)
        : Api.viewLearningLab(1000);
    return CMSAPIWithToken()
      .get(labCmsUrl)
      .then((res) => {
        const matchedLabFromCms = res.data.results.filter((item) => {
          return item.identifier == labIdentifier;
        });
        return matchedLabFromCms[0];
      })
      .catch((err) => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // provision a learning lab
  provisionLab = (
    labIdentifier,
    isReservedIp,
    ip,
    purpose,
    testValue,
    dealId,
    // zone,
    instance_type,
  ) => {
    const data = {
      learning: labIdentifier,
    };
    if (isReservedIp) {
      data["reserved_ip"] = isReservedIp;
    }
    if (ip) {
      data["ip_address"] = ip;
    }
    if (purpose) {
      data["purpose"] = purpose;
    }
    
    if (testValue) {
      data["mode"] = testValue;
    }
    if (dealId) {
      data["deal_id"] = dealId;
    }
    // if (zone) {
    //   data["zone"] = zone;
    // }
    if (instance_type) {
      data["instance_type"] = instance_type;
    }
    return SDCloudBackendAPIWithToken()
      .post(Api.provisionLearningLab, data)
      .then((res) => {
        return res.data.uuid;
      });
  };

  handleHeaderClick = (selectedLabType, type) => {
    this.setState(
      {
        currentSelectedItem: type,
        currentLabSubItem:
          selectedLabType === "ActiveLab" || selectedLabType === "Subscribed"
            ? "All"
            : selectedLabType,
        activeLabSubItem:
          selectedLabType === "ActiveLab" ? "Active" : selectedLabType === "InactiveLab" ? "Inactive" : selectedLabType,
      },
      () => {
        if (type == "labItem") {
          let index;
          if (selectedLabType == "All") {
            this.state.searchText = "";
            this.setState({});
            index = 0;
          } else if (selectedLabType == "Subscribed") {
            this.state.searchText = "";
            this.setState({});
            index = 1;
          } else if (selectedLabType == "ActiveLab") {
            this.state.searchText = "";
            this.setState({});
            index = 2;
          } else if (selectedLabType == "InactiveLab") {
            this.state.searchText = "";
            this.setState({});
            index = 3;
          } else if (selectedLabType == "ScheduleLab") {
            this.state.searchText = "";
            this.setState({});
            index = 4;
          }

          this.setState({
            currentTabIndex: index,
          });
        }
      }
    );
    switch (selectedLabType) {
      case "All":
        this.setState({
          adminLabArray: this.state.allLabTemplates,
          currentLabType: selectedLabType,
        });
        break;
      case "Subscribed":
        this.setState({
          adminLabArray: this.state.subscribedList,
          currentLabType: selectedLabType,
        });
        break;
      case "ScheduleLab":
        this.setState({
          // adminLabArray: this.state.subscribedList,
          currentLabType: selectedLabType,
        });
        break;
      case "ActiveLab":
        this.setState({
          adminLabArray: this.state.activeLabs,
          currentLabType: selectedLabType,
        });
        break;
      case "InactiveLab":
        this.setState({
          adminLabArray: this.state.allLabs,
          currentLabType: selectedLabType,
        });
        break;
      default:
        this.setState({
          adminLabArray: this.state.labTypetoLabMap[selectedLabType],
          currentLabSubItem: selectedLabType,
        });
      }
  };

  handleTabItemClick = (selectedLabType) => {
    switch (selectedLabType) {
      case "All":
        this.setState({
          adminLabArray: this.state.allLabs,
          activeLabSubItem: selectedLabType,
        });
        break;
      case "Active":
        this.setState({
          adminLabArray: this.state.activeLabs,
          activeLabSubItem: selectedLabType,
        });
        break;
      case "Inactive":
        this.setState({
          adminLabArray: this.state.inactiveLabs,
          activeLabSubItem: selectedLabType,
        });
        break;
      default:
        this.setState({
          adminLabArray: [],
          activeLabSubItem: selectedLabType,
        });
    }
  };

  // get reserved IP settings for user, account and offering specific
  getReservedIp = async () => {
    const isSalesDemo = this.offeringType === Offerings.SALES_DEMO.resource;
    return SDCloudBackendAPIWithToken()
      .get(Api.getUserProfile)
      .then((res) => {
        // Check launch lab permission for user
        if (res.data?.can_launch === false) {
          this.setState({
            canLaunch: false,
          });
        }
        let reservedIpObj = {};
        if (res.data.static_ips?.length) {
          reservedIpObj["reservedIps"] = [...res.data.static_ips];
          reservedIpObj["ipService"] = true;
        }
        return reservedIpObj;
      })
      .then((reservedIpObj) => {
        let accountName = JSON.parse(localStorage.getItem("profileData"))?.account;
        return SDCloudBackendAPIWithToken()
          .get(Api.getAccountDetails,{
            params: {
                account: accountName
            }})
          .then((res) => {
            if (res.data.reserved_ip) {
              reservedIpObj["staticIp"] = res.data.reserved_ip;
            }
            if (
              isSalesDemo &&
              res.data.ip_available_services?.includes("sales_demo")
            ) {
              reservedIpObj["ipService"] = true;
            } else if (res.data.ip_available_services?.includes("learnings")) {
              reservedIpObj["ipService"] = true;
            } else {
              reservedIpObj["ipService"] = false;
            }
            return reservedIpObj;
          });
      })
      .then((reservedIpObj) => {
        if (
          reservedIpObj.ipService &&
          (reservedIpObj.reservedIps || reservedIpObj.staticIp)
        ) {
          reservedIpObj["dynamicIp"] = "default";
          this.setState(
            {
              reservedIp: reservedIpObj,
              reservedIpLoaded: true,
            },
            () => {
              return;
            }
          );
        } else {
          this.setState({
            reservedIpLoaded: true,
          });
        }
      })
      .catch((err) => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };
  handleAccessLabBtn = (
    lab_uuid,
    lab_identifier,
    createdDate,
    expiryDate,
    history,
    getLabIdfromLabIdentifier,
    offeringType,
    addHoursDetails,
    profileObj = null,
    clusterUUID,
    template_name
  ) => {
    let redirectRoute = null;

    if (offeringType.resource === Offerings.SALES_DEMO.resource) {
      redirectRoute = Urls.accessSalesDemo;
    } else if (offeringType === Offerings.LEARNING_LABS.resource) {
      redirectRoute = Urls.accessLab;
    } else if (offeringType === Offerings.POC_LIBRARY.resource) {
      this.props.history.push(
        `/access-sandbox/${clusterUUID}/${template_name}`
      );
      return true;
    }
    getLabIdfromLabIdentifier(lab_identifier).then(({ id, full_title }) => {
      const stateParams = {
        labId: id,
        labName: full_title,
        labEnabled: false,
        labIdentifier: lab_identifier,
        uuid: lab_uuid,
        createdDate: createdDate,
        expiryDate: expiryDate,
        addHoursDetails: addHoursDetails,
        offeringType: offeringType,
      };
      // push access learning labs
      if (lab_uuid !== null) {
        stateParams.labEnabled = true;
      } else {
        stateParams.labEnabled = false;
      }
      let additionParams = stateParams;
      additionParams["addHoursDetails"] = profileObj;
      additionParams["offeringType"] = offeringType;
      history.push({
        pathname: redirectRoute,
        state: { stateParams: stateParams },
        search: `?labId=${stateParams.labId}&uuid=${stateParams.uuid}&labIdentifier=${stateParams.labIdentifier}&labEnabled=${stateParams.labEnabled}&labName=${stateParams.labName}&expiryDate=${stateParams.expiryDate}&createdDate=${stateParams.createdDate}&offeringType=${stateParams.offeringType}`,
      });
    });
  };
  renderInactiveCards(){
    return (
      <>
          {this.state.inactiveLabs &&
          this.state.inactiveLabs.length > 0 &&
          this.state.inactiveLabs.map((item, key) => {
            let labTimeLeft = msToStr(
              moment(new Date()).format("YYYY-MM-DD HH:mm"),
              item.expiry_date
            );
            let endTime = moment(item.expiry_date);
            let startTime = moment(
              moment(new Date()).format("YYYY-MM-DD HH:mm")
            );
            let differenceInMs = endTime.diff(startTime);
            let dif = moment.duration(differenceInMs);
            let hours = dif.hours();
            let minutes = dif.minutes();
            let status =
              item.lab_status == "Not provisioned" ||
              (item.lab_status !== "terminated" &&
                item.lab_status !== "suspended" && item.lab_status !== "archived")
                ? "Active"
                : "Inactive";
            const imageUrl = item.thumbnail ? item.thumbnail : "";
            if (minutes > 0 && hours >= 0) {
              return (
                <div className="sortable-moves">
                  {imageUrl.trim() !== "" ? (
                    <img
                      className="img-fluid p-absolute image-dim"
                      src={imageUrl}
                      alt=""
                    ></img>
                  ) : (
                    <div className="imgdefault">
                      <SvgComponent
                        className={this.props.classes.absoluteImg}
                        type="default"
                        svgSource={svg12}
                      />
                    </div>
                  )}
                  <div
                  style={{marginTop:"10px",display: "flex",color:"black",fontFamily:"Roboto",fontStyle:"normal",fontSize:"16px",fontWeight:"bold"}}>
                    {item.display_name.toUpperCase()}
                  </div>
                  <p className="card-content"></p>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={9}>
                      <p
                        className={`card-content position float-left pt-4 ${this.props.classes.font}`}
                        style={{ paddingBottom: "10px", width: "76%" }}
                      >
                        Time Left : <span>{labTimeLeft}</span>{" "}
                        <span className="sepbar">|</span> Status :{" "}
                        <span>{status}</span> <br /> Lab Status :{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {item.lab_status}
                        </span>
                        <span className="sepbar">|</span>Launched By :{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {item.user_name}
                        </span>
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      justifyContent="flex-end"
                      className="right-button"
                    >
                      <div className="card-content float-right position pt-4">
                        <Button
                          className={this.props.classes.font}
                          onClick={() =>
                            this.handleAccessLabBtn(
                              item.lab_uuid,
                              item.name,
                              item.created,
                              item.expiry_date,
                              this.props.history,
                              this.getLabIdfromLabIdentifier,
                              this.offeringType,
                              this.state.addHoursDetails
                            )
                          }
                          style={selectedlabTypeStyle}
                        >
                         Access Learning Lab
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              );
            } else {
              this.state.inactiveLabs = [];
            }
          })}
      </>
    )
  }
  renderSideLabMenu() {
    const { classes } = this.props;
    const labType = new Set();
    let orderedLabTypes = [];
    if (
      Object.keys(this.state.labTypetoLabMap).length > 0 &&
      this.state.reservedIpLoaded
    ) {
      Object.entries(this.state.labTypetoLabMap).map(([key, value]) => {
        orderedLabTypes.push({ title: key, sequence: value[0].sequence });
      });

      orderedLabTypes.sort((a, b) => {
        if (a.sequence > b.sequence) return 1;
        if (a.sequence < b.sequence) return -1;
        return 0;
      });
      orderedLabTypes.forEach((item) => {
        labType.add(item.title);
      });

      return (
        <div className="search-container-right" style={{width:"70%"}}>
          <a
            href="#"
            onClick={() => this.handleHeaderClick("All", "subitem")}
            className={classes.font}
            style={
              this.state.currentLabSubItem.toLowerCase() ===
              templateFilters[0].toLowerCase()
                ? selectedlabTypeStyle
                : {}
            }
          >
            All
          </a>
          {Array.from(labType).map((item) => {
            return (
              <a
                href="#"
                onClick={() => this.handleHeaderClick(item, "subitem")}
                className={classes.font}
                style={
                  this.state.currentLabSubItem.toLowerCase() ===
                  item.toLowerCase()
                    ? selectedlabTypeStyle
                    : {}
                }
              >
                {item}
              </a>
            );
          })}
        </div>
      );
    }
  }

  // renderActiveTabMenu() {
  //   let activeTabFilter = ["All", "Active", "Inactive"];
  //   const { classes } = this.props;
  //   return (
  //     <div className="search-container-right">
  //       {activeTabFilter.map((item) => {
  //         return (
  //           <a
  //             href="#"
  //             onClick={() => this.handleTabItemClick(item)}
  //             className={classes.font}
  //             style={
  //               this.state.activeLabSubItem.toLowerCase() === item.toLowerCase()
  //                 ? selectedlabTypeStyle
  //                 : {}
  //             }
  //           >
  //             {item}
  //           </a>
  //         );
  //       })}
  //     </div>
  //   );
  // }

  onRowSelect = (selectedRows) => {
    this.setState({
      selectedRows,
    });
  };

  handleChange = (e, type) => {
    const { value } = e.target;
    this.state.searchText = value;
    let category = Object.keys(this.state.labTypetoLabMap);
    let titles = category.filter(
      (title) => title.toLowerCase() === value.toLowerCase()
    );
    let admin =
      JSON.parse(localStorage.getItem("profileData")).privilege === "admin";
    this.setState({
      searchText: value,
    });

    if (this.state.currentLabType === "All") {
      const searchList = titles?.length
        ? this.state.labTypetoLabMap[titles[0]]
        : this.state.searchfilter &&
          this.state.searchfilter.filter((obj) =>
            ["display_name", "name", "short_title"].some((key) => {
              return (
                obj[key] && obj[key].toLowerCase().includes(value.toLowerCase())
              );
            })
          );
      this.setState({
        adminLabArray: value !== "" ? searchList : this.state.searchfilter,
      });
    } else if (this.state.currentLabType === "Subscribed") {
      const searchList =
        this.state.subscribedListTemp &&
        this.state.subscribedListTemp.filter((obj) =>
          ["display_name", "name", "short_title"].some((key) => {
            return (
              obj[key] && obj[key].toLowerCase().includes(value.toLowerCase())
            );
          })
        );
      this.setState({
        adminLabArray:
          value !== "" ? searchList : this.state.subscribedListTemp,
      });
    } else if (this.state.currentLabType === "ActiveLab") {
      if (this.state.activeLabSubItem === "All") {
        const searchList =
          this.state.allLabsTemp &&
          this.state.allLabsTemp.filter((obj) =>
            admin
              ? ["display_name", "name", "user_name"].some((key) => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
              : ["display_name", "name"].some((key) => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
          );
        this.setState(
          {
            allLabs: value !== "" ? searchList : this.state.allLabsTemp,
          },
          () => {
            // //console.log("alllabsss", this.state.allLabs)
          }
        );
      }
      if (this.state.activeLabSubItem === "Active") {
        const searchList =
          this.state.activeLabsTemp &&
          this.state.activeLabsTemp.filter((obj) =>
            admin
              ? ["display_name", "name", "user_name"].some((key) => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
              : ["display_name", "name"].some((key) => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
          );
        this.setState(
          {
            activeLabs: value !== "" ? searchList : this.state.activeLabsTemp,
          },
          () => {
            // //console.log("alllabsss", this.state.allLabs)
          }
        );
      }
      // if (this.state.activeLabSubItem === "Inactive") {
      //   const searchList =
      //     this.state.inactiveLabsTemp &&
      //     this.state.inactiveLabsTemp.filter((obj) =>
      //       admin
      //         ? ["display_name", "name", "user_name"].some((key) => {
      //             return (
      //               obj[key] &&
      //               obj[key].toLowerCase().includes(value.toLowerCase())
      //             );
      //           })
      //         : ["display_name", "name"].some((key) => {
      //             return (
      //               obj[key] &&
      //               obj[key].toLowerCase().includes(value.toLowerCase())
      //             );
      //           })
      //     );
      //   this.setState(
      //     {
      //       inactiveLabs:
      //         value !== "" ? searchList : this.state.inactiveLabsTemp,
      //     },
      //     () => {
      //       // //console.log("alllabsss", this.state.allLabs)
      //     }
      //   );
      // }
    } else if (this.state.currentLabType === "InactiveLab") {
      if (this.state.activeLabSubItem === "Inactive") {
        const searchList1 =
          this.state.inactiveLabsTemp &&
          this.state.inactiveLabsTemp.filter((obj) =>
            admin
              ? ["display_name", "name", "user_name"].some((key) => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
              : ["display_name", "name"].some((key) => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
          );
        this.setState(
          {
            inactiveLabs: value !== "" ? searchList1 : this.state.inactiveLabsTemp,
          },
          () => {
            // //console.log("alllabsss", this.state.allLabs)
          }
        );
      }
    }
  };

  handleChanges = (event, newValue) => {
    this.setState({ value: newValue });
  };

  accessLabBtnHandler = (stateParams) => {
    let redirectRoute =
      this.offeringType === Offerings.SALES_DEMO.resource
        ? Urls.accessSalesDemo
        : Urls.accessLab;

    let additionParams = stateParams;
    // redirectRoute = `${redirectRoute}/${stateParams.uuid}/${stateParams.labIdentifier}`;
    additionParams["addHoursDetails"] = this.props.profile.profileObj.add_hours;
    additionParams["offeringType"] = Offerings.LEARNING_LABS;
    this.props.history.push({
      pathname: redirectRoute,
      state: { stateParams: additionParams },
      search: `?labId=${stateParams.labId}&uuid=${stateParams.uuid}&labIdentifier=${stateParams.labIdentifier}&labEnabled=${stateParams.labEnabled}&labName=${stateParams.labName}&expiryDate=${stateParams.expiryDate}&createdDate=${stateParams.createdDate}&offeringType=${stateParams.offeringType.title}`,
    });
  };

  render() {
    
    const { resources } = this.state;
    const { classes } = this.props;
    let currentStatus = resources.find((o) => o.id == "learning");
    if (currentStatus && !currentStatus.isLaunch) {
      
      return <LearningCenterUpgrade />;
    } else {
      if (resources.length <= 0 || !this.state.activeLabs || !this.state.inactiveLabs) {
        return null;
      } else {
        return (
          <div className={""}>
            {this.state.app_bar === true ? (
              <>
                {JSON.parse(localStorage.getItem("profileData")).privilege ===
                "admin" ? (
                  <AppBar position="static">
                    <Tabs
                      value={this.state.value}
                      classes={{
                        root: classes.customTabRoot,
                      }}
                      onChange={this.handleChanges}
                      aria-label="simple tabs example"
                    >
                      <Tab
                        label="Catalog"
                        {...a11yProps(0)}
                        onClick={() => this.handleHeaderClick("All", "labItem")}
                      />
                      <Tab
                        label="Active"
                        {...a11yProps(1)}
                        onClick={() =>
                          this.handleHeaderClick("ActiveLab", "labItem")
                        }
                      />
                      <Tab
                        label="Inactive"
                        {...a11yProps(2)}
                        onClick={() =>
                          this.handleHeaderClick("InactiveLab", "labItem")
                        }
                      />
                      <Tab
                        label="Scheduled"
                        {...a11yProps(3)}
                        onClick={() =>
                          this.handleHeaderClick("ScheduleLab", "labItem")
                        }
                      />
                    </Tabs>
                  </AppBar>
                ) : (
                  <AppBar position="static">
                    <Tabs
                      value={this.state.value}
                      classes={{
                        root: classes.customTabRoot,
                      }}
                      onChange={this.handleChanges}
                      aria-label="simple tabs example"
                    >
                      <Tab
                        label="Catalog"
                        {...a11yProps(0)}
                        onClick={() => this.handleHeaderClick("All", "labItem")}
                      />
                      <Tab
                        label="Subscribed"
                        {...a11yProps(1)}
                        onClick={() =>
                          this.handleHeaderClick("Subscribed", "labItem")
                        }
                      />
                      <Tab
                        label="Active"
                        {...a11yProps(2)}
                        onClick={() =>
                          this.handleHeaderClick("ActiveLab", "labItem")
                        }
                      />
                      <Tab
                        label="Inactive"
                        {...a11yProps(3)}
                        onClick={() =>
                          this.handleHeaderClick("InactiveLab", "labItem")
                        }
                      />
                      <Tab
                        label="Scheduled"
                        {...a11yProps(4)}
                        onClick={() =>
                          this.handleHeaderClick("ScheduleLab", "labItem")
                        }
                      />
                    </Tabs>
                  </AppBar>
                )}
                <div className="topnav">
                  <div
                    className={
                      this.state.currentLabType == "ScheduleLab"
                        ? "full-search search-container"
                        : "search-container"
                    }
                  >
                    <form action="">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.4163 16.7497L14.4163 12.7497C15.333 11.4997 15.833 9.99967 15.833 8.33301C15.833 4.16634 12.4997 0.833008 8.33301 0.833008C4.16634 0.833008 0.833008 4.16634 0.833008 8.33301C0.833008 12.4997 4.16634 15.833 8.33301 15.833C9.99967 15.833 11.4997 15.333 12.7497 14.4163L16.7497 18.4163C17.2497 18.9163 17.9163 18.9163 18.4163 18.4163C18.833 17.9163 18.833 17.1663 18.4163 16.7497ZM8.33301 13.7497C5.33301 13.7497 2.91634 11.333 2.91634 8.33301C2.91634 5.33301 5.33301 2.91634 8.33301 2.91634C11.333 2.91634 13.7497 5.33301 13.7497 8.33301C13.7497 11.333 11.333 13.7497 8.33301 13.7497Z"
                          fill="#545454"
                        />
                      </svg>
                      <input
                        type="text"
                        className={classes.font}
                        placeholder=""
                        name="search"
                        value={this.state.searchText}
                        onChange={(e) => this.handleChange(e, "All")}
                      ></input>
                    </form>
                  </div>
                  {(this.state.currentLabType == "All" ||
                    this.state.currentLabType == "Subscribed") &&
                    this.renderSideLabMenu()}
                  {/* {this.state.currentLabType == "ActiveLab" &&
                  this.renderActiveTabMenu()} */}
                </div>
                <TabPanel
                  value={this.state.value}
                  index={
                    JSON.parse(localStorage.getItem("profileData"))
                      .privilege === "admin"
                      ? 0
                      : 0
                  }
                >
                  {this.state.adminLabArray &&
                  this.state.adminLabArray.length === 0 ? (
                    <h4 className={classes.font} style={{ fontWeight: 400 }}>
                      {" "}
                      No matches found.{" "}
                    </h4>
                  ) : (
                    <>
                      {this.state.adminLabArray &&
                        this.state.adminLabArray.map((lab) => {
                          const labProps = { ...lab };
                          return (
                            <LaunchNewOfferingCard
                              labData={{
                                ...labProps,
                                launchLabBtnHandler: this.launchLabBtnHandler,
                                accessLabBtnHandler: this.accessLabBtnHandler,
                                reservedIp: this.state.reservedIp,
                                canLaunch: this.state.canLaunch,
                                offeringType:
                                  this.props.offeringType ||
                                  Offerings.LEARNING_LABS,
                              }}
                              inventoryData={this.state.learningsInventoryData}
                              notes={notes.reservedIpNote}
                              key={uuidv4()}
                              id={lab.identifier}
                            />
                          );
                        })}
                    </>
                  )}
                </TabPanel>
                <TabPanel
                  value={this.state.value}
                  index={
                    JSON.parse(localStorage.getItem("profileData"))
                      .privilege === "admin"
                      ? ""
                      : 1
                  }
                >
                  {this.state.adminLabArray &&
                  this.state.adminLabArray.length === 0 && this.state.adminLabArray.length < 1 ? (
                    <h4 className={classes.font} style={{ fontWeight: 400 }}>
                      {" "}
                      No active Labs.{" "}
                    </h4>
                  ) : (
                    <>
                      {this.state.adminLabArray &&
                        this.state.adminLabArray.map((lab) => {
                          const labProps = { ...lab };
                          return (
                            <LaunchNewOfferingCard
                              labData={{
                                ...labProps,
                                launchLabBtnHandler: this.launchLabBtnHandler,
                                accessLabBtnHandler: this.accessLabBtnHandler,
                                reservedIp: this.state.reservedIp,
                                canLaunch: this.state.canLaunch,
                                offeringType:
                                  this.props.offeringType ||
                                  Offerings.LEARNING_LABS,
                              }}
                              inventoryData={this.state.learningsInventoryData}
                              notes={notes.reservedIpNote}
                              key={uuidv4()}
                              id={lab.identifier}
                            />
                          );
                        })}
                    </>
                  )}
                </TabPanel>
                <TabPanel
                  value={this.state.value}
                  index={
                    JSON.parse(localStorage.getItem("profileData"))
                      .privilege === "admin"
                      ? 1
                      : 2
                  }
                >
                  {/* { this.state.isProfileLoaded && this.state.isResourceLoaded && */}
                  {this.state.adminLabArray && this.state.adminLabArray.length === 0 ? (
                    <h4 className={classes.font} style={{ fontWeight: 400 }}>
                      No active Labs.
                    </h4>
                  ) : this.state.activeLabs && this.state.activeLabs.length === 0 ? (
                    <h4 className={classes.font} style={{ fontWeight: 400 }}>
                      No active Labs found.
                    </h4>
                  ) : (
                  <GridContainer>
                    <GridItem xs={12}>
                      <LearningCards
                        labFilter={this.state.activeLabSubItem}
                        offeringType={this.offeringType}
                        privilege={this.props.profile.profileObj.privilege}
                        labs={{
                          activeLabs: this.state.activeLabs,
                          // allLabs: this.state.allLabs,
                        }}
                        addHoursDetails={
                          this.props.profile.profileObj.add_hours
                        }
                        getLabIdfromLabIdentifier={
                          this.getLabIdfromLabIdentifier
                        }
                        onRowSelect={this.onRowSelect}
                        handleActive={this.props.handleActive}
                        buttonTitle={this.props.buttonTitle}
                        handleLaunchNewLab={this.props.handleLaunchNewLab}
                      />
                    </GridItem>
                  </GridContainer>
                  )}
                </TabPanel>
                <TabPanel
                  value={this.state.value}
                  index={
                    JSON.parse(localStorage.getItem("profileData"))
                      .privilege === "admin"
                      ? 2
                      : 3
                  }
                >
                  {/* { this.state.isProfileLoaded && this.state.isResourceLoaded && */}
                  {this.state.adminLabArray && this.state.adminLabArray.length === 0 ? (
                    <h4 className={classes.font} style={{ fontWeight: 400 }}>
                      No inactive Labs.
                    </h4>
                  ) : this.state.inactiveLabs && this.state.inactiveLabs.length === 0 ? (
                    <h4 className={classes.font} style={{ fontWeight: 400 }}>
                      No inactive Labs found.
                    </h4>
                  ) : (
                    this.renderInactiveCards()
                  )}
                </TabPanel>
                <TabPanel
                  value={this.state.value}
                  index={
                    JSON.parse(localStorage.getItem("profileData"))
                      .privilege === "admin"
                      ? 3
                      : 4
                  }
                >
                  <GridContainer>
                    <GridItem xs={12}>
                      <ScheduleCluster
                        value="No scheduled labs."
                        offeringType={this.offeringType}
                        ScheduleSearchInput={this.state.searchText}
                        noofhours={this.state?.adminLabArray}
                      />
                    </GridItem>
                  </GridContainer>
                </TabPanel>
              </>
            ) : (
              <Loader
                type={LOADER_TYPE}
                color={globalTheme.palette.secondary.main}
                width={50}
                height={50}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              />
            )}
            <Chatbot/>
          </div>
        );
      }
    }
  }
}
const mapStateToProps = (state) => {
  return {
    profile: state.profileObj,
  };
};
LearningLab.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default connect(mapStateToProps)(withStyles(styles)(LearningLab));
