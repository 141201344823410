import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Base64 } from "js-base64";

// Formik
import { useFormi, useFormik } from "formik";

// Material UI Components
import { Alert, AlertTitle } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Link from '@material-ui/core/Link';
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import {
  getDesignerObjects
} from "../../../apis/APIServices";

// Local
import ProfileTable from "./ProfileTable";
import LoaderHoc from "components/HOC/LoaderHoc";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const inputProps = {
  disableAnimation: true,
  shrink: false,
};

const formControlProps = {
  root: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: "0 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

const CreateProfile = () => {
  const classes = useStyles();
  // States
  const [menuItems, setMenuItems] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [learningsData, setLearningsData] = useState([]);
  const [showcasesData, setShowcasesData] = useState([]);
  const [sandboxesData, setSandboxesData] = useState([]);
  const [designerData, setDesignerData] = useState([]);
  const [designer, setDesigner] = useState([]);
  const [designerElements, setDesignerElements] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const columns = [
    {
      Header: "Template Name",
      accessor: "template_name",
    },
    {
      Header: "Name",
      accessor: "display_name",
    },
    // {
    //   Header: "Can View",
    //   accessor: "can_view",
    // },
    {
      Header: "Permission",
      accessor: "permission",
    },
    // {
    //   Header: "Hours Available",
    //   accessor: "hours_available",
    // },
    {
      Header: "Lab Hours",
      accessor: "lab_hours",
    },
    {
      Header: "Allocate Hours",
      accessor: "allocate_hours",
    },
    {
      Header: "Max Templates",
      accessor: "max_allowed_templates"
    }

  ];

  const designerColumns = [
    {
      Header: "Template Name",
      accessor: "template_name",
    },
    {
      Header: "Name",
      accessor: "display_name",
    },
    {
      Header: "Permission",
      accessor: "permission",
    },
  ];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    
    var design_objects = JSON.parse(localStorage.getItem("design_objects"));
    var xml_objects = JSON.parse(Base64.decode(design_objects.xml))
    var name_icon_obj_map = {};
    var parser = new DOMParser();
    for (let key in xml_objects) {
      if (xml_objects.hasOwnProperty(key)) {
          var value = xml_objects[key];
          if(value['title'] == "Deprecated Devices" || value['title'].includes("Shapes")){
            continue;
          }else{
            var shape_obj_list = []
            let stencilXMl = value['stencilXML']
            let xmlString = Base64.decode(stencilXMl)
            var parser = new DOMParser();
            var xmlDoc = parser.parseFromString(xmlString,"text/xml");
            let innxml = xmlDoc.getElementsByTagName('shape');
            for (var i = 0; i < innxml.length; i++) {
                var shape_obj_map = {}
                var shapexml = innxml[i];
                var shapename = shapexml.getAttribute('name')
                var childnodes = shapexml.childNodes
                for (var j = 0; j < childnodes.length; j++){
                  if(childnodes[j].nodeName == 'background'){
                    for(var k = 0; k < childnodes[j].childNodes.length; k++){
                      if(childnodes[j].childNodes[k].nodeName == 'path'){
                        for(var l = 0; l < childnodes[j].childNodes[k].childNodes.length; l++){
                          if( childnodes[j].childNodes[k].childNodes[l].nodeName == 'image'){
                            var iconurl = childnodes[j].childNodes[k].childNodes[l].getAttribute('src')
                          }
                        }
                      }
                    }
                  }
                  if(iconurl != undefined){
                    if(iconurl.includes('http')){
                      shape_obj_map[shapename] = iconurl
                    }else{
                      //http://localhost:3000/design_center/resources/stencils/criterion/vedge.svg
                      shape_obj_map[shapename] = window.location.origin+'/'+'design_center/'+iconurl
                    }
                  }
                }
                shape_obj_list.push(shape_obj_map)
            }
            name_icon_obj_map[value['title']] = shape_obj_list
          }
      }
  }
    setDesignerElements(name_icon_obj_map)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      deal_id: "",
      profile_name: "",
      learningPermissions: [],
      learningViews: [],
      sandboxPermissions: [],
      sandboxViews: [],
      designerPermissions: [],
    },
    onSubmit: (values) => {
      let permissions = [];
      let max_allowed_templates = 0
      values.learningViews.forEach((item) => {
        let payload = {
          resource_type: "Learning",
          resource_name: item,
          permission: "view",
          alloc_hours: 0,
        };
        if (!values.learningPermissions.includes(item)) {
          permissions.push(payload);
        }
      });

      values.learningPermissions.forEach((item) => {
        let payload = {
          resource_type: "Learning",
          resource_name: item,
          permission: "create",
          alloc_hours:
            document.getElementById(`${item}_allocate_hours`).value || 0,
        };
        permissions.push(payload);
      });

      values.sandboxViews.forEach((item) => {
        let payload = {
          resource_type: "Sandbox",
          resource_name: item,
          permission: "view",
          alloc_hours:
            document.getElementById(`${item}_allocate_hours`).value || 0,
        };
        if (!values.sandboxPermissions.includes(item)) {
          permissions.push(payload);
        }
      });

      values.sandboxPermissions.forEach((item) => {
        let payload = {
          resource_type: "Sandbox",
          resource_name: item,
          permission: "create",
          alloc_hours:
            document.getElementById(`${item}_allocate_hours`).value || 0,
        };

        if (item === "designer_portal") {
          payload["designer_objects"] = values.designerPermissions;
          max_allowed_templates = document.getElementById(`${item}_max_allowed_templates`).value || 0
        }

        permissions.push(payload);
      });

      let payload = {
        profile_name: `${values.deal_id}_${values.profile_name}`,
        permissions: permissions,
        max_allowed_templates: max_allowed_templates
      };

      SDCloudBackendAPIWithToken()
        .post("/create_profile", payload)
        .then((resp) => resp.data)
        .then((data) => {
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("Profile created successfully");
        })
        .catch((err) => {
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
        });
    },
    validate: (values) => {
      let errors = {};

      if (!values.deal_id) {
        //console.log("deal id error");
        errors.deal_id = "Required";
      }

      if (!values.profile_name) {
        errors.profile_name = "Required";
      }

      //   if (!values.privilege) {
      //     errors.privilege = "Required";
      //   }

      //   if (!values.permission_profile_name && values.privilege !== "admin") {
      //     errors.permission_profile_name = "Required";
      //   }

      return errors;
    },
  });

  const renderTemplateData = async () => {
    const templates = SDCloudBackendAPIWithToken()
      .get("/get_resources")
      .then((resp) => resp.data)
      .then((data) => {
        // let learnings = data.learnings;
        // let sandboxes = data.sandboxes;
        let learnings = [];
        let showcases = [];
        let sandboxes = [];
        let designer = [];
        let designerObjects = [];

        // Learnings & Showcases
        data.learnings.forEach((item) => {
          if (item.is_scripted_demo) {
            showcases.push({
              template_name: item.name,
              display_name: item.display_name,
              can_view: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`learningViews`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              permission: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`learningPermissions`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              // hours_available:
              //   item.allocated_hours - item.consumed_hours > 0
              //     ? item.allocated_hours - item.consumed_hours
              //     : 0,
              lab_hours: item.labhours,
              allocate_hours: (
                <TextField
                  type="number"
                  variant="filled"
                  name={`${item.name}_allocate_hours`}
                  fullWidth
                  inputProps={{ id: `${item.name}_allocate_hours` }}
                  InputLabelProps={inputProps}
                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                />
              ),
            });
          } else {
            learnings.push({
              template_name: item.name,
              display_name: item.display_name,
              can_view: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`learningViews`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              permission: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`learningPermissions`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              // hours_available:
              //   item.allocated_hours - item.consumed_hours > 0
              //     ? item.allocated_hours - item.consumed_hours
              //     : 0,
              lab_hours: item.labhours,
              allocate_hours: (
                <TextField
                  type="number"
                  variant="filled"
                  name={`${item.name}_allocate_hours`}
                  fullWidth
                  inputProps={{ id: `${item.name}_allocate_hours` }}
                  InputLabelProps={inputProps}
                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                />
              ),
            });
          }
        });

        // Sandboxes & Designer
        data.sandboxes.forEach((item) => {
          let max_allowed_templates = 0
          if (item.name === "designer_portal") {
            designerObjects = item.designer_objects.map((designerObject) => ({
              template_name: designerObject.name,
              display_name: designerObject.display_name,
              permission: (
                <FormControlLabel
                  control={
                    <Switch
                      value={designerObject.name}
                      name={`designerPermissions`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
            }));

            designer.push({
              template_name: item.name,
              display_name: "Designer",
              can_view: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`sandboxViews`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              permission: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`sandboxPermissions`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              // hours_available:
              //   item.allocated_hours - item.consumed_hours > 0
              //     ? item.allocated_hours - item.consumed_hours
              //     : 0,
              lab_hours: item.labhours,
              allocate_hours: (
                <TextField
                  type="number"
                  variant="filled"
                  name={`${item.name}_allocate_hours`}
                  fullWidth
                  InputProps={{ id: `${item.name}_allocate_hours` }}
                  InputLabelProps={inputProps}
                  style={{paddingRight:"15px"}}
                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                />
              ),
              max_allowed_templates: (
                <TextField
                  type="number"
                  variant="filled"
                  name={`${item.name}_max_allowed_templates`}
                  fullWidth
                  InputProps={{ id: `${item.name}_max_allowed_templates` }}
                  InputLabelProps={inputProps}
                  defaultValue={max_allowed_templates}
                  style={{paddingRight:"15px"}}
                />
              )
            });

            setDesigner(designerObjects);
          } else {
            sandboxes.push({
              template_name: item.name,
              display_name:
                item.name === "designer_portal"
                  ? "Designer"
                  : item.display_name,
              can_view: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`sandboxViews`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              permission: (
                <FormControlLabel
                  control={
                    <Switch
                      value={item.name}
                      name={`sandboxPermissions`}
                      color="default"
                    />
                  }
                  onChange={formik.handleChange}
                />
              ),
              // hours_available:
              //   item.allocated_hours - item.consumed_hours > 0
              //     ? item.allocated_hours - item.consumed_hours
              //     : 0,
              lab_hours: item.labhours,
              allocate_hours: (
                <TextField
                  type="number"
                  variant="filled"
                  name={`${item.name}_allocate_hours`}
                  fullWidth
                  InputProps={{ id: `${item.name}_allocate_hours` }}
                  InputLabelProps={inputProps}
                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                />
              ),
            });
          }
        });

        setLearningsData(learnings);
        setSandboxesData(sandboxes);
        setShowcasesData(showcases);
        setDesignerData(designer);
        setShowLoader(false);
      });
  };

  const renderDealIdDropdown = async () => {
    let accountName = JSON.parse(localStorage.getItem("profileData"))?.account;
    const profileItems = SDCloudBackendAPIWithToken()
      .get(`/get_engagement_ids?account=${accountName}`)
      .then((resp) => resp.data)
      .then((data) => {
        let engagements = data.engagements_id_list;

        let items = engagements.map((item) => {
          return (
            <option key={uuidv4()} value={item}>
              {item}
            </option>
          );
        });

        items = [
          <option key={uuidv4()} value={null} selected>
            None
          </option>,
          ...items,
        ];

        setMenuItems(items);
      });
  };

  const getDesignObjects = async () => {
    const designObject = localStorage.getItem("design_objects");
    if (!designObject) {
      const response = await getDesignerObjects();
      if (response.success) {
        localStorage.setItem("design_objects", JSON.stringify(response.data));
      }
    }
  };

  useEffect(() => {
    renderDealIdDropdown();
    renderTemplateData();
    getDesignObjects();
  }, []);


  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.gridContainer}>
          <Grid item md={12}>
            <Typography variant="h4">Create new profile</Typography>
          </Grid>
          <Grid item md={12}>
            <Grid container justify="space-between" alignItems="flex-end">
              <Grid item xs={12} md={5}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Select Deal ID
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    labelWidth={100}
                    fullWidth
                    name="deal_id"
                    value={formik.values.deal_id}
                    onChange={formik.handleChange}
                    native
                    error={formik.errors.deal_id ? true : false}
                    helperText={formik.errors.deal_id}
                  >
                    {menuItems.length > 0 ? (
                      menuItems
                    ) : (
                      <option value="">None</option>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                {formik.errors.profile_name && formik.touched.profile_name ? (
                  <TextField
                    type="text"
                    variant="filled"
                    label="Profile Name"
                    name="profile_name"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.profile_name}
                    onChange={formik.handleChange}
                    helperText={formik.errors.profile_name}
                    onBlur={formik.handleBlur}
                    error
                  />
                ) : (
                  <TextField
                    type="text"
                    variant="filled"
                    label="Profile Name"
                    name="profile_name"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.profile_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {showLoader ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "80px",
              }}
            >
              <LoaderHoc isLoading={showLoader}></LoaderHoc>
            </div>
          ) : (
            <>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={columns}
                    data={learningsData}
                    tableTitle="Learn"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={["template_name" , "max_allowed_templates"]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "35px"}}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={columns}
                    data={showcasesData}
                    tableTitle="Showcase"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={["template_name" , "max_allowed_templates"]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={columns}
                    data={sandboxesData}
                    tableTitle="Sandbox"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={["template_name", "lab_hours" , "max_allowed_templates"]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={columns}
                    data={designerData}
                    tableTitle="Designer"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={["template_name", "lab_hours"]}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                <Typography className={classes.root}>
                  <Link href="#" onClick={handleClickOpen}>
                    Show Design Center Elements
                  </Link>
                </Typography>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={designerColumns}
                    data={designer}
                    tableTitle="Available Designer Objects"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={["template_name"]}
                  />
                </Grid>
              </Grid> */}
              <Grid item xs={12} md={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  disableFocusRipple
                  className={classes.buttOutlined}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12}>
            {showNotification ? (
              <Alert
                onClose={() => setShowNotification(false)}
                severity={notificationType}
              >
                {notificationMessage}
              </Alert>
            ) : null}
          </Grid>
        </Grid>

        {/* </Grid> */}
      </form>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"><b>{"Design Centre Eleemnts"}</b></DialogTitle>
        <DialogContent>
          {Object.keys(designerElements).map((key, index) => {
            return (
            <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                <Typography className={classes.heading}>{key}</Typography>
                </AccordionSummary>
                <Grid container spacing={4}>
                {Object.entries(designerElements[key]).map(([index, value]) => {
                  return (
                    <div>
                      
                      {Object.entries(value).map(([iconkey, iconurl]) => {
                         return(
                          <AccordionDetails>
                          <Grid item xs={5}>
                            <img src={iconurl} height='56' width={56}></img>
                            <Paper className={classes.paper}>{iconkey}</Paper>
                          </Grid>
                          </AccordionDetails>
                         );
                        })}
  
                    </div>
                  );
                })}
                </Grid>
            </Accordion>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" right>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateProfile;
