import React, { useEffect } from "react";
import theme from "theme";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import "./progressBar.css";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { useDispatch } from "react-redux";

// Icons
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "24px",
    fontWeight: "400",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  subtitle: {
    marginTop: "15px",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  runningTimeTitle: {
    fontSize: "0.875rem",
    fontWeight: "300",
    marginRight: "5px",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  runningTime: {
    fontSize: "0.875rem",
    fontWeight: "500",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
"@keyframes example": {
    from: { opacity: 1 },
    to: { opacity: 0.2 }
  },
  clusterStatus: {
    marginTop: "36px"
    // "& @keyframes example": {
    //   "from": {opacity: 1},
    //   "to": {opacity: 0.2}
    // },
    // animationName: "$example",
    // animationDuration: "1s",
    // animationIterationCount: "infinite",
    // animationDirection: "alternate",
  },
  progressBar: {
    backgroundColor: theme.palette.grey[300]
  },
  barColorPrimary: {
    backgroundColor: `${theme.palette.secondary.main} !important`
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12)
  },
  statusIcon: {
    color: theme.palette.secondary.main,
    marginRight: "5px"
  },
  runTime: {
    textAlign: "end",
    display: "flex",
    alignItems: "center"
  },
  headerContainer: {
    marginBottom: "30px"
  },
  pgress: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    "&.active:before": {
      color: `${theme.palette.secondary.main} !important`,
      background: `${theme.palette.secondary.main} !important`
    },
    "&.active:after": {
      background: `${theme.palette.secondary.main} !important`
    },
    "&.inactive:before": {
      color: "#C4C4C4 !important",
      background: "#C4C4C4 !important"
    },
    "&.inactive:after": {
      background: "#C4C4C4 !important"
    },
    "&.selected": {
      color: `${theme.palette.secondary.main} !important`,
      "&.active": {
        color: `${theme.palette.secondary.main} !important`,
        background: `${theme.palette.secondary.main} !important`
      },
      "&:before": {
        color: `${theme.palette.secondary.main} !important`,
        background: `${theme.palette.secondary.main} !important`
      },
      "&:after": {
        background: `${theme.palette.secondary.main} !important`
      }
    }
  },
  textfont: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
},
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12)
  }
}));

function ExerciseHeader(props) {
  const classes = useStyles();

  const [progress, setProgress] = React.useState(0);
  // const renderIcon = () => {
  //   switch (props.clusterStatus) {
  //     case "ready":
  //       return <CloudDoneIcon className={classes.statusIcon} />;
  //     case "suspended":
  //       return <PauseCircleFilledIcon className={classes.statusIcon} />;
  //     case "suspending":
  //       return <PauseCircleFilledIcon className={classes.statusIcon} />;
  //     default:
  //       return <QueryBuilderIcon className={classes.statusIcon} />;
  //   }
  // };

  const status = props.clusterStatus;

  const previous_status = props.clusterPrevious_status;
  const dispatch = useDispatch();

  const showErrorNotification = (message,color) => {
    const payload = {
      message: message,
      color: color
    };
    dispatch(SHOW_NOTIFICATION({ payload }));
  };


  // Convert clusterRunningTime and clusterRemainingTime to minutes if they are in hours
  const parts = props.clusterRunningTime.split(' ');
  const extractedDays1 = parts.includes('days') ? parseInt(parts[parts.indexOf('days') - 1], 10) : 0;
  const extractedHours1 = parts.includes('hours') ? parseInt(parts[parts.indexOf('hours') - 1], 10) : 0;
  const extractedMinutes1 = parts.includes('minutes') ? parseInt(parts[parts.indexOf('minutes') - 1], 10) : 0;
  const clusterRunningTimeMinutes = parseFloat(extractedDays1) * 14400 + parseFloat(extractedHours1) * 60 + parseFloat(extractedMinutes1) ;
  const parts1 = props.clusterRemainingTime.split(' ');
  const extractedDays2 = parts1.includes('days') ? parseInt(parts1[parts1.indexOf('days') - 1], 10) : 0;
  const extractedHours2 = parts1.includes('hours') ? parseInt(parts1[parts1.indexOf('hours') - 1], 10) : 0;
  const extractedMinutes2 = parts1.includes('minutes') ? parseInt(parts1[parts1.indexOf('minutes') - 1], 10) : 0;
  const clusterRemainingTimeMinutes = parseFloat(extractedDays2) * 14400 + parseFloat(extractedHours2) * 60 + parseFloat(extractedMinutes2) ;
  // const clusterRunningTimeMinutes = parseFloat(props.clusterRunningTime) * 60;
  // const clusterRemainingTimeMinutes = parseFloat(props.clusterRemainingTimeMinutes)*60
  const clusterTotalTimeMinutes = clusterRunningTimeMinutes + clusterRemainingTimeMinutes;

  // Debugging output
  //console.log('runTimeMinutes:', clusterRunningTimeMinutes);
  //console.log('clusterTotalTime:', clusterTotalTimeMinutes);

  const percentage = isNaN(clusterRunningTimeMinutes) || isNaN(clusterTotalTimeMinutes) || clusterTotalTimeMinutes === 0
    ? 0
    : Math.round((clusterRunningTimeMinutes * 100) / clusterTotalTimeMinutes);

  //console.log('Calculated Percentage:', percentage);
  useEffect(() => {
    if (props.clusterStatus === "error") {
      showErrorNotification("Error in provisioning. Please terminate the cluster and provision it again.","danger");
    }
    const end_action = props.auto_terminate ? "terminate" : "suspend";
    if (props.clusterStatus === "ready" && Math.ceil(clusterRemainingTimeMinutes) > 0 && Math.ceil(clusterRemainingTimeMinutes) <= 10) {
      showErrorNotification(`Your sandbox will ${end_action} in ${Math.ceil(clusterRemainingTimeMinutes)} minutes.`,"info");
    }
  }, [props.clusterStatus, clusterRemainingTimeMinutes]);
  return (
    <Grid container className={classes.headerContainer}>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <p className={classes.title}>{props.title}</p>
        <p className={classes.subtitle}>{props.subtitle}</p>
        <Tooltip
          arrow
          className={classes.tooltip}
          title={
            <React.Fragment>
              <div>
                <span className={classes.runningTimeTitle}>Running Time:</span>
                <span className={classes.runningTime}>
                  {props.clusterRunningTime} 
                </span>
              </div>
              <div className={classes.runTime}>
                <span className={classes.runningTimeTitle}>
                  Remaining Time:
                </span>
                <span className={classes.runningTime}>
                  {props.clusterRemainingTime} 
                </span>
              </div>
            </React.Fragment>
          }
        >
          <Box display="flex" alignItems="center" id={"progress-box0"}>
            <Box
              width="100%"
              mr={1}
              style={{ width: "80%" }}
              id={"progress-box1"}
            >
              <LinearProgress
                variant="determinate"
                className={classes.progressBar}
                classes={{ barColorPrimary: classes.barColorPrimary }}
                value={percentage}
              />
            </Box>
            <Box minWidth={35} id={"progress-box2"}>
              <Typography variant="body2">
                {percentage}%
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={8} md={8} lg={8} className={classes.clusterStatus}>
        {props.clusterStatus === "suspending" ? (
          <ul className="progressbar">
            <li
              className={`${classes.textfont + " " + classes.pgress}  ${
                props.clusterStatus === "ready"
                  ? "selected"
                  : [
                      props.clusterStatus === "ready" || "suspending" || "suspended"
                        ? "active"
                        : "inactive"
                    ]
              }`}
            >
              Ready
            </li>
            <li
              className={`${classes.textfont + " " + classes.pgress}  ${
                props.clusterStatus === "suspending"
                  ? "selected"
                  : [
                      props.clusterStatus === "suspending" || "suspended"
                        ? "active"
                        : "inactive"
                    ]
              }`}
            >
              Suspending
            </li>
            <li
              className={`${classes.textfont + " " + classes.pgress}  ${
                props.clusterStatus === "suspended"
                  ? "selected"
                  : [props.clusterStatus === "suspended" ? "active" : "inactive"]
              }`}
            >
              Suspended
            </li>
          </ul>
        ) : (
          [
            props.clusterStatus === "suspended" ||
            (props.clusterStatus === "suspended" && props.clusterPrevious_status === " ") ? (
              <ul className="progressbar">
                <li
                  className={`${classes.textfont + " " + classes.pgress}  ${
                    props.clusterStatus === "ready"
                      ? "selected"
                      : [
                          props.clusterStatus === "ready" || "suspending" || "suspended"
                            ? "active"
                            : "inactive"
                        ]
                  }`}
                >
                  Ready
                </li>
                <li
                  className={`${classes.textfont + " " + classes.pgress} ${
                    props.clusterStatus === "suspending"
                      ? "selected"
                      : [
                          props.clusterStatus === "suspending" || "suspended"
                            ? "active"
                            : "inactive"
                        ]
                  }`}
                >
                  Suspending
                </li>
                <li
                  className={`${classes.textfont + " " + classes.pgress}  ${
                    props.clusterStatus === "suspended"
                      ? "selected"
                      : [props.clusterStatus === "suspended" ? "active" : "inactive"]
                  }`}
                >
                  Suspended
                </li>
              </ul>
            ) : (
              [
                props.clusterStatus === "resuming" ? (
                  <ul className="progressbar">
                    <li
                      className={`${classes.textfont + " " + classes.pgress}  ${
                        props.clusterStatus === "suspended"
                          ? "selected"
                          : [
                              props.clusterStatus === "suspended" || "resuming" || "ready"
                                ? "active"
                                : "inactive"
                            ]
                      }`}
                    >
                      Suspended
                    </li>
                    <li
                      className={`${classes.textfont + " " + classes.pgress}  ${
                        props.clusterStatus === "resuming"
                          ? "selected"
                          : [
                              props.clusterStatus === "resuming" || "ready"
                                ? "active"
                                : "inactive"
                            ]
                      }`}
                    >
                      Resuming
                    </li>
                    <li
                      className={`${classes.textfont + " " + classes.pgress}  ${
                        props.clusterStatus === "ready"
                          ? "selected"
                          : [props.clusterStatus === "ready" ? "active" : "inactive"]
                      }`}
                    >
                      Ready
                    </li>
                  </ul>
                ) : (
                  [
                    (props.clusterStatus === "ready" && props.clusterPrevious_status === "") ||
                    (props.clusterStatus === "ready" && props.clusterPrevious_status === "resuming") ? (
                      <ul className="progressbar">
                        <li
                          className={`${classes.textfont +
                            " " +
                            classes.pgress}  ${
                            props.clusterStatus === "suspended"
                              ? "selected"
                              : [
                                  props.clusterStatus === "suspended" ||
                                  "resuming" ||
                                  "ready"
                                    ? "active"
                                    : "inactive"
                                ]
                          }`}
                        >
                          Suspended
                        </li>
                        <li
                          className={`${classes.textfont +
                            " " +
                            classes.pgress}  ${
                            props.clusterStatus === "resuming"
                              ? "selected"
                              : [
                                  props.clusterStatus === "resuming" || "ready"
                                    ? "active"
                                    : "inactive"
                                ]
                          }`}
                        >
                          Resuming
                        </li>
                        <li
                          className={`${classes.textfont +
                            " " +
                            classes.pgress}  ${
                            props.clusterStatus === "ready"
                              ? "selected"
                              : [props.clusterStatus === "ready" ? "active" : "inactive"]
                          }`}
                        >
                          Ready
                        </li>
                      </ul>
                    ) : (
                      [
                        props.clusterStatus === "not provisioned" ||
                        props.clusterStatus === "error" ? null : (
                          <ul className="progressbar">
                            <li
                              className={`${classes.textfont +
                                " " +
                                classes.pgress}  ${
                                props.clusterStatus === "queued"
                                  ? "selected"
                                  : [
                                      props.clusterStatus === "queued" ||
                                      props.clusterStatus === "spawning" ||
                                      props.clusterStatus === "provisioning" ||
                                      props.clusterStatus === "stabilizing" ||
                                      props.clusterStatus === "ready"
                                        ? "active"
                                        : "inactive"
                                    ]
                              }`}
                            >
                              Queued
                            </li>
                            <li
                              className={`${classes.textfont +
                                " " +
                                classes.pgress}  ${
                                props.clusterStatus === "spawning"
                                  ? "selected"
                                  : [
                                      props.clusterStatus === "spawning" ||
                                      props.clusterStatus === "provisioning" ||
                                      props.clusterStatus === "stabilizing" ||
                                      props.clusterStatus === "ready"
                                        ? "active"
                                        : "inactive"
                                    ]
                              }`}
                            >
                              Spawning
                            </li>
                            <li
                              className={`${classes.textfont +
                                " " +
                                classes.pgress}  ${
                                props.clusterStatus === "provisioning"
                                  ? "selected"
                                  : [
                                      props.clusterStatus === "provisioning" ||
                                      props.clusterStatus === "stabilizing" ||
                                      props.clusterStatus === "ready"
                                        ? "active"
                                        : "inactive"
                                    ]
                              }`}
                            >
                              Provisioning
                            </li>
                            <li
                              className={`${classes.textfont +
                                " " +
                                classes.pgress}  ${
                                props.clusterStatus === "stabilizing"
                                  ? "selected"
                                  : [
                                      props.clusterStatus === "stabilizing" ||
                                      props.clusterStatus === "ready"
                                        ? "active"
                                        : "inactive"
                                    ]
                              }`}
                            >
                              Stabilizing
                            </li>
                            <li
                              className={`${classes.textfont +
                                " " +
                                classes.pgress}  ${
                                props.clusterStatus === "ready"
                                  ? "selected"
                                  : [props.clusterStatus === "ready" ? "active" : "inactive"]
                              }`}
                            >
                              Ready
                            </li>
                          </ul>
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        )}
      </Grid>
    </Grid>
  );
}

export default ExerciseHeader;
