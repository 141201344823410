import React, { useEffect, useMemo, useState, Fragment } from "react";
import spacetime from "spacetime";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  Typography,
  Button,
  TextareaAutosize,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import { useHistory } from "react-router-dom";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment-timezone";
import { useFormik } from "formik";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { Alert } from "@material-ui/lab";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InfoIcon from '@mui/icons-material/Info'


// Styling
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "25px 30px 30px 45px",
    border: "1px solid #F0F0F0",
    boxSizing: "border-box",
    background: "#FFFFFF",
    // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    // minHeight: "334px",
    position: "relative",
    marginTop: "15px",
  },
  body2: {
    color: "#838383",
    paddingTop: "10px",
    fontSize: "12px",
  },
  uploadContainer: {
    marginTop: "8px",
    border: "1px solid #8C8C8C",
    boxShadow: "0px 4px 4px 0px #000000 25%",
    borderStyle: "dashed",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
  btnStyle: {
    background: `${theme.palette.buttonColor.main} !important`,
  },
  imageUploadBtn: {
    background: `${theme.palette.primary.main} !important`,
  },
  purposeDropdown: {
    width: "100%",
    marginTop: "5px",
  },
  warningNote: {
    padding: "auto",
    "& .MuiAlert-icon": {
      // display: "none",
      // color:"rgb(206, 17, 38)"
      color:"#0288d1"
    },
  },
}));

const AddTraining = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const inputProps = {
    disableAnimation: true,
    shrink: false,
  };
  const [tz, setTz] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [datetime, setDatetime] = useState(spacetime.now());

  useMemo(() => {
    const tzValue = tz.value ?? tz;
    setDatetime(datetime.goto(tzValue));
  }, []);

  useEffect(() => {
    dispatch(SET_NAVBAR_TITLE("New Training"));
  }, []);

  const handlePrevious = () => {
    history.push("/usermanagement/instructer/viewtraining");
  };

  const formik = useFormik({
    initialValues: {
      training_name: props.location.state!== undefined?props.location.state.training_name:"",
      training_email: props.location.state!== undefined?props.location.state.training_email:JSON.parse(localStorage.profileData).email,
      time_zone: props.location.state!== undefined?props.location.state.time_zone:tz,
      start_date: props.location.state!== undefined?props.location.state.start_date:null,
      end_date: props.location.state!== undefined?props.location.state.end_date:null,
      no_of_participants: props.location.state!== undefined?props.location.state.no_of_participants:"",
      description:props.location.state!== undefined?props.location.state.description:"",
      endaction: props.location.state!== undefined?props.location.state.endaction:"suspend",
    },
    onSubmit: (values) => {
      history.push({
        pathname : "/Instructer/profiledetails",
        state :{
          training_name : values.training_name,
          training_email : values.training_email,
          time_zone : tz,
          start_date: values.start_date,
          end_date: values.end_date,
          no_of_participants: values.no_of_participants,
          description:values.description,
          endaction:values.endaction
        }
      })
    },
    // },
    validate: (values) => {
      let errors = {};

      if (!values.training_name) {
        errors.training_name = "Required";
      }
      if (!values.training_email) {
        errors.training_email = "Required";
      }
      if (!values.time_zone) {
        errors.time_zone = "Required";
      }
      if (!values.start_date) {
        errors.start_date = "Required";
      }
      if (!values.end_date) {
        errors.end_date = "Required";
      }
      if (!values.no_of_participants) {
        errors.no_of_participants = "Required";
      }
      if (!values.description) {
        errors.description = "Required";
      }
      if (values.no_of_participants > 40 || values.no_of_participants < 1) {
        errors.no_of_participants = "No of Participants should be less than or equal to 40";
      }
      return errors;
    },
  });

  return (
    <Grid container className={classes.container} spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="h4">Add New Training</Typography>
        <Typography variant="body2" className={classes.body2}>
          Fill all the required details
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}></Grid>
      <Grid item xs={12} sm={6}>
        {formik.errors.training_name && formik.touched.training_name ? (
          <FormControl fullWidth>
            <TextField
              label="Name Of Training"
              InputLabelProps={{
                disableAnimation: true,
                shrink: false,
              }}
              InputProps={{ disableUnderline: true }}
              id="training_name"
              variant="filled"
              helperText={formik.errors.training_name}
              error={
                formik.errors.training_name && formik.touched.training_name
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </FormControl>
        ) : (
          <FormControl fullWidth>
            <TextField
              label="Name Of Training"
              InputLabelProps={{
                disableAnimation: true,
                shrink: false,
              }}
              InputProps={{ disableUnderline: true }}
              id="training_name"
              variant="filled"
              value={formik.values.training_name}
              onChange={formik.handleChange}
            />
          </FormControl>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {formik.errors.training_email && formik.touched.training_email ? (
          <FormControl fullWidth>
            <TextField
              label="Instructor Email"
              InputLabelProps={{
                disableAnimation: true,
                shrink: false,
              }}
              InputProps={{ disableUnderline: true }}
              id="training_email"
              variant="filled"
              helperText={formik.errors.training_email}
              error={
                formik.errors.training_email && formik.touched.training_email
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </FormControl>
        ) : (
          <FormControl fullWidth>
            <TextField
              label="Instructor Email"
              InputLabelProps={{
                disableAnimation: true,
                shrink: false,
              }}
              InputProps={{ disableUnderline: true }}
              id="training_email"
              variant="filled"
              value={formik.values.training_email}
              onChange={formik.handleChange}
            />
          </FormControl>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{ marginTop: "15px" }}
        className={classes.body2}
      >
        {formik.errors.start_date && formik.touched.start_date ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Fragment>
        
              <FormControl fullWidth>
              <KeyboardDatePicker
                inputVariant="filled"
                label="Start Date"
                name="start_date"
                format="MM/dd/yyyy"
                // placeholder="MM/DD/YYYY"
                fullWidth
                labelFunc={() => formik.values.start_date || "MM/DD/YYYY"}
                onChange={(val) => {
                  formik.setFieldValue(
                    "start_date",
                    moment(val).format("MM/DD/YYYY")
                  );
                }}
                helperText={formik.errors.start_date}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.start_date && formik.touched.start_date
                    ? true
                    : false
                }
              />
              </FormControl>
            </Fragment>
          </MuiPickersUtilsProvider>
        ) : (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Fragment>
            <FormControl fullWidth>
              <KeyboardDatePicker
                inputVariant="filled"
                label="Start Date"
                name="start_date"
                format="MM/dd/yyyy"
                // placeholder="MM/DD/YYYY"
                fullWidth
                labelFunc={() => formik.values.start_date || "MM/DD/YYYY"}
                //onChange={formik.handleChange}
                onChange={(val) => {
                  formik.setFieldValue(
                    "start_date",
                    moment(val).format("MM/DD/YYYY")
                  );
                }}
              />
              </FormControl>
            </Fragment>
          </MuiPickersUtilsProvider>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{ marginTop: "15px" }}
        className={classes.body2}
      >
        {formik.errors.end_date && formik.touched.end_date ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Fragment>
            <FormControl fullWidth>
              <KeyboardDatePicker
                inputVariant="filled"
                label="End Date"
                name="end_date"
                format="MM/dd/yyyy"
                // placeholder="MM/DD/YYYY"
                fullWidth
                labelFunc={() => formik.values.end_date || "MM/DD/YYYY"}
                onChange={(val) => {
                  formik.setFieldValue(
                    "end_date",
                    moment(val).format("MM/DD/YYYY")
                  );
                }}
                helperText={formik.errors.end_date}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.end_date && formik.touched.end_date
                    ? true
                    : false
                }
              />
              </FormControl>
            </Fragment>
          </MuiPickersUtilsProvider>
        ) : (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Fragment>
            <FormControl fullWidth>
              <KeyboardDatePicker
                inputVariant="filled"
                name="end_date"
                label="End Date"
                format="MM/dd/yyyy"
                // placeholder="MM/DD/YYYY"
                fullWidth
                InputLabelProps={inputProps}
                labelFunc={() => formik.values.end_date || "MM/DD/YYYY"}
                onChange={(val) => {
                  formik.setFieldValue(
                    "end_date",
                    moment(val).format("MM/DD/YYYY")
                  );
                }}
              />
              </FormControl>
            </Fragment>
          </MuiPickersUtilsProvider>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {formik.errors.description &&
        formik.touched.description ? (
          <FormControl fullWidth>
            <TextField
              label="Description"
              type="textarea"
              InputLabelProps={{
                disableAnimation: true,
                shrink: false,
              }}
              InputProps={{ disableUnderline: true }}
              id="description"
              variant="filled"
              helperText={formik.errors.description}
              error={
                formik.errors.description &&
                formik.touched.description
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </FormControl>
        ) : (
          <FormControl fullWidth>
            <TextField
              label="Description"
              type="textarea"
              InputLabelProps={{
                disableAnimation: true,
                shrink: false,
              }}
              InputProps={{ disableUnderline: true }}
              id="description"
              variant="filled"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </FormControl>
        )}
      </Grid>
      <Grid item xs={12} sm={2}>
        {formik.errors.no_of_participants &&
        formik.touched.no_of_participants ? (
          <FormControl fullWidth>
            <TextField
              label="No of participants"
              type="number"
              InputLabelProps={{
                disableAnimation: true,
                shrink: false,
              }}
              InputProps={{ disableUnderline: true }}
              id="no_of_participants"
              variant="filled"
              helperText={formik.errors.no_of_participants}
              error={
                formik.errors.no_of_participants &&
                formik.touched.no_of_participants
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </FormControl>
        ) : (
          <FormControl fullWidth>
            <TextField
              label="No of participants"
              type="number"
              InputLabelProps={{
                disableAnimation: true,
                shrink: false,
              }}
              InputProps={{ disableUnderline: true }}
              id="no_of_participants"
              min="1"
              max="40"
              variant="filled"
              value={formik.values.no_of_participants}
              onChange={formik.handleChange}
            />
          </FormControl>
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <Typography variant="body2" className={classes.body2}>
            Time Zone
          </Typography>
            <FormControl variant="filled" className={classes.purposeDropdown}>
              <TimezoneSelect
                value={tz}
                label="Time Zone"
                onChange={setTz}
                timezones={{
                  ...allTimezones,
                  "America/Lima": "Pittsburgh",
                  "Europe/Berlin": "Frankfurt"
                }}
              />
            </FormControl>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <Typography variant="body2" className={classes.body2}>
            End Action
          </Typography>
          <Alert
                              icon={<InfoIcon fontSize="inherit" />}
                              severity="warning"
                              className={classes.warningNote}
                            >
                              Note: If the "Terminate" option is selected, the "Suspend" feature will be disabled and unavailable for future actions.
                            </Alert>
            <FormControl component="fieldset">
                                  <RadioGroup
                                    row
                                    aria-label="endaction"
                                    name="endaction"
                                    value={formik.values.endaction}
                                    className={classes.radioButton}
                                    onChange={formik.handleChange}
                                  >
                                    <FormControlLabel
                                      value="suspend"
                                      control={<Radio color="primary" />}
                                      label="Suspend"
                                    />
                                    <FormControlLabel
                                      value="terminate"
                                      control={<Radio color="primary" />}
                                      label="Terminate"
                                    />
                                  </RadioGroup>
                                </FormControl>
        </FormControl>
      </Grid>
      
      <Grid item xs={12} sm={8} md={8}></Grid>
      <Grid item xs={12} sm={2} md={2}>
        <FormControl fullWidth>
          <Button
            onClick={handlePrevious}
            fullWidth
            variant="contained"
            color="secondary"
            disableElevation
            disableFocusRipple
            type="submit"
          >
            Previous
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <FormControl fullWidth>
          <Button
            onClick={formik.handleSubmit}
            fullWidth
            variant="contained"
            color="secondary"
            disableElevation
            disableFocusRipple
            type="submit"
          >
            Next
          </Button>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default AddTraining;
