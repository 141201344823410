// View that gets rendered when launch new lab is clicked.
import React, { Component } from "react";
import { Api, Offerings, Urls } from "config/api";
import LaunchNewOfferingCard from "views/Components/ServiceOffering/LaunchNewOfferingCard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  HIDE_LOADER,
  SET_NAVBAR_TITLE,
  SHOW_LOADER,
  SHOW_NOTIFICATION
} from "action_creators/actioncreator";
import { v4 as uuidv4 } from "uuid";
import { asyncSetState } from "utils/utilfunction";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { CMSAPIWithToken } from "apis/CMS";
import backIcon from "../../../assets/criterion_networks/icons/learnings/long-left-arrow.png";
import globalTheme from "../../../theme";
import { ValueSource } from "react-avatar";

const useStyles = makeStyles({
  labType: {
    bottom: 0,
    // marginLeft: "15px",
    // paddingLeft: "15px",
    paddingRight: "15px",
    cursor: "pointer",
    marginBottom: "0px",
    paddingBottom: "0px",
    fontSize: "14px",
    fontWeight: "normal",
    "&:hover": {
      color: PRIMARY_COLOR
    }
  }
});

const customStyles = theme => ({
  headerStyle: {
    backgroundColor: "white",
    margin: "15px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      margin: "15px"
    }
  },
  launchLabTtile: {
    bottom: 0,
    marginTop: "5px",
    marginBottom: "0px",
    paddingBottom: "10px",
    fontSize: "36px",
    fontWeight: "400",
    color: "black"
  }
});

const selectedlabTypeStyle = {
  color: globalTheme.palette.secondary.main
};

const templateFilters = ["All", "Recent"];
// Custom notes information object in the current component
const notes = {
  reservedIpNote:
    "Option selected will reflect the IP address for the learning lab."
};
const EXPERIENCEFILTER = "Learn";

class LaunchLabs extends Component {
  constructor(props) {
    super(props);
    this.offeringType = props.offeringType || Offerings.LEARNING_LABS.resource;
    this.state = {
      adminLabArray: undefined,
      activeLabsTime: {},
      labIDToNameMap: {},
      reservedIp: null,
      currentLabType: "",
      labTypetoLabMap: {},
      allLabTemplates: [],
      profileDetails: null,
      resourceDetails: null,
      provisionedLab: {},
      reservedIpLoaded: false,
      canLaunch: true
    };
  }
  // subscribe to a learning lab
  subscribeLab = (labIdentifier, customerName, demoName) => {
    const data = {
      learning: labIdentifier,
      auto_renew: false
    };
    if (customerName) {
      data["cust_name"] = customerName;
    }
    if (demoName) {
      data["name"] = demoName;
    }
    return SDCloudBackendAPIWithToken().post(Api.subscribeLearningLab, data);
  };

  // provision a learning lab
  provisionLab = (labIdentifier, isReservedIp, ip, purpose, testValue, instance_type) => {
    const data = {
      learning: labIdentifier
    };
    if (isReservedIp) {
      data["reserved_ip"] = isReservedIp;
    }
    if (ip) {
      data["ip_address"] = ip;
    }
    if (purpose) {
      data["purpose"] = purpose;
    }
    if (testValue) {
      data["mode"] = testValue;
    }
    // if (zone) {
    //   data["zone"] = zone;
    // }
    if (instance_type) {
      data["instance_type"] = instance_type;
    }
    return SDCloudBackendAPIWithToken()
      .post(Api.provisionLearningLab, data)
      .then(res => {
        return res.data.uuid;
      });
  };

  launchLabBtnHandler = async (
    labId,
    labIdentifier,
    labEnabled,
    labName,
    isReservedIp = null,
    ip = null,
    customerName = null,
    demoName = null,
    purpose = null,
    // zone = null,
    instance_type = null,
    testValue = null
  ) => {
    let uuid = null;
    let redirectRoute =
      this.offeringType === Offerings.SALES_DEMO
        ? Urls.accessSalesDemo
        : Urls.accessLab;
    this.props.dispatch(SHOW_LOADER());
    try {
      await this.subscribeLab(labIdentifier, customerName, demoName).catch(
        err => {
          let stateParams = {
            labId: labId,
            labEnabled: labEnabled,
            labIdentifier: labIdentifier,
            uuid: uuid,
            labName: labName
          };
          let additionParams = stateParams;
          additionParams["offeringType"] = Offerings.LEARNING_LABS;
          this.props.history.push({
            pathname: redirectRoute,
            state: { stateParams: additionParams },
            search: `?labId=${labId}&uuid=${uuid}&labIdentifier=${labIdentifier}&labEnabled=${labEnabled}&labName=${labName}&offeringType=${Offerings.LEARNING_LABS.title}`
          });
          this.props.dispatch(HIDE_LOADER());
          throw err?.response?.data?.message || "error in subscribing";
        }
      );
      uuid = await this.provisionLab(
        labIdentifier,
        isReservedIp,
        ip,
        purpose,
        // zone,
        instance_type,
        testValue
      ).catch(err => {
        throw err?.response?.data?.message || "error in provisioning";
      });
      const stateParams = {
        labId: labId,
        labEnabled: labEnabled,
        labIdentifier: labIdentifier,
        uuid: uuid,
        labName: labName
      };
      if (isReservedIp) {
        stateParams["reserved_ip"] = true;
      }
      if (ip) {
        stateParams["ip_address"] = ip;
      }
      if (uuid) {
        let additionParams = stateParams;
        // redirectRoute = `${redirectRoute}/${uuid}/${labIdentifier}`;
        additionParams[
          "addHoursDetails"
        ] = this.props.profile.profileObj.add_hours;
        additionParams["offeringType"] = Offerings.LEARNING_LABS;
        await this.props.history.push({
          pathname: redirectRoute,
          state: { stateParams: additionParams },
          search: `?labId=${stateParams.labId}&uuid=${stateParams.uuid}&labIdentifier=${stateParams.labIdentifier}&labEnabled=${stateParams.labEnabled}&labName=${stateParams.labName}&offeringType=${Offerings.LEARNING_LABS.title}`
        });
      }
      await this.props.dispatch(HIDE_LOADER());
    } catch (err) {
      this.props.dispatch(HIDE_LOADER());
      const payload = {
        message: err,
        color: "danger"
      };
      this.props.dispatch(SHOW_NOTIFICATION({ payload }));
    }
  };

  accessLabBtnHandler = stateParams => {
    let redirectRoute =
      this.offeringType === Offerings.SALES_DEMO.resource
        ? Urls.accessSalesDemo
        : Urls.accessLab;

    let additionParams = stateParams;
    // redirectRoute = `${redirectRoute}/${stateParams.uuid}/${stateParams.labIdentifier}`;
    additionParams["addHoursDetails"] = this.props.profile.profileObj.add_hours;
    additionParams["offeringType"] = Offerings.LEARNING_LABS;
    this.props.history.push({
      pathname: redirectRoute,
      state: { stateParams: additionParams },
      search: `?labId=${stateParams.labId}&uuid=${stateParams.uuid}&labIdentifier=${stateParams.labIdentifier}&labEnabled=${stateParams.labEnabled}&labName=${stateParams.labName}&expiryDate=${stateParams.expiryDate}&createdDate=${stateParams.createdDate}&offeringType=${stateParams.offeringType.title}`
    });
  };

  // api call to find active labs
  getNewLearnings = async () => {
    return SDCloudBackendAPIWithToken()
      .get(Api.getNewLearning)
      .then(res => {
        let activeLabs = { names: [], userNames: {} };
        let provisionedLab = { labData: [], userNames: {} };
        res.data.learnings.forEach(item => {
          if (item.lab_uuid) {
            provisionedLab["labData"].push({
              labName: item.name,
              uuid: item.lab_uuid
            });
            if (
              provisionedLab.userNames[item.name] &&
              provisionedLab.userNames[item.name] ==
                localStorage.getItem("userName")
            ) {
              // //console.log("Do nothing ..")
            } else {
              Object.assign(provisionedLab["userNames"], {
                [item.name]: item.user_name
              });
            }
          }
          if (item.is_active === true) {
            this.setState(prevState => ({
              activeLabsTime: {
                ...prevState.activeLabsTime,
                [item.name]: {
                  createdDate: item.created,
                  expiryDate: item.expiry_date
                }
              }
            }));
            activeLabs["names"].push(item.name);
            if (
              activeLabs.userNames[item.name] &&
              activeLabs.userNames[item.name] ==
                localStorage.getItem("userName")
            ) {
              // //console.log("Do nothing ..")
            } else {
              Object.assign(activeLabs["userNames"], {
                [item.name]: item.user_name
              });
            }
          }
        });
        return { activeLabs, provisionedLab };
      })
      .then(({ activeLabs, provisionedLab }) => {
        return (
          asyncSetState(
            {
              activeLabs,
              provisionedLab
            },
            this
          )
            // .then(() => this.getAdminLabTypes())
            .catch(err => {
              throw err;
            })
        );
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // api call to find lab mappings
  // independent of sales demo or learning lab. calls a `general` api.
  getAdminLabTypes = async () => {
    return CMSAPIWithToken()
      .get(Api.adminLabTypes)
      .then(res => {
        const labIDToNameMap = {};
        res.data.results.forEach(item => {
          labIDToNameMap[item.id] = {
            sequence: item.sequence,
            name: item.name
          };
        });
        this.setState({
          labIDToNameMap
        });
      })
      .then(() => {
        // if (this.props.profile.profileObj.privilege === "developer") {
        //   return this.getProfile();
        // } else if (this.props.profile.profileObj.privilege === "admin") {
        //   return this.getResources();
        // }
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };
  //get resources for user type admin
  getResources = async (developer = null) => {
    const isSalesDemo = this.offeringType === Offerings.SALES_DEMO.resource;
    return SDCloudBackendAPIWithToken()
      .get(Api.getResource)
      .then(res => {
        let getResourcesDetails = {}; // { labname : { ...details }}
        if (res.data.hasOwnProperty("learnings")) {
          // creating a mapping of lab Name to lab details.
          res.data.learnings.forEach(item => {
            // is_scripted_demo = false implies not a sales demo.
            if (item.is_scripted_demo === isSalesDemo) {
              getResourcesDetails[item.name] = { ...item };
              delete getResourcesDetails.name; // delete name field to align with getProfile data.
            }
          });
        }
        return getResourcesDetails;
      })
      .then(getResourcesDetails => {
        this.setState(
          {
            resourceDetails: getResourcesDetails
          },
          //console.log("")
        );
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // gets lab name and tries related data from backend db for user type non admin.
  getProfile = () => {
    const isSalesDemo = this.offeringType === Offerings.SALES_DEMO.resource;
    return SDCloudBackendAPIWithToken()
      .get(Api.getProfile)
      .then(res => {
        let getProfileDetails = {}; // { labname : { ...labDetails }}
        res.data.profiles[0].permissions.forEach(item => {
          // is_scripted_demo = false implies not a sales demo.
          if (item.is_scripted_demo === isSalesDemo) {
            getProfileDetails[item.resource_name] = { ...item };
          }
        });
        return getProfileDetails;
      })
      .then(getProfileDetails => {
        this.setState(
          {
            profileDetails: getProfileDetails
          },
          //console.log("")
        );
        // return this.getCmsData(getProfileDetails);
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };
  // gets lab related data from cms db.
  // merging of cms data and backend get_profile data. data from get_profile will overwrite cms data.
  getCmsData = async (getProfileDetails = null, getResourcesDetails = null) => {
    let getResourceLabs = {};
    if (getProfileDetails && getResourcesDetails) {
      let getProfileLabs = Object.keys(getProfileDetails);
      let resourceKeys = Object.keys(getResourcesDetails);
      resourceKeys.forEach((item, index) => {
        if (
          !getProfileLabs.includes(getResourcesDetails[item].name) &&
          !getProfileLabs.includes(getResourcesDetails[item].resource_name)
        ) {
          getResourceLabs[item] = getResourcesDetails[item];
          getResourceLabs[item]["access"] = "no";
        }
        // getResourceLabs[item] = getResourcesDetails[item];
      });
    } else {
      getResourceLabs = getResourcesDetails;
    }
    getProfileDetails = { ...getProfileDetails, ...getResourceLabs };
    const getCmsDataUrl =
      this.offeringType === Offerings.LEARNING_LABS.resource
        ? Api.viewLearningLab(1000)
        : Api.adminSalesDemo;
    return CMSAPIWithToken()
      .get(getCmsDataUrl)
      .then(res => {
        let cmsData = res.data.results;
        // creating a mapping of labName to lab details for cms data.
        let cmsLabtoDetailsMap = [];
        cmsData.forEach(item => {
          cmsLabtoDetailsMap[item.identifier] = { ...item };
        });
        // Array containing combined data from getProfile/resources and cms data.
        let cmsAndProfileCombinedData = [];
        // Array of lab names derived from getProfile / resoures.
        let labNameKeysArrGetProfile = Object.keys(getProfileDetails);

        // combining data from cms and profile/resources
        for (let i = 0; i < labNameKeysArrGetProfile.length; i++) {
          if (cmsLabtoDetailsMap[labNameKeysArrGetProfile[i]]) {
            cmsAndProfileCombinedData.push({
              // data from cms { labName : { ...labDetails }}
              ...cmsLabtoDetailsMap[labNameKeysArrGetProfile[i]],
              // data from getProfile/resources { labName : { ...labDetails }}
              ...getProfileDetails[labNameKeysArrGetProfile[i]],
              // if a lab is active from getNewLearnings then add it to combined data.
              isLabActive: this.state.activeLabs.names.includes(
                labNameKeysArrGetProfile[i]
              ),
              userName: this.state.provisionedLab.userNames[
                labNameKeysArrGetProfile[i]
              ]
                ? this.state.provisionedLab.userNames[
                    labNameKeysArrGetProfile[i]
                  ]
                : this.state.activeLabs.userNames[labNameKeysArrGetProfile[i]],
              sequence: this.state.labIDToNameMap[
                cmsLabtoDetailsMap[labNameKeysArrGetProfile[i]]
                  .admin_lab_types_id
              ].sequence,
              activeLabTime: this.state.activeLabsTime[
                labNameKeysArrGetProfile[i]
              ],
              isLabProvisioned: this.state.provisionedLab.labData
                .flatMap(x => x.labName)
                .includes(labNameKeysArrGetProfile[i]),
              uuid: this.state.provisionedLab.labData.find(
                x => x.labName === labNameKeysArrGetProfile[i]
              )
                ? this.state.provisionedLab.labData.find(
                    x => x.labName === labNameKeysArrGetProfile[i]
                  ).uuid
                : null
            });
          }
        }
        // segregating data based on lab type
        const labTypetoLabMap = {}; // { primer : {...} , advance : { ... }}
        let labIDToNameMap = this.state.labIDToNameMap; // { 2 : "advance" , 3 : "primer" }
        const launchAccessList = []; // [ { {id: 2, short_title: "AAR" , type : "Learning" }]
        const noAccessList = [];
        const labProvisionedList = [];
        cmsAndProfileCombinedData.forEach(item => {
          let tries = Math.floor(
            (item.allocated_hours - item.consumed_hours) / item.labhours
          );
          let availableTries = tries < 1 ? 0 : tries;

          if (item.isLabActive && item.isLabProvisioned) {
            labProvisionedList.push(item);
          } else if (availableTries < 1) {
            noAccessList.push(item);
          } else {
            launchAccessList.push(item);
          }

          if (labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name]) {
            labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name].push(
              item
            );
          } else {
            labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name] = [
              item
            ];
          }
        });

        const allLabTypetoLabMap = labProvisionedList
          .concat(launchAccessList)
          .concat(noAccessList);
        if (cmsAndProfileCombinedData.length > 0) {
          this.setState(
            {
              // adminLabArray: labTypetoLabMap[defaultLabType],
              // currentLabType: defaultLabType,
              allLabTemplates: allLabTypetoLabMap,
              adminLabArray: allLabTypetoLabMap,
              currentLabType: templateFilters[0],
              labTypetoLabMap: labTypetoLabMap
            },
            () => {
              return;
            }
          );
        } else {
          this.setState({
            adminLabArray: [],
            currentLabType: templateFilters[0],
            allLabTemplates: [],
            labTypetoLabMap: labTypetoLabMap
          });
        }
      })
      .then(() => {
        return this.getReservedIp();
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // get reserved IP settings for user, account and offering specific
  getReservedIp = async () => {
    const isSalesDemo = this.offeringType === Offerings.SALES_DEMO.resource;
    return SDCloudBackendAPIWithToken()
      .get(Api.getUserProfile)
      .then(res => {
        // Check launch lab permission for user
        if (res.data?.can_launch === false) {
          this.setState({
            canLaunch: false
          });
        }
        let reservedIpObj = {};
        if (res.data.static_ips?.length) {
          reservedIpObj["reservedIps"] = [...res.data.static_ips];
          reservedIpObj["ipService"] = true;
        }
        return reservedIpObj;
      })
      .then(reservedIpObj => {
        let accountName = JSON.parse(localStorage.getItem("profileData"))?.account;
        return SDCloudBackendAPIWithToken()
          .get(Api.getAccountDetails,{
            params: {
                account: accountName,
            }})
          .then(res => {
            if (res.data.reserved_ip) {
              reservedIpObj["staticIp"] = res.data.reserved_ip;
            }
            if (
              isSalesDemo &&
              res.data.ip_available_services?.includes("sales_demo")
            ) {
              reservedIpObj["ipService"] = true;
            } else if (res.data.ip_available_services?.includes("learnings")) {
              reservedIpObj["ipService"] = true;
            } else {
              reservedIpObj["ipService"] = false;
            }
            return reservedIpObj;
          });
      })
      .then(reservedIpObj => {
        if (
          reservedIpObj.ipService &&
          (reservedIpObj.reservedIps || reservedIpObj.staticIp)
        ) {
          reservedIpObj["dynamicIp"] = "default";
          this.setState(
            {
              reservedIp: reservedIpObj,
              reservedIpLoaded: true
            },
            () => {
              return;
            }
          );
          this.props.dispatch(HIDE_LOADER());
        } else {
          this.setState({
            reservedIpLoaded: true
          });
        }
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  handleHeaderClick = selectedLabType => {
    switch (selectedLabType) {
      case "All":
        this.setState({
          adminLabArray: this.state.allLabTemplates,
          currentLabType: selectedLabType
        });
        break;
      case "Recent":
        this.setState({
          adminLabArray: this.state.allLabTemplates.filter(item => {
            return item.uuid !== null;
          }),
          currentLabType: selectedLabType
        });
        break;
      default:
        this.setState({
          adminLabArray: this.state.labTypetoLabMap[selectedLabType],
          currentLabType: selectedLabType
        });
    }
  };

  componentWillUnmount = () => {
    // resetting navbar to show current route name
    this.props.dispatch(SET_NAVBAR_TITLE(""));
  };

  componentDidMount = async () => {
    this.props.dispatch(SET_NAVBAR_TITLE(EXPERIENCEFILTER));
    this.props.dispatch(SHOW_LOADER());
    if (
      JSON.parse(localStorage.getItem("profileData")).privilege === "developer"
    ) {
      await Promise.all([
        this.getNewLearnings(),
        this.getAdminLabTypes(),
        this.getResources(),
        this.getProfile()
      ]);
    } else if (
      JSON.parse(localStorage.getItem("profileData")).privilege === "admin"
    ) {
      await Promise.all([
        this.getNewLearnings(),
        this.getAdminLabTypes(),
        this.getResources()
      ]);
    }

    this.getCmsData(this.state.profileDetails, this.state.resourceDetails)
      // this.getNewLearnings()
      // .catch(err => {
      //   this.props.dispatch(HIDE_LOADER());
      //   const payload = {
      //     message: "Errror in fetching labs.",
      //     color: "danger",
      //     error: err,
      //     showErrorPage: true
      //   };
      //   this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      // })
      .finally(() => {
        this.props.dispatch(HIDE_LOADER());
      });
  };

  Header = () => {
    const classes = useStyles();
    const labType = new Set();
    let orderedLabTypes = [];
    if (
      Object.keys(this.state.labTypetoLabMap).length > 0 &&
      this.state.reservedIpLoaded
    ) {
      Object.entries(this.state.labTypetoLabMap).map(([key, value]) => {
        orderedLabTypes.push({ title: key, sequence: value[0].sequence });
      });
      orderedLabTypes.sort((a, b) => {
        if (a.sequence > b.sequence) return 1;
        if (a.sequence < b.sequence) return -1;
        return 0;
      });
      orderedLabTypes.forEach(item => {
        labType.add(item.title);
      });
      let templateFilter = Array.from(templateFilters).map(item => {
        return (
          <GridItem xs={12} key={uuidv4()}>
            <h4
              className={classes.labType}
              onClick={() => this.handleHeaderClick(item)}
              style={
                this.state.currentLabType.toLowerCase() === item.toLowerCase()
                  ? selectedlabTypeStyle
                  : {}
              }
            >
              {item}
            </h4>
          </GridItem>
        );
      });
      return (
        <GridContainer justify="flex-start">
          {templateFilter}
          <GridItem xs={12}>
            <Divider
              variant="inset"
              style={{
                height: "1px",
                background: "#E6E6E6",
                margin: "15px 30px 20px"
              }}
            />
          </GridItem>
          {Array.from(labType).map(item => {
            return (
              <GridItem xs={12} key={uuidv4()}>
                <h4
                  className={classes.labType}
                  onClick={() => this.handleHeaderClick(item)}
                  style={
                    this.state.currentLabType.toLowerCase() ===
                    item.toLowerCase()
                      ? selectedlabTypeStyle
                      : {}
                  }
                >
                  {item}
                </h4>
              </GridItem>
            );
          })}
        </GridContainer>
      );
    } else {
      return null;
    }
  };
  render() {
    const { classes } = this.props;
    if (this.state.allLabTemplates === undefined) {
      return null;
    }
    if (this.state.allLabTemplates.length === 0) {
      return (
        <GridContainer alignItems="flex-start" justify="flex-start">
          <p> No labs subscribed! </p>
        </GridContainer>
      );
    }

    if (this.state.reservedIpLoaded) {
      return (
        <div className="launch-demo">
          <GridContainer>
            <GridItem sm={12} md={12}>
              <span
                className="go-back"
                onClick={() => this.props.history.goBack()}
              >
                <img src={backIcon} alt="back" /> Back
              </span>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <h1 className={classes.launchLabTtile}>Learning Labs</h1>
            </GridItem>
            <GridItem
              xs={12}
              sm={3}
              md={3}
              lg={3}
              style={{ marginTop: "55px" }}
            >
              <this.Header />
            </GridItem>
            <GridItem
              xs={12}
              sm={9}
              md={9}
              lg={9}
              style={{ marginTop: "50px" }}
            >
              {this.state.adminLabArray.map(lab => {
                const labProps = { ...lab };
                return (
                  <LaunchNewOfferingCard
                    labData={{
                      ...labProps,
                      launchLabBtnHandler: this.launchLabBtnHandler,
                      accessLabBtnHandler: this.accessLabBtnHandler,
                      reservedIp: this.state.reservedIp,
                      canLaunch: this.state.canLaunch,
                      offeringType:
                        this.props.offeringType || Offerings.LEARNING_LABS
                    }}
                    notes={notes.reservedIpNote}
                    key={uuidv4()}
                    id={lab.identifier}
                  />
                );
              })}
            </GridItem>
          </GridContainer>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profileObj
  };
};

// const dispatchToProps =
const LaunchLabsHoc = withRouter(withStyles(customStyles)(LaunchLabs));
export default connect(mapStateToProps)(LaunchLabsHoc);
