import React, { useEffect, useState,useRef } from "react";
import { useDispatch } from "react-redux";
import imageMapResize from "image-map-resizer";
import ReactMarkdown from "react-markdown";
import { useHistory, useParams } from "react-router-dom";
import { Api, Urls } from "config/api";
// Material UI Components
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Typography from "views/Components/Typography";
import {SHOW_NOTIFICATION} from "action_creators/actioncreator";
import { getDesignerObjects } from "../../../../../apis/APIServices";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert, AlertTitle } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
  rootAlert: {
    width: '60%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      float: 'left',
    },
  },
  root: {
    padding: "0 20px",
    backgroundColor: "#ffffff",
  },
  appBar: {
    backgroundColor: "#ffffff !important",
    color: theme.palette.grey["600"],
    boxShadow: "none",
  },
  topologyImage: {
    width: "100%",
    "& img": {
      maxWidth: "100%",
    },
  },
  tabPanel: {
    backgroundColor: "#ffffff",
    "& div": {
      width: "100%",
      margin: "0",
      paddingTop: "15px",
    },
  },
  ipDetailAccess: {
    fontFamily: theme.palette.fontList.selectedFont,
    width: "100%",
    paddingTop: "30px",
    "& table": {
      width: "100%",
      "& thead": {
        textAlign: "left",
        "& tr": {
          borderBottom: "1px solid black",
        },
        "& th": {
          height: "40px",
          color: theme.palette.grey["500"],
        },
      },
      "& tbody": {
        textAlign: "left",
        "& td": {
          height: "40px",
          color: theme.palette.grey["900"],
        },
      },
    },
  },
  interactiveTopologyButton: {
    marginTop: "20px",
  },
  btnColor: {
    background: `${theme.palette.buttonColor.main} !important`,
    "&:hover": {
      background: `${theme.palette.buttonColor.main} !important`,
    }
  },
  customTabRoot: {
    color: `#000`,
    "& .Mui-selected .MuiTab-wrapper": {
      color: `${theme.palette.secondary.main}`
    },
    "& .MuiTab-wrapper": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont,
  },
  buttonStyle1: {
    backgroundColor: theme.palette.secondary.main,
    float:"left",
    color: "#fff",
    marginTop: "20px",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonStyle2: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    float:"right",
    marginTop: "20px",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

//user confirmation model
const ConfirmationModal = ({ open, handleClose, handleConfirm }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{textAlign:'center'}}>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{color:"black"}}>
        Would you like to proceed with moving this sandbox to the designer?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} 
        className={classes.interactiveTopologyButton + " " + classes.btnColor}variant="contained"  disableElevation disableFocusRipple style={{color: "#fff"}}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} className={classes.interactiveTopologyButton + " " + classes.btnColor} variant="contained" style={{color: "#fff"}} disableElevation disableFocusRipple>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function SandboxAccessDevices(props) {
  const [value, setValue] = useState(0);
  const params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const errorRef = useRef(null);
  var profileData = JSON.parse(localStorage.getItem("profileData"));
	var previlege = profileData.privilege;


  useEffect(() => {
    imageMapResize();
    localStorage.removeItem("design_objects");
    getDesignObjects();
  });

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePrev = () => {
    if (value === 0) {
      if(props.sandboxCheatsheets && props.sandboxCheatsheets.length > 0){
        const sortedCheatsheets = props.sandboxCheatsheets.slice().sort((a, b) => a.cheatsheet_position - b.cheatsheet_position);
        props.changePrevActiveTab("Cheat sheets", sortedCheatsheets.length - 1);
      } else if (props.badgeInfo) {
        props.changeActiveTab("Skill Badge");
      } else if (props.categoriesData && props.categoriesData[0].length > 0) {
      const lastCategoryIndex = props.categoriesData[0].length - 1;
      const lastCategoryName = props.categoriesData[0][lastCategoryIndex].sandboxes_category_name;
      const exercisesForSelectedCategory = props.exercisesData[0].filter(
        (exercise) => exercise.admin_sandboxes_categories_id === props.categoriesData[0][lastCategoryIndex].id
      );
      const sortedExercisesData = exercisesForSelectedCategory.slice().sort((a, b) => a.exercise_position - b.exercise_position); 
      props.changePrevActiveTab(lastCategoryName,sortedExercisesData.length-1,lastCategoryIndex);
      } else {
        props.changeActiveTab("Overview")
      }
    } else {
      setValue((prevValue) => Math.max(prevValue - 1, 0));
    }
  };
  const handleNext = () => {
    if (value === 0) {
      setValue(1); 
    } else {
      setValue((prevValue) => Math.min(prevValue + 1, 1));
    }
  };
  

  const handleOpenConfirmation = async() => {
    const response = await SDCloudBackendAPIWithToken().post(
    Api.refreshToken,
      {
        refresh: localStorage.getItem("refreshToken"),
    }
    );
    if (response.status ==200){
      let newToken = response.data.access;
      localStorage.setItem("user", newToken);
      setIsConfirmationOpen(true);
    }else{
      const payload = {
        message: response?.data?.message || "Session Expired",
        color: "danger",
        error: "error occured"
      };
      dispatch(SHOW_NOTIFICATION({ payload }))
      setTimeout(function () {
        history.push("/auth/login-page");
      }, 2000);
      return
    }
    
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };
  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMsg(message);

    // Close the alert after 5 seconds
    setTimeout(() => {
      setAlertType('');
      setAlertMsg('');
    }, 5000);
  };

  const getDesignObjects = async () => {
    const designObject = localStorage.getItem("design_objects");
    if (!designObject) {
      const response = await getDesignerObjects();
      if (response.success) {
        localStorage.setItem("design_objects", JSON.stringify(response.data));
      }
    }
  };

  const handleConfirmAction = async() => {

    let data = {...params,"action":"liveedit"}
    try {
      const response = await SDCloudBackendAPIWithToken().post(
        Api.createLiveeditTemplate,
        data
      );
      if (!localStorage.getItem("BASE_URL")) {
          const BASE_URL = window.REACT_APP_API_BASE_URL;
          localStorage.setItem("BASE_URL", BASE_URL);
          
      }
       if (response.status == 201){
        showAlert('success',response.data.msg)
        window.scrollTo(0, document.body.scrollHeight);
        const uuid_val = response.data.uuid;
        const redirectUrl = `${window.location.origin}/design_center/?uuid=${uuid_val}`;
        const timeoutId = setTimeout(() => {
          window.open(redirectUrl, '_blank');
          setIsConfirmationOpen(false)
        }, 1000);
    
        return () => clearTimeout(timeoutId);
       }else if (response.status == 409){
        showAlert('error',response.data.msg)
        window.scrollTo(0, document.body.scrollHeight);
       }else{
        showAlert('success',response.data.msg)
        window.scrollTo(0, document.body.scrollHeight);
       }  
    
    } catch (error) {
      if (error.response.status == 409){
        showAlert('error',error.response.data.msg)
        window.scrollTo(0, document.body.scrollHeight);
      }else{
      setAlertType('info')
      setAlertMsg(error.response.data.msg)
      window.scrollTo(0, document.body.scrollHeight);

      }

    }

    handleCloseConfirmation();
  };

  const handleInteractiveTopology = () => {
    // history.push(`/design_center/interactive_topo.html?uuid=${params.uuid}`);
    // window.location.href = `/design_center/interactive_topo.html?uuid=${params.uuid}`;
    if (
        localStorage.getItem("BASE_URL") == null ||
        localStorage.getItem("BASE_URL") == undefined
    ) {
      const BASE_URL = window.REACT_APP_API_BASE_URL;
      localStorage.setItem("BASE_URL", BASE_URL);
    }
    window.open(
      `/design_center/interactive_topo.html?uuid=${params.uuid}`,
      "_blank"
    );
  };

  const renderAccessDevicesImage = () => {
    let topo_svg = props.clusterDetails.topo_svg;
    let source1 = `${props.data.access_device}`;
    if (topo_svg != "") {
      return (
        <>
        
          <ReactMarkdown
            source={source1}
            escapeHtml={false}
            className={classes.topologyImage}
          />
          <div>
            <p className={classes.font}>Please click on the below button to open Interactive topology</p>
          </div>
          <div>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              size="large"
              disableFocusRipple
              type="button"
              className={classes.interactiveTopologyButton + " " + classes.btnColor}
              onClick={handleInteractiveTopology}
              disabled={
                props.clusterDetails.status !== "ready" ? true : false
              }
            >
              Open Interactive Topology
            </Button>
          </div>
          
         
          { previlege == 'developer' &&
          <>
          <div>
          <p className={classes.font}>Please click on the below button to open Live Edit topology</p>
        </div>
          <div>
            <div>
              <Button 
              variant="contained" 
              color="primary" 
              onClick={handleOpenConfirmation}
              disableElevation
              disableFocusRipple
              disabled={
                props.clusterDetails.status !== "ready" ? true : false
              }
              >
              Live Edit Topology
              </Button>
              <ConfirmationModal
                open={isConfirmationOpen}
                handleClose={handleCloseConfirmation}
                handleConfirm={handleConfirmAction}
              />

            </div>
          </div>
          </>}
        </>
        
      );
    }

    let ip = props.clusterDetails.ipdetails;
    let source = `${props.data.access_device}`;

    if (
      ip &&
      Object.keys(ip).length !== 0 &&
      ip.constructor === Object &&
      props.clusterDetails.status === "ready"
    ) {
      if (ip.hasOwnProperty(`${params.uuid}-custom-underlay1`)) {
        // if (ip.includes("custom-underlay1")) {
        //   ip = ip[`${params.uuid}-custom-underlay1`];
        //   source = source.replaceAll("custom-underlay1", ip);
        // }

        ip = ip[`${params.uuid}-custom-underlay1`];
        source = source.replaceAll("custom-underlay1", ip);

        return (
          <ReactMarkdown
            source={source}
            escapeHtml={false}
            className={classes.topologyImage}
          />
        );
      } else if (ip.hasOwnProperty(`${params.uuid}-viptela-underlay1`)) {
        ip = ip[`${params.uuid}-viptela-underlay1`];
        source = source.replaceAll("viptela-underlay1", ip);

        return (
          <ReactMarkdown
            source={source}
            escapeHtml={false}
            className={classes.topologyImage}
          />
        );
      }
    } else {
      return (
        <ReactMarkdown
          source={source}
          escapeHtml={false}
          className={classes.topologyImage}
        />
      );
    }
  };

  const renderIPDetails = () => {
    let ip = props.clusterDetails.ipdetails;
    let source = `${props.data.ip_details_access}`;
    if (
      ip &&
      Object.keys(ip).length !== 0 &&
      ip.constructor === Object &&
      props.clusterDetails.status === "ready"
    ) {
      if (ip.hasOwnProperty(`${params.uuid}-custom-underlay1`)) {
        ip = ip[`${params.uuid}-custom-underlay1`];
        source = source.replaceAll("custom-underlay1", ip);
        return (
          <ReactMarkdown
            source={source}
            escapeHtml={false}
            className={classes.ipDetailAccess}
          />
        );
      } else if (ip.hasOwnProperty(`${params.uuid}-viptela-underlay1`)) {
        ip = ip[`${params.uuid}-viptela-underlay1`];
        source = source.replaceAll("viptela-underlay1", ip);

        return (
          <ReactMarkdown
            source={source}
            escapeHtml={false}
            className={classes.ipDetailAccess}
          />
        );
      }
    } else {
      return (
        <ReactMarkdown
          source={source}
          escapeHtml={false}
          className={classes.ipDetailAccess}
        />
      );
    }
  };

  if (!props.data.access_device) return null;

  return (
    <div className={classes.root}>
      
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            root: classes.customTabRoot
          }}
        >
          <Tab label="Topology" {...a11yProps(0)} />
          <Tab label="IP Details" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ flexDirection: "row", flexWrap: "wrap" }}
        >
          {renderAccessDevicesImage()}
        </Grid>
        <Button
              onClick={handlePrev}
              className={classes.buttonStyle1}
            >
              Prev
            </Button>
        <Button
            onClick={handleNext}
              className={classes.buttonStyle2}
            >
              Next
            </Button>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ flexDirection: "row", flexWrap: "wrap" }}
        >
          {renderIPDetails()}
        </Grid>
        <Button
              onClick={handlePrev}
              className={classes.buttonStyle1}
            >
              Prev
            </Button>
      </TabPanel>
      <div className={classes.rootAlert} ref={errorRef}>
      {alertType == 'error' && <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {alertMsg} 
      </Alert>}
     {alertType == 'info' && <Alert severity="error">
        <AlertTitle>Info</AlertTitle>
        {alertMsg}
      </Alert>}

      {alertType == 'success' && <Alert severity="success" style={{float:'right'}}>
        <AlertTitle>Success</AlertTitle>
        {alertMsg}
      </Alert>}
    </div>
    </div>
  );
}

export default SandboxAccessDevices;