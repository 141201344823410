import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {v4 as uuidv4} from "uuid";
import {DropzoneArea} from 'material-ui-dropzone';


// Material UI Components
import {Alert} from "@material-ui/lab";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

// Icons
import Visibility from "@material-ui/icons/Visibility";

// Local
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import {SDCloudBackendAPIWithToken, SDCloudCustomizeAPIWithToken} from "../../../apis/backendAPI";
import {IconButton} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {Api} from "../../../config/api";
import {HIDE_LOADER, SHOW_LOADER} from "../../../action_creators/actioncreator";
import {useDispatch} from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CustomTable from "../../Usermanagement/components/Table";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import axios from "axios";
import DesignWizardTable from "./DesignWizardTable";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import AttachmentIcon from "@material-ui/icons/Attachment";
import SvgtoIcon from "../../../utils/SvgtoIcon";
import {ReactComponent as deleteSvg} from "../../../assets/criterion_networks/svg/delete.svg";

// Styling
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "50px",
    },
    item: {
        textAlign: "end",
    },
    button: {
        background: `${theme.palette.buttonColor.main} !important`,
        height: "40px",
        color: "white",
        border: "none",
        width: "200px",
        fontSize: "0.875rem",
        fontFamily: `${theme.palette.fontList.selectedFont} !important`,
        cursor: "pointer",
    },
    filter: {
        marginTop: "2%",
        padding: "20px 0px 50px 50px",
        background: "#FFFFFF",
        border: "1px solid #F0F0F0",
        boxSizing: "border-box",
        boxShadow: "0px 4px 12px rgb(0 0 0 / 4%)",
        position: "relative",
        width: "80%"
    },
    buttongrid:{
        marginTop: "20px"
    },
    btnStyle: {
        background: `#FFFFFF`,
        height: "40px",
        width: "200px",
        fontSize: "0.875rem",
        fontFamily: `${theme.palette.fontList.selectedFont} !important`,
        cursor: "pointer",
    },
    filterButton: {
        padding: "8px 50px 0px 0px",
    },
    icon: {
        marginTop: "30px",
        // paddingLeft: "80px",
        // [theme.breakpoints.down("md")]: {
        //   marginTop: "26px",
        //   paddingLeft: "40px",
        // },
    },
}));

const DesignWizard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // States
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const [notificationType, setNotificationType] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [menuItems, setMenuItems] = useState([]);
    const [rows, setRows] = useState([]);


    // useEffects
    useEffect(() => {
        renderData();
    }, []);
var wizards
    const handleDeleteButton = (name) => {
        if (window.confirm("Are you sure you want to delete " + name + " wizard?")) {
            delete (wizards[name])
            wizards = btoa(JSON.stringify(wizards))
            let data = {
                wizards: (wizards)
            }
            SDCloudBackendAPIWithToken().post(Api.updateWizards, data).then(raw_response => {
                if (raw_response.status == 200) {
                    setShowNotification(true)
                    setNotificationType("success");
                    setNotificationMessage("Wizard Deleted Successfully");
                    renderData()
                } else {
                    setShowNotification(true)
                    setNotificationType("error");
                    setNotificationMessage("Wizard Deletion Failed");
                }
            })
        }
    };
    const columns = [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Action",
            accessor: "action"
        }
    ];
    const renderData = async () => {
        dispatch(SHOW_LOADER());
        let data = [];
        const accountName = JSON.parse(localStorage.getItem("profileData"))?.account;
        var getwizards =await SDCloudBackendAPIWithToken().get(Api.getWizardsXML,{params: { account: accountName }})
        {
            wizards = getwizards.data.wizards
            for (let item in getwizards.data.wizards) {
                data.push({
                    name: item,
                    action: (
                        <div>
                            <Grid container wrap="wrap" spacing={1}>
                                <Grid item className="delete-icon">
                                    <IconButton
                                        onClick={() => handleDeleteButton(item)}
                                        title="Delete"
                                    >
                                        <SvgtoIcon svgSource={deleteSvg} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </div>
                    )
                })
            }
            setRows(data);
        }
        dispatch(HIDE_LOADER());
    };

    return (
        <div>
            <Grid container>
                {showNotification ? (
                    <Alert
                        onClose={() => setShowNotification(false)}
                        severity={notificationType}
                    >
                        {notificationMessage}
                    </Alert>
                ) : null}
                <Grid container className={classes.filter}>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                            <DesignWizardTable
                                columns={columns}
                                data={rows}
                                tableTitle="Wizards"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default DesignWizard;
