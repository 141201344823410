import React, { useEffect, useState , Fragment} from "react";

// Formik
import { useFormik } from "formik";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormControl, TextField, TextareaAutosize } from "@material-ui/core";
import '../../Accounts/accounts.css'
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import Switch from "@material-ui/core/Switch";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment-timezone";

import { MenuProps , options } from "./utils";
import { Details } from "@material-ui/icons";

const inputProps = {
    disableAnimation: true,
    shrink: false,
  };
// Styling
const useStyles = makeStyles((theme, styles) => ({
    root: {
        textAlign: "end"
    },
    labelRoot: {
        fontSize: "14px",
        padding: "40px 10px 0px 0px"
    },
    indeterminateColor: {
        color: "#f50057"
      },
      selectAllText: {
        fontWeight: 500
      },
      selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
      }
}));

const AddInventory = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const inv_type = props.inv_type;
    const [clusterTemplatesList, setClusterTemplatesList] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationType, setNotificationType] = useState("");
    const [clusterTemplate, setClusterTemplate] = useState("");
    const [tags, setTags] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [inventoryState, setInventoryState] = useState([]);
    const [inventoryStatesList, setInventoryStatesList] = useState([]);
    const [inventoryEnvironmentList, setInventoryEnvironmentList]= useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const isAllSelected = accounts.length > 0 && selectedAccounts.length === accounts.length;
    const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
        setSelectedAccounts(selectedAccounts.length === accounts.length ? [] : accounts);
        return;
    }
    setSelectedAccounts(value);
    };

    const [selectedTags, setSelectedTags] = useState([]);
    const isAllSelectedTags = tags.length > 0 && selectedTags.length === tags.length;
    const handleTagChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
        setSelectedTags(selectedTags.length === tags.length ? [] : tags);
        return;
    }
    setSelectedTags(value);
    };
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
          sku_id: "",
          sku_name: "",
          accounts: [],
          display_name: "",
          sub_category: "",
          sub_categories:"",
          inventory_type: inv_type,
          image_link:"",
          icon_url:"",
          image_version:"",
          owner_name:"",
          owner_email:"",
          inventory_state:"",
          inventory_env:"",
          tags:[],
          cost_per_unit:"",
          comments:"",
          commissioned_date:"",
          decommission_date:"",
          cluster_template:"",
          resource_allocation_type:"",
          cloud_provider:"",
          vcpu:"",
          memory:"",
          storage:"",
          max_nodes:"",
          max_int:"",
          prop_manual:"",
          prop_external:"",
          automated_l1:"",
          automated_l2:"",
          erb_template:"",
          //is_active: ""
          boot_time:"",
          consumed_cpu:"",
          consumed_ram:"",
          consumed_disk:"",
          is_badge_available: false
        },
        onSubmit: (values) => {
          let payload = {
            sku_id: values.sku_id,
            sku_name: values.sku_name,
            accounts: selectedAccounts,
            display_name: values.display_name,
            inventory_type: values.inventory_type,
            image_link:values.image_link,
            icon_url:values.icon_url,
            image_version:values.image_version,
            owner_name:values.owner_name,
            owner_email:values.owner_email,
            inventory_state:values.inventory_state,
            inventory_env:values.inventory_env,
            tags:selectedTags,
            sub_category:values.sub_category,
            cost_per_unit:values.cost_per_unit,
            comments:values.comments,
            commissioned_date:values.commissioned_date,
            decommission_date:values.decommission_date,
            cluster_template:clusterTemplate,
            resource_allocation_type: values.resource_allocation_type,
            cloud_provider:values.cloud_provider,
            vcpu:values.vcpu,
            memory:values.memory,
            storage:values.storage,
            max_nodes:values.max_nodes,
            max_int:values.max_int,
            prop_manual:values.prop_manual,
            prop_external:values.prop_external,
            automated_l1:values.automated_l1,
            automated_l2:values.automated_l2,
            erb_template:values.erb_template,
            //is_active:values.is_active,
            boot_time: values.boot_time,
            consumed_cpu: values.consumed_cpu,
            consumed_ram: values.consumed_ram,
            consumed_disk: values.consumed_disk,
            is_badge_available: values.is_badge_available
          };
    
          SDCloudBackendAPIWithToken()
            .post("/inventory/add_new_inventory", payload)
            .then((resp) => resp.data)
            .then((data) => {
                const payload = {
                    message: "Inventory Created Successfully",
                    color: "success",
                };
                payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
                history.push("/inventorymanagement?"+values.inventory_type);
                window.location.reload()
            })
            .catch((err) => {
              let message = err.response.data.message;
              const payload = {
                message: message,
                color: "danger",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
            });
        },
        
        validate: (values) => {
          let errors = {};
          if (!values.sku_id) {
            errors.sku_id = "Required";
          }
          if (!values.sku_name) {
            errors.sku_name = "Required";
          }
          if(!selectedAccounts){
            errors.selectedAccounts = "Required";
          }
          // for bug CN-9989
            //   if(values.sku_name.length > 45){
            //     errors.sku_name = "SKU Name should be of 45 characters";
            //   }
          if (!values.display_name) {
            errors.display_name = "Required";
          }
          if (!values.inventory_type) {
            errors.inventory_type = "Required";
          }
          if (!values.image_link) {
            errors.image_link = "Required";
          }
          if (!values.image_version) {
            errors.image_version = "Required";
          }
          if (!values.owner_name) {
            errors.owner_name = "Required";
          }
          if (!values.owner_email) {
            errors.owner_email = "Required";
          }
          if (!values.inventory_state) {
            errors.inventory_state = "Required";
          }
          if (!values.inventory_env) {
            errors.inventory_env = "Required";
          }
          if (!values.cost_per_unit) {
            errors.cost_per_unit = "Required";
          }
        //   if (!values.comments) {
        //     errors.comments = "Required";
        //   }
          if (!values.resource_allocation_type) {
            errors.resource_allocation_type = "Required";
          }
          if(!values.commissioned_date){
            errors.commissioned_date = "Required";
          }
          if(!values.decommission_date){
            errors.decommission_date = "Required";
          }
          if (!values.cloud_provider) {
            errors.cloud_provider = "Required";
          }
          if (!values.vcpu) {
            errors.vcpu = "Required";
          }
          if (!values.memory) {
            errors.memory = "Required";
          }
          if (!values.storage) {
            errors.storage = "Required";
          }
          return errors;
        },
      });
    const renderTemplateDropdown = async () => {
        const profileItems = SDCloudBackendAPIWithToken()
            .get("/inventory/get_available_templates")
            .then((resp) => resp.data)
            .then((data) => {
            let templates = data[inv_type];

            let items = templates.map((item) => {
                return item.name;
            });

            setClusterTemplatesList(items);
        }); 
    };

    const fetchAccTagsCategories = async () => {
        let payload = {'inv_type':inv_type}
        SDCloudBackendAPIWithToken()
            .post("/inventory/get_accounts_tags_categories", payload)
            .then((resp) => resp.data)
            .then((data) => {
            let details = data['details'];
            setAccounts(details['accounts'])
            setTags(details['tags'])
            setSubCategories(details['sub_categories'])
        });   
    };

    const fetchTemplateDetails = async (selectedTemplate) => {
        let payload = {'template': selectedTemplate, 'inv_type':inv_type}
        SDCloudBackendAPIWithToken()
            .post("/inventory/get_template_details", payload)
            .then((resp) => resp.data)
            .then((data) => {
            let template_details = data.template_details;
            for(const prop in template_details){
                formik.setFieldValue(prop,template_details[prop])
                if(prop == "accounts"){
                    setAccounts(template_details[prop])
                }
                if(prop == "tags"){
                    setTags(template_details[prop])
                }
            }
        });      
    };
    useEffect(() => {
        if(inv_type !== 'Designer'){
            renderTemplateDropdown(); 
        }else{
            fetchAccTagsCategories();
        }
        const inv_states= SDCloudBackendAPIWithToken()
            .get("/inventory/get_inventory_states")
            .then((resp) => resp.data)
            .then((data) => {
            let states = data['inventory_states'];
            setInventoryStatesList(states);
        });
        const inv_env= SDCloudBackendAPIWithToken()
            .get("/inventory/get_inventory_env")
            .then((resp) => resp.data)
            .then((data) => {
            let envs = data['inventory_envs'];
            setInventoryEnvironmentList(envs);
        });     
    }, []);
    
    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                
                    <div className="inventoryedit-card">
                        <Grid container className='box-inner-height mt-2'>
                        <Grid item xs={12} sm={12} md={12}>
                            {inv_type !== 'Designer' ? (
                              <><h3 className="createaccount-widget-title">Select Cluster Template</h3><Grid item xs={12} sm={8} md={8}>
                                {formik.errors.cluster_template && formik.touched.cluster_template ? (
                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        className={classes.formControl}

                                    >
                                        <InputLabel id="select_cluster_template">Select Cluster Template</InputLabel>
                                        <Autocomplete
                                            openOnFocus
                                            label="Select Cluster Template"
                                            id="select_cluster_template"
                                            options={clusterTemplatesList}
                                            value={clusterTemplate}
                                            onChange={(event, newValue) => {
                                                setClusterTemplate(newValue);
                                                fetchTemplateDetails(newValue);
                                            } }
                                            helperText={formik.errors.training_name}
                                            error={formik.errors.training_name && formik.touched.training_name
                                                ? true
                                                : false}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                type="text"
                                                variant="filled"
                                                fullWidth />} />
                                    </FormControl>
                                ) : (
                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            openOnFocus
                                            id="select_cluster_template"
                                            options={clusterTemplatesList}
                                            value={clusterTemplate}
                                            onChange={(event, newValue) => {
                                                setClusterTemplate(newValue);
                                                fetchTemplateDetails(newValue);

                                            } }
                                            renderInput={(params) => <TextField
                                                {...params}
                                                type="text"
                                                variant="filled"
                                                fullWidth />} />
                                    </FormControl>
                                )}
                            </Grid></>):(<div></div>)}
                          
                            <h3 className="createaccount-widget-title">General Information</h3>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                            
                            </Grid>
                 
                            <Grid item xs={12} sm={4} md={4}>
                            {formik.errors.sku_id && formik.touched.sku_id ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="SKU ID"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="sku_id"
                                        value={formik.values.sku_id}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.sku_id}
                                        error={
                                            formik.errors.sku_id && formik.touched.sku_id
                                            ? true
                                            : false
                                        }
                                    // value={""}
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="SKU ID"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="sku_id"
                                        value={formik.values.sku_id}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        disabled
                                    // value={""}
                                    />
                                </FormControl>
                            )}
                            </Grid>

                            <Grid item xs={12} sm={4} md={4}>
                            {formik.errors.sku_name && formik.touched.sku_name ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="SKU Name"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="sku_name"
                                        value={formik.values.sku_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.sku_name}
                                        error={
                                            formik.errors.sku_name && formik.touched.sku_name
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ):(
                                <FormControl
                                fullWidth
                                className={classes.customInputStyle}
                            >
                                <TextField
                                    style={{ paddingRight: "50px", margin: 0 }}
                                    rows={1}
                                    label="SKU Name"
                                    InputLabelProps={{ disableAnimation: true, shrink: false }}
                                    InputProps={{ disableUnderline: true }}
                                    id="sku_name"
                                    value={formik.values.sku_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="filled"
                                // value={""}
                                />
                            </FormControl>
                            )}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                            {formik.errors.display_name && formik.touched.display_name ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label={inv_type !== "Designer"?"Display Name":"Display Name Category"}
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="display_name"
                                        value={formik.values.display_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.display_name}
                                        error={
                                            formik.errors.display_name && formik.touched.display_name
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label={inv_type !== "Designer"?"Display Name":"Display Name Category"}
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="display_name"
                                        value={formik.values.display_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            {inv_type == 'Designer' ? (
                            <Grid item xs={12} sm={4} md={4} style={{width: "82%"}}>
                            {formik.errors.sub_categories && formik.touched.sub_categories ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                    variant="filled"
                                    style={{ marginTop: "10", marginRight: "5px", width:"83%" }}
                                >
                                    <InputLabel id="sub_category">
                                        Sub Category
                                    </InputLabel>
                                    <Select
                                        labelId="sub_category"
                                        id="sub_category"
                                        labelWidth={100}
                                        style={{width:"82%"}}
                                        name="sub_category"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        native
                                        variant="filled"
                                        helperText={formik.errors.sub_category}
                                        error={
                                            formik.errors.sub_category && formik.touched.sub_category
                                            ? true
                                            : false
                                        }
                                    >
                                     <option value="">Select Sub Category</option>
                                        {subCategories.map(value =>  
                                            <option value={value}>{value}</option> 
                                        )}   
                                    </Select> 
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                    variant="filled"
                                    style={{ marginTop: "10", marginRight: "25px" , width:"83%"}}
                                >
                                    <InputLabel id="sub_category">
                                        Sub Category
                                    </InputLabel>
                                    <Select
                                        labelId="sub_category"
                                        id="sub_category"
                                        labelWidth={100}
                                        fullWidth
                                        name="sub_category"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        native
                                        variant="filled"
                                    >
                                        <option value="">Select Sub Category</option>
                                        {subCategories.map(value =>  
                                            <option value={value}>{value}</option> 
                                        )}
                                    </Select> 
                                </FormControl>
                            )}
                            </Grid> 
                             ):(<div></div>)}
                            <Grid item xs={12} sm={4} md={4}>
                            {formik.errors.inventory_type && formik.touched.inventory_type ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Inventory Type"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="inventory_type"
                                        value={formik.values.inventory_type}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.inventory_type}
                                        error={
                                            formik.errors.inventory_type && formik.touched.inventory_type
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Inventory Type"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="inventory_type"
                                        value={formik.values.inventory_type}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            )}
                            </Grid>
                      
                            <Grid item xs={12} sm={4} md={4}>
                            {formik.errors.cost_per_unit && formik.touched.cost_per_unit ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Cost Per Unit"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="cost_per_unit"
                                        value={formik.values.cost_per_unit}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.cost_per_unit}
                                        error={
                                            formik.errors.cost_per_unit && formik.touched.cost_per_unit
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Cost Per Unit"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="cost_per_unit"
                                        value={formik.values.cost_per_unit}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            )}
                            </Grid>
                           
                            
                            <Grid item xs={12} sm={4} md={4}>
                            {formik.errors.owner_name && formik.touched.owner_name ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Owner Name"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="owner_name"
                                        value={formik.values.owner_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.owner_name}
                                        error={
                                            formik.errors.owner_name && formik.touched.owner_name
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Owner Name"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="owner_name"
                                        value={formik.values.owner_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                            {formik.errors.owner_email && formik.touched.owner_email ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Owner Email"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="owner_email"
                                        value={formik.values.owner_email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.owner_email}
                                        error={
                                            formik.errors.owner_email && formik.touched.owner_email
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Owner Email"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="owner_email"
                                        value={formik.values.owner_email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            {inv_type !== 'Designer' ? (
                            <Grid item xs={12} sm={4} md={4}>
                            {formik.errors.comments && formik.touched.comments ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Comments"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="comments"
                                        value={formik.values.comments}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.comments}
                                        error={
                                            formik.errors.comments && formik.touched.comments
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="comments"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="comments"
                                        value={formik.values.comments}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    />
                                </FormControl>
                            )}
                            </Grid>):(<div></div>)}
                            <Grid item xs={12} sm={4} md={4} style={{width: "82%"}}>
                            {formik.errors.inventory_state && formik.touched.inventory_state ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                    variant="filled"
                                    style={{ marginTop: "10", marginRight: "5px", width:"82%" }}
                                >
                                    <InputLabel id="inv-state">
                                        Inventory State
                                    </InputLabel>
                                    <Select
                                        labelId="inv-state"
                                        id="inventory_state"
                                        labelWidth={100}
                                        style={{width:"82%"}}
                                        name="inventory_state"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        native
                                        variant="filled"
                                        helperText={formik.errors.inventory_state}
                                        error={
                                            formik.errors.inventory_state && formik.touched.inventory_state
                                            ? true
                                            : false
                                        }
                                    >
                                     <option value="">Select Inventory State</option>
                                        {inventoryStatesList.map(value =>  
                                            <option value={value}>{value}</option> 
                                        )}   
                                    </Select> 
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                    variant="filled"
                                    style={{ marginTop: "10", marginRight: "25px" , width:"83%"}}
                                >
                                    <InputLabel id="inv-state">
                                        Inventory State
                                    </InputLabel>
                                    <Select
                                        labelId="inv-state"
                                        id="inventory_state"
                                        labelWidth={100}
                                        fullWidth
                                        name="inventory_state"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        native
                                        variant="filled"
                                    >
                                        <option value="">Select Inventory State</option>
                                        {inventoryStatesList.map(value =>  
                                            <option value={value}>{value}</option> 
                                        )}
                                    </Select> 
                                </FormControl>
                            )}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} style={{width: "82%"}}>
                            {formik.errors.inventory_env && formik.touched.inventory_env ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                    variant="filled"
                                    style={{ marginTop: "10", marginRight: "5px", width:"82%" }}
                                >
                                    <InputLabel id="inv-env">
                                        Inventory Environment
                                    </InputLabel>
                                    <Select
                                        labelId="inv-env"
                                        id="inventory_env"
                                        labelWidth={100}
                                        style={{width:"82%"}}
                                        name="inventory_env"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        native
                                        variant="filled"
                                        helperText={formik.errors.inventory_env}
                                        error={
                                            formik.errors.inventory_env && formik.touched.inventory_env
                                            ? true
                                            : false
                                        }
                                    >
                                     <option value="">Select Inventory Environment</option>
                                        {inventoryEnvironmentList.map(value =>  
                                            <option value={value}>{value}</option> 
                                        )}   
                                    </Select> 
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                    variant="filled"
                                    style={{ marginTop: "11%", marginRight: "25px" , width:"95%"}}
                                >
                                    <InputLabel id="inv-env">
                                        Inventory Environment
                                    </InputLabel>
                                    <Select
                                        labelId="inv-env"
                                        id="inventory_env"
                                        labelWidth={100}
                                        fullWidth
                                        name="inventory_env"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        native
                                        variant="filled"
                                    >
                                        <option value="">Select Inventory Environment</option>
                                        {inventoryEnvironmentList.map(value =>  
                                            <option value={value}>{value}</option> 
                                        )}
                                    </Select> 
                                </FormControl>
                            )}
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                style={{marginRight:"10px"}}
                                className={classes.body2}
                            >
                                {formik.errors.commissioned_date && formik.touched.commissioned_date ? (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Fragment>
                                
                                    <FormControl fullWidth>
                                    <KeyboardDatePicker
                                        inputVariant="filled"
                                        label="Commissioned Date"
                                        name="commissioned_date"
                                        format="MM/dd/yyyy"
                                        // placeholder="MM/DD/YYYY"
                                        fullWidth
                                        labelFunc={() => formik.values.commissioned_date || "MM/DD/YYYY"}
                                        onChange={(val) => {
                                        formik.setFieldValue(
                                            "commissioned_date",
                                            moment(val).format("MM/DD/YYYY")
                                        );
                                        }}
                                        helperText={formik.errors.commissioned_date}
                                        onBlur={formik.handleBlur}
                                        error={
                                        formik.errors.commissioned_date && formik.touched.commissioned_date
                                            ? true
                                            : false
                                        }
                                    />
                                    </FormControl>
                                    </Fragment>
                                </MuiPickersUtilsProvider>
                                ) : (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Fragment>
                                    <FormControl fullWidth>
                                    <KeyboardDatePicker
                                        inputVariant="filled"
                                        label="Commissioned Date"
                                        name="commissioned_date"
                                        format="MM/dd/yyyy"
                                        // placeholder="MM/DD/YYYY"
                                        fullWidth
                                        labelFunc={() => formik.values.commissioned_date || "MM/DD/YYYY"}
                                        //onChange={formik.handleChange}
                                        onChange={(val) => {
                                        formik.setFieldValue(
                                            "commissioned_date",
                                            moment(val).format("MM/DD/YYYY")
                                        );
                                        }}
                                    />
                                    </FormControl>
                                    </Fragment>
                                </MuiPickersUtilsProvider>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={5}
                                sm={5}
                                className={classes.body2}
                            >
                                {formik.errors.decommission_date && formik.touched.decommission_date && inv_type == 'Designer' ? (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Fragment>
                                
                                    <FormControl fullWidth>
                                    <KeyboardDatePicker
                                        inputVariant="filled"
                                        label="Decommission Date"
                                        name="decommission_date"
                                        format="MM/dd/yyyy"
                                        // placeholder="MM/DD/YYYY"
                                        fullWidth
                                        labelFunc={() => formik.values.decommission_date || "MM/DD/YYYY"}
                                        onChange={(val) => {
                                        formik.setFieldValue(
                                            "decommission_date",
                                            moment(val).format("MM/DD/YYYY")
                                        );
                                        }}
                                        helperText={formik.errors.decommission_date}
                                        onBlur={formik.handleBlur}
                                        error={
                                        formik.errors.decommission_date && formik.touched.decommission_date
                                            ? true
                                            : false
                                        }
                                    />
                                    </FormControl>
                                    </Fragment>
                                </MuiPickersUtilsProvider>
                                ) : (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Fragment>
                                    <FormControl fullWidth>
                                    <KeyboardDatePicker
                                        inputVariant="filled"
                                        label="Decommission Date"
                                        name="decommission_date"
                                        format="MM/dd/yyyy"
                                        // placeholder="MM/DD/YYYY"
                                        fullWidth
                                        labelFunc={() => formik.values.decommission_date || "MM/DD/YYYY"}
                                        //onChange={formik.handleChange}
                                        onChange={(val) => {
                                        formik.setFieldValue(
                                            "decommission_date",
                                            moment(val).format("MM/DD/YYYY")
                                        );
                                        }}
                                    />
                                    </FormControl>
                                    </Fragment>
                                </MuiPickersUtilsProvider>
                                )}
                            </Grid>
                            {inv_type !== 'Designer' ? (
                                <Grid xs={12} sm={8} md={8}>
                                    <FormControlLabel
                                style={{marginTop:"20px",marginLeft:"-10px"}}
                                    control={
                                        <Checkbox
                                            id="is_badge_available"
                                            checked={formik.values.is_badge_available}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            color="primary"
                                        />
                                    }
                                    label="Enable Badge"
                                />
                                </Grid>
                                
                            ):(<div></div>)}
                            <Grid item xs={12} sm={12} md={12}>
                            <h3 className="createaccount-widget-title">Image Information</h3>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8}>
                            {formik.errors.image_link && formik.touched.image_link ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Image Link"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="image_link"
                                        value={formik.values.image_link}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.image_link}
                                        error={
                                            formik.errors.image_link && formik.touched.image_link
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Image Link"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="image_link"
                                        value={formik.values.image_link}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                            {formik.errors.image_version && formik.touched.image_version ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Image Version"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="image_version"
                                        value={formik.values.image_version}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.image_version}
                                        error={
                                            formik.errors.image_version && formik.touched.image_version
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Image Version"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="image_version"
                                        value={formik.values.image_version}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            {inv_type == 'Designer' ? (
                            <Grid item xs={12} sm={8} md={8}>
                            {formik.errors.icon_url && formik.touched.icon_url ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Icon URL"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="icon_url"
                                        value={formik.values.icon_url}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.icon_url}
                                        error={
                                            formik.errors.icon_url && formik.touched.icon_url
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Icon URL"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="icon_url"
                                        value={formik.values.icon_url}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            ):(<div></div>)}
                            <Grid item xs={12} sm={4} md={4}>
                            {formik.errors.cloud_provider && formik.touched.cloud_provider ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Cloud Proviser"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="cloud_provider"
                                        value={formik.values.cloud_provider}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.cloud_provider}
                                        error={
                                            formik.errors.cloud_provider && formik.touched.cloud_provider
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Cloud Proviser"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="cloud_provider"
                                        value={formik.values.cloud_provider}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            {inv_type == 'Designer' ? (
                                <><Grid item xs={12} sm={12} md={12}>
                                        <h3 className="createaccount-widget-title">Device Properties</h3></Grid><Grid item xs={12} sm={3} md={3}>
                                            {formik.errors.max_nodes && formik.touched.max_nodes ? (
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                    <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        rows={1}
                                                        label="Max Nodes"
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="max_nodes"
                                                        value={formik.values.max_nodes}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        variant="filled"
                                                        helperText={formik.errors.max_nodes}
                                                        error={formik.errors.max_nodes && formik.touched.max_nodes
                                                            ? true
                                                            : false} />
                                                </FormControl>
                                            ) : (
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                    <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        rows={1}
                                                        label="Max Nodes"
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="max_nodes"
                                                        value={formik.values.max_nodes}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        variant="filled" />
                                                </FormControl>
                                            )}
                                        </Grid><Grid item xs={12} sm={3} md={3}>
                                            {formik.errors.max_int && formik.touched.max_int ? (
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                    <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        rows={1}
                                                        label="Max Interfaces"
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="max_int"
                                                        value={formik.values.max_int}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        variant="filled"
                                                        helperText={formik.errors.max_int}
                                                        error={formik.errors.max_int && formik.touched.max_int
                                                            ? true
                                                            : false} />
                                                </FormControl>
                                            ) : (
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                    <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        rows={1}
                                                        label="Max Interfaces"
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="max_int"
                                                        value={formik.values.max_int}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        variant="filled" />
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                    <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        label="Automated L1"
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="automated_l1"
                                                        value={formik.values.automated_l1}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        rows={1}
                                                        variant="filled" />
                                                   
                                                </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                   <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        label="Automated L2"
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="automated_l2"
                                                        value={formik.values.automated_l2}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        rows={1}
                                                        variant="filled" />
                                                </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                   <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        label="ERB Template"
                                                        rows={10}
                                                        multiline
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="erb_template"
                                                        value={formik.values.erb_template}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        variant="filled" />
                                                </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            {formik.errors.prop_manual && formik.touched.prop_manual ? (
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                    <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        rows={10}
                                                        multiline
                                                        label="Node XML"
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="prop_manual"
                                                        value={formik.values.prop_manual}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        variant="filled"
                                                        helperText={formik.errors.prop_manual}
                                                        error={formik.errors.prop_manual && formik.touched.prop_manual
                                                            ? true
                                                            : false} />
                                                </FormControl>
                                            ) : (
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                    <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        rows={10}
                                                        multiline
                                                        label="Node XML"
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="prop_manual"
                                                        value={formik.values.prop_manual}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        variant="filled" />
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            {formik.errors.prop_external && formik.touched.prop_external ? (
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                    <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        rows={10}
                                                        multiline
                                                        label="Node JSON"
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="prop_external"
                                                        value={formik.values.prop_external}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        variant="filled"
                                                        helperText={formik.errors.prop_external}
                                                        error={formik.errors.prop_external && formik.touched.prop_external
                                                            ? true
                                                            : false} />
                                                </FormControl>
                                            ) : (
                                                <FormControl
                                                    fullWidth
                                                    className={classes.customInputStyle}
                                                >
                                                    <TextField
                                                        style={{ paddingRight: "50px", margin: 0 }}
                                                        rows={10}
                                                        multiline
                                                        label="Node JSON"
                                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                                        InputProps={{ disableUnderline: true }}
                                                        id="prop_external"
                                                        value={formik.values.prop_external}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        variant="filled" />
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3}>
                      {formik.errors.boot_time && formik.touched.boot_time ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Boot Time"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="boot_time"
                            value={formik.values.boot_time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.boot_time}
                            error={formik.errors.boot_time && formik.touched.boot_time
                              ? true
                              : false} />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Boot Time"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="boot_time"
                            value={formik.values.boot_time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled" />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      {formik.errors.consumed_cpu && formik.touched.consumed_cpu ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed CPU"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_cpu"
                            value={formik.values.consumed_cpu}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.consumed_cpu}
                            error={formik.errors.consumed_cpu && formik.touched.consumed_cpu
                              ? true
                              : false} />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed CPU"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_cpu"
                            value={formik.values.consumed_cpu}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled" />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      {formik.errors.consumed_ram && formik.touched.consumed_ram ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed RAM"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_ram"
                            value={formik.values.consumed_ram}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.consumed_ram}
                            error={formik.errors.consumed_ram && formik.touched.consumed_ram
                              ? true
                              : false} />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed RAM"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_ram"
                            value={formik.values.consumed_ram}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled" />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      {formik.errors.consumed_disk && formik.touched.consumed_disk ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed Disk"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_disk"
                            value={formik.values.consumed_disk}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.consumed_disk}
                            error={formik.errors.consumed_disk && formik.touched.consumed_disk
                              ? true
                              : false} />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed Disk"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_disk"
                            value={formik.values.consumed_disk}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled" />
                        </FormControl>
                      )}
                    </Grid>
                                        
                                        </>
                                ):(<div></div>)}
                            <Grid item xs={12} sm={12} md={12}>
                            <h3 className="createaccount-widget-title">Resources</h3></Grid>
                            <Grid item xs={12} sm={3} md={3}>
                            {formik.errors.resource_allocation_type && formik.touched.resource_allocation_type ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Allocation Type"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="resource_allocation_type"
                                        value={formik.values.resource_allocation_type}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.resource_allocation_type}
                                        error={
                                            formik.errors.resource_allocation_type && formik.touched.resource_allocation_type
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Allocation Type"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="resource_allocation_type"
                                        value={formik.values.resource_allocation_type}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                            {formik.errors.vcpu && formik.touched.vcpu ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="CPU"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="vcpu"
                                        value={formik.values.vcpu}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.vcpu}
                                        error={
                                            formik.errors.vcpu && formik.touched.vcpu
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="CPU"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="vcpu"
                                        value={formik.values.vcpu}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                            {formik.errors.memory && formik.touched.memory ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="RAM (in MBs)"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="memory"
                                        value={formik.values.memory}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.memory}
                                        error={
                                            formik.errors.memory && formik.touched.memory
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="RAM (in MBs)"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="memory"
                                        value={formik.values.memory}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                            {formik.errors.storage && formik.touched.storage ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="DISK"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="storage"
                                        value={formik.values.storage}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.storage}
                                        error={
                                            formik.errors.storage && formik.touched.storage
                                            ? true
                                            : false
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="DISK"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="storage"
                                        value={formik.values.storage}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                            <h3 className="createaccount-widget-title">Select Accounts and Tags</h3></Grid>
                            <Grid item xs={12} sm={12} md={12}>
                            {formik.errors.selectedAccounts && formik.touched.selectedAccounts ? (
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                    variant="filled"
                                    style={{ marginTop: "10", marginRight: "5px" }}
                                >
                                   <InputLabel id="mutiple-select-label">Select Accounts</InputLabel>
                                    <Select
                                        labelId="mutiple-select-label"
                                        multiple
                                        value={selectedAccounts}
                                        onChange={handleChange}
                                        renderValue={(selectedAccounts) => selectedAccounts.join(", ")}
                                        MenuProps={MenuProps}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                        helperText={formik.errors.selectedAccounts}
                                        error={
                                            formik.errors.accounts && formik.touched.accounts
                                            ? true
                                            : false
                                        }
                                        required={true}
                                    >
                                        <MenuItem
                                        value="all"
                                        classes={{
                                            root: isAllSelected ? classes.selectedAll : ""
                                        }}
                                        >
                                        <ListItemIcon>
                                            <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllSelected}
                                            indeterminate={
                                                selectedAccounts.length > 0 && selectedAccounts.length < accounts.length
                                            }
                                            required={true}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                        </MenuItem>
                                        {accounts.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <ListItemIcon>
                                            <Checkbox checked={selectedAccounts.indexOf(option) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                    <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                    variant="filled"
                                    style={{ marginTop: "10", marginRight: "5px" }}
                                >
                                   <InputLabel id="mutiple-select-label">Select Accounts</InputLabel>
                                    <Select
                                        labelId="mutiple-select-label"
                                        multiple
                                        value={selectedAccounts}
                                        onChange={handleChange}
                                        onBlur={formik.handleBlur}
                                        renderValue={(selectedAccounts) => selectedAccounts.join(", ")}
                                        MenuProps={MenuProps}
                                        variant="filled"
                                        required={true}
                                        
                                    >
                                        <MenuItem
                                        value="all"
                                        classes={{
                                            root: isAllSelected ? classes.selectedAll : ""
                                        }}
                                        >
                                        <ListItemIcon>
                                            <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllSelected}
                                            indeterminate={
                                                selectedAccounts.length > 0 && selectedAccounts.length < accounts.length
                                            }
                                            required={true}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                        </MenuItem>
                                        {accounts.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <ListItemIcon>
                                            <Checkbox checked={selectedAccounts.indexOf(option) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            </Grid>
                            <Grid item xs={12} sm={8} md={8}>
                            <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                    variant="filled"
                                    style={{ marginTop: "10", marginRight: "5px" }}
                                >
                                   <InputLabel id="select-tag">Select Tags</InputLabel>
                                    <Select
                                        labelId="select-tag"
                                        multiple
                                        value={selectedTags}  
                                        onChange={handleTagChange}
                                        renderValue={(selectedTags) => selectedTags.join(", ")}
                                        onBlur={formik.handleBlur}
                                        variant="filled"
                                    >
                                        <MenuItem
                                            value="all"
                                            classes={{
                                                root: isAllSelectedTags ? classes.selectedAll : ""
                                            }}
                                        >
                                        <ListItemIcon>
                                            <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllSelectedTags}
                                            indeterminate={
                                                selectedTags.length > 0 && selectedTags.length < tags.length
                                            }
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                        </MenuItem>
                                        {tags.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <ListItemIcon>
                                            <Checkbox checked={selectedTags.indexOf(option) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={8} md={8}></Grid>
                                <Grid item xs={12} sm={4} md={4} style={{ padding: "0px 0px 0px 0px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Add Inventory
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            </form>
        </div>
    );
};

export default AddInventory;