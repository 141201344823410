import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// Local
import Menu from "../../Components/Menu";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { CMSAPIWithToken } from "../../../apis/CMS";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import SandboxView from "./SandboxView";
import SandboxTypeUpdate from "./SandboxTypeUpdate";
import SandboxCopyModal from "./SandboxCopyModal";

// Material UI Components
import { Alert } from "@material-ui/lab";
// Icons
import Visibility from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ForwardIcon from '@material-ui/icons/Forward';
// Local
import CustomTable from "../Components/Table";
import RightDrawer from "../Components/RightDrawer";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { IconButton } from "@material-ui/core";
//import Visibility from "@material-ui/icons/Visibility";
import ViewInventory from "../../InventoryManagement/components/ViewInventory"
import AddInventory from "../../InventoryManagement/components/AddInventory"
import {hotjar} from "react-hotjar";


// Styling
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "50px"
    },
    backButton: {
      margin: "15px 0",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginRight: "5px",
      },
      "&:hover": {
        color: theme.palette.secondary.main,
        "& svg": {
          fill: theme.palette.secondary.main,
        },
      },
    },
}));

const ProductContentSandboxesTypes = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const [isInternal, setIsInternal] = useState(hasDomain);
    useEffect(() => {
      dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Content Management Sandboxes":"Content Management Sandboxes"));
        renderrow();
    }, []);
    hotjar.initialize(2281731, 6);
    hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
    // States
    const [learningIdentifiers, setLearningIdentifiers] = useState([]); // Initialize as empty array
    const [sandboxIdentities, setSandboxIdentities] = useState([]);
    const [sandboxesRows, setSandboxesRows] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentInventory, setCurrentInventory] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const [currentScreen, setCurrentScreen] = useState("Sandboxes");
    const [currentDrawerScreen, setcurrentDrawerScreen] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const [currentUser, setCurrentUser] = useState();
    var sandbox_id_title_map = {};
    const [notificationType, setNotificationType] = useState("");
    const columns = [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "typeName",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
  
    let sandboxesData = [];
    let identitys = [];
    let identifiers = [];
    //var menuItems = ["Sandboxes", "Sandbox Options", "Sandbox Details", "Sandboxes Cheat sheets", "Sandboxes Labs", "Sandboxes Categories","Sandboxes Exercises"];

    const [isLoading, setIsLoading] = useState(true);
    const renderNotififications = () => {
        let notifications = notificationMessage.map((item) => {
          let notificationType = item.success ? "success" : "error";
          return (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
              spacing={1}
            >
              {item.message}
            </Alert>
          );
        });
    
        return notifications;
      };

      const renderrow = async (value) => {
        return CMSAPIWithToken()
        .get("/poc/admin_sandboxes_types/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              sandbox_id_title_map[item.id] = item.type_name;
                sandboxesData.push({
                  id: item.id,
                typeName: item.type_name,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handleEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
                identitys.push(item.identity);
            });
            setSandboxesRows(sandboxesData);
            setSandboxIdentities(identitys);
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };


//Sandbox Delete Items 
      const handleDeleteButton = (id) => {
      
        let user_confirm = window.confirm("Do you want to delete sandbox type?")
        if(user_confirm){
        let url="/poc/admin_sandboxes_types"+`/${id}/`;
        CMSAPIWithToken()
        .delete("/poc/admin_sandboxes_types"+`/${id}/`)
        .then((resp) => resp.data.results)
        .then((data) => {
          const payload = {
              message: "Sandbox Type Deleted Successfully",
              color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("/product-admin-sandboxes");
          window.location.reload()
      })
      .catch((err) => {
        let message = err.response.data.message;
        const payload = {
          message: message,
          color: "danger",
      };
      payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
      });
      };
    }
      // view single product details
      const renderDrawerComponent = () => {
        switch (currentDrawerScreen) {
          case "Sandboxes Type Edit":
            return <SandboxTypeUpdate user={currentUser} />;
                            
          case "default":
            return null;
        }
      };

      const handleEditButton = (typeId) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Type Edit");
        setCurrentUser(typeId);
      };

      //Add new sandbox , Option , Details ,Cheat Sheets, Labs ,Categories,Exercises
      const handleAddUserSandboxType = () => {
        history.push("ProductContentSandboxes/AddNewSandboxType");  
      };

      const handleContentCopy = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

      const backButton = () => {
        history.goBack();
      }
      
    const renderContent = () => {
        return (
          <Grid container className={classes.root} spacing={1}>
            <Grid item xs={12} md={12}>
              {showNotification ? renderNotififications() : null}
            </Grid>
            <Grid item xs={12} md={12}>
              <LoaderHoc isLoading={isLoading}>
                <CustomTable
                  columns={columns}
                  data={sandboxesRows}
                  handleDeleteButtonClick={handleDeleteButton}
                  handleButtonClick={handleAddUserSandboxType}
                  tableTitle="Sandbox Types"
                  buttonTitle="Add New Sandbox Type"
                  hiddenColumns={[]}
                  contentType="others"
                />
              </LoaderHoc>
            </Grid>
          </Grid>
        );
    };
    return (
        <div>
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    {renderContent()}
                </Grid>
                 {/* Drawer */}
                 <RightDrawer
                      open={openDrawer}
                      onClose={setOpenDrawer}
                      title={currentInventory}
                    >
                      {renderDrawerComponent()}
                    </RightDrawer>
                    <SandboxCopyModal
                      open={isModalOpen}
                      handleClose={handleCloseModal}
                      sandboxIdentifiers={sandboxIdentities}
                      targetIdentifiers={learningIdentifiers}
                    />
            </Grid>
        </div>
    );
};

export default ProductContentSandboxesTypes;