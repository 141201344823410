import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AuthLayout from "layouts/Auth.js";
import { Route } from "react-router-dom";
import queryString from "query-string";
import AdminLayout from "layouts/Admin.js";
import { Switch, Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { Api } from "config/api";
import {
  SHOW_NOTIFICATION,
  SAVE_PROFILE,
  THEME_CUSTOMIZE,
  SHOW_LOADER,
  HIDE_LOADER
} from "action_creators/actioncreator";
import {
  SDCloudBackendAPIWithToken,
  SDCloudCustomizeAPIWithToken
} from "apis/backendAPI";
import theme from "theme";
export default function App() {
  let { profileObj } = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  let queryParams = queryString.parse(window.location.search);
  if (queryParams.access && queryParams.refresh && queryParams.schedule) {
    let token = queryParams.access;
    SDCloudBackendAPIWithToken()
      .get(Api.getUserProfile)
      .then(value => {
        localStorage.setItem("userName", value.data.username);
      })
      .catch(err => {
        const payload = {
          message: err?.response?.data?.message || "Session Expired",
          color: "danger",
          error: err
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        setTimeout(function () {
          history.push("/auth/login-page");
        }, 2000);
      });
    let expiryTime = new Date();
    expiryTime = expiryTime.setMinutes(expiryTime.getMinutes() + 15);
    localStorage.setItem("user", token);
    localStorage.setItem("refreshToken", queryParams.refresh);
    localStorage.setItem("token_expire_at", expiryTime);
  }
  var isAuthenticated =
    localStorage.getItem("user") && localStorage.getItem("userName");

  useEffect(() => {
    let token = localStorage.getItem("user");
    let parsedQuery = queryString.parse(window.location.search);
    let accountName = JSON.parse(localStorage.getItem("profileData"))?.account;
    if (token) {
      //since user was logged in previously so reload redux.
      SDCloudBackendAPIWithToken()
        .get(Api.getUserProfile)
        .then(value => {
          getAdminDashboard(value.data);
          localStorage.setItem("userName", value.data.username);
          localStorage.setItem("isTerminate",value.data.is_terminate);
          localStorage.setItem(
            "available_purposes",
            JSON.stringify(value.data.available_purposes)
          );
          localStorage.setItem(
            "internal_domains",
            JSON.stringify(value.data.internal_domain)
          );
          localStorage.setItem(
            "available_zones",
            JSON.stringify(value.data.available_zones)
          );
          localStorage.setItem(
            "machine_types",
            JSON.stringify(value.data.cluster_flavor)
          );
          SDCloudBackendAPIWithToken()
            .get(Api.getDealIds,{
              params: {
                  account: accountName,
              }})
            .then(deal => {
              if (deal.data.deal_id) {
                // var matches = deal.data.deal_id.match(/\[(.*?)\]/);
                // if (matches) {
                //   var submatch = matches[1];
                // }
                // let str1 = submatch.replace(/'/g, "");
                // str1 = str1.replace(/ /g, "").split(",");
                localStorage.setItem("deal_ids", JSON.stringify(deal.data.deal_id));
              }
            });
          if (value.data.mfa_enabled) {
            localStorage.setItem("isMfaLoaded", true);
            dispatch(HIDE_LOADER());
          } else {
            localStorage.setItem("profileData", JSON.stringify(value.data));
            // making redux call to save profile.
            const payload = { data: value.data };
            dispatch(SAVE_PROFILE({ payload }));
          }
          const payload = { data: value.data };
          dispatch(SAVE_PROFILE({ payload }));
        })
        .catch(err => {
          const payload = {
            message: err?.response?.data?.message || "Session Expired",
            color: "danger",
            error: err
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
          setTimeout(function () {
            history.push("/auth/login-page");
          }, 2000);
        });
    } else {
      if (!(parsedQuery.id)) {
        history.push("/auth/login-page");
      }
    }
  }, [isAuthenticated]);

  const getAdminDashboard = async profileData => {
    dispatch(SHOW_LOADER());
    SDCloudCustomizeAPIWithToken()
      .get(Api.CustomizeAccount)
      .then(response => {
        dispatch(HIDE_LOADER());
        if (response.data.results.length > 0) {
          let filterData = response.data.results.filter(
            f => f.name == profileData.account
          );
          if (filterData.length > 0) {
            dispatch(SHOW_LOADER());
            SDCloudCustomizeAPIWithToken()
              .get(Api.dashboardCustomize + "" + filterData[0].id + "/")
              .then(response2 => {
                dispatch(HIDE_LOADER());
                if (response2.data) {
                  // const payload = { data: response2.data };
                  if (response2.data.title !== "") {
                    localStorage.setItem("Title", response2.data.title);
                  }
                  if (response2.data.subtitle !== "") {
                    localStorage.setItem("SubTitle", response2.data.subtitle);
                  }
                  if (response2.data.primary_color_code !== "") {
                    localStorage.setItem(
                      "PrimaryColor",
                      response2.data.primary_color_code
                    );
                  }
                  if (response2.data.secondary_color_code !== "") {
                    localStorage.setItem(
                      "SecondaryColor",
                      response2.data.secondary_color_code
                    );
                  }
                  if (response2.data.button_color_code !== "") {
                    localStorage.setItem(
                      "ButtonColor",
                      response2.data.button_color_code
                    );
                  }
                  if (response2.data.panel_color_code !== "") {
                    localStorage.setItem(
                      "PanelColor",
                      response2.data.panel_color_code
                    );
                  }

                  if (response2.data.branding_logo !== "") {
                    localStorage.setItem(
                      "BrandLogo",
                      response2.data.branding_logo
                    );
                  }
                  if (response2.data.branding_logo_inv !== "") {
                    localStorage.setItem(
                      "BrandLogoInv",
                      response2.data.branding_logo_inv
                    );
                  }
                  if (response2.data.branding_logo_small !== "") {
                    localStorage.setItem(
                      "BrandLogoSmall",
                      response2.data.branding_logo_small
                    );
                  }
                  if (
                    response2.data.font_family &&
                    response2.data.font_family !== ""
                  ) {
                    localStorage.setItem(
                      "FontFamily",
                      response2.data.font_family
                    );
                  }
                  // dispatch(THEME_CUSTOMIZE({ payload }));
                }
              })
              .catch(err2 => {
                dispatch(HIDE_LOADER());
                //console.log(err2.response);
              });
          }
        }
      })
      .catch(err => {
        dispatch(HIDE_LOADER());
        //console.log(err.response);
      });
  };

  if (isAuthenticated && localStorage.getItem("isMfaLoaded")) {
    return (
      <Switch>
        <Route path="/auth/login-page" exact component={AuthLayout} />
        <Redirect from="/" to="/auth/login-page" component={AuthLayout} />
      </Switch>
    );
  } else if (isAuthenticated) {
    return (
      <Switch>
        <Route path="/" component={AdminLayout} />
        <Redirect from="/" to="/dashboard" />
      </Switch>
    );
  } else {
    localStorage.removeItem("Title");
    localStorage.removeItem("SubTitle");
    localStorage.removeItem("BrandLogo");
    localStorage.removeItem("BrandLogoInv");
    localStorage.removeItem("BrandLogoSmall");
    localStorage.removeItem("PrimaryColor");
    localStorage.removeItem("SecondaryColor");
    localStorage.removeItem("ButtonColor");
    localStorage.removeItem("FontFamily");
    localStorage.removeItem("PanelColor");
    localStorage.removeItem("customAccountName");
    localStorage.removeItem("sessionId1")
    localStorage.removeItem("sessionId2")
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key.startsWith('designer_logs_')) {
        localStorage.removeItem(key);
      }
    }
    return (
      <Switch>
        <Route from="/auth/reset-password" exact component={AuthLayout} />
        <Route path="/auth/login-page" exact component={AuthLayout} />
        <Redirect from="/" to="/auth/login-page" component={AuthLayout} />
      </Switch>
    );
  }
}
